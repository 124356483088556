import {createClient} from 'contentful';

const config_old = {
    space: "g7zopbybs1ea",
    accessToken: "KUpDlj0KIgIaTmDU6Sk9mMk1SiRCSMFToUsMRyFM9pc"
};

const config_new = {
    space: "yso50nbaghtr",
    accessToken: "ZmXRRszHjULqjV6RasfGDfMCOHzP6IlBdw9PcvzfhTI"
};

const config_dev = {
    space: "0x5o817yoioj",
    accessToken: "rj5RLvBlKd5v7CheYOO5b0toiGboLZU7zzd_Qckc41U"
};


export default class Contentful {
    protected static _instance: Contentful = new Contentful();
    client = createClient(config_new);

    constructor() {
        if(Contentful._instance) {
            throw new Error("Instantiation failed");
        }
    }

    public static getInstance(): Contentful {
        return Contentful._instance
    }
}
