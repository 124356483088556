const Intro = "/";
const Homepage = "/";
const Accessories = "/accessories";
const Parts = "/parts";
const Configurator = "/configurator";
const ProductPage = "/product";
const ConferencePage = "/conference";
const EducationPage = "/education";
const ExecutivePage = "/executive";
const GuestPage = "/guest";
const StackPage = "/stack";
const TaskPage = "/task";
const StoolsPage = "/stools";
const SchoolPage = "/school";
const Contact = "/contact";
const About = "/about";
const Material = "/material";
const PoliciesPage = "/policies";
const PrivacyPolicy = "/privacy-policy";
const GsaAdvantage = "/gsa-advantage";
const GsaProgram = "/gsa-program";
const FabrixCatalog = "/fabrix-catalog";
const PartnerPage = "/partner";
const ResourcesPage = "/resources";


export default {
  Intro,
  Homepage,
  Accessories,
  Configurator,
  ProductPage,
  ConferencePage,
  EducationPage,
  ExecutivePage,
  GuestPage,
  StackPage,
  TaskPage,
  StoolsPage,
  SchoolPage,
  Contact,
  About,
  Material,
  PoliciesPage,
  PrivacyPolicy,
  GsaAdvantage,
  GsaProgram,
  FabrixCatalog,
  PartnerPage,
  ResourcesPage,
  Parts
};
