import React from "react"
import { iOSCheck } from "../../utils/iosCheck/iosCheck.util"
import { AndroidArView } from "./arViews/androidArView/customizer.androidArView"
import { IosArView } from "./arViews/iosArView/customizer.iosArView"

type Props = {
  mesh: THREE.Object3D
  setArAvailble: Function
}

export const MobileAr: React.FC<Props> = ({ mesh, setArAvailble}) => {
  React.useEffect(() => {
    if(!iOSCheck()) {
      if ( 'xr' in navigator ) {
        // @ts-ignore
        navigator.xr.isSessionSupported( 'immersive-ar' ).then( function ( supported ) {
          supported ? setArAvailble(true) : setArAvailble(false);
        } ).catch( setArAvailble(false));
      }
    }

  }, []);

  return(
  <>
    {iOSCheck()
    ? <IosArView mesh={mesh}/>
    : <AndroidArView mesh={mesh}/>}
  </>
)}