import React from "react";
import Model from "../../../types/Model";
import styles from "./styles.module.scss";
import ProductCard from "./product.card/product.card";
import { useDispatch, useSelector } from "react-redux";
import useFilterModels from "../../../hooks/useFilterModels";
import { Grid, MenuItem, MenuItemProps } from "@material-ui/core";
import SortIcon from "../../../components/icons.components/sort.icon/sort.icon";
import {
  filterSelector,
  sortSelector,
} from "../../../redux/modules/app.module";
import { changeControls } from "../../../redux/modules/action.creators/app.actions";
import FilterIcon from "../../../components/icons.components/filter.icon/filter.icon";
import DropdownComponent from "../../../components/dropdown.component/dropdown.component";
// import Dropdown from 'react-multilevel-dropdown';

import {
  A_TO_Z,
  DISCONTINUED_FILTER,
  FABRIX_FILTER,
  GSA_FILTER,
  HIGH_TO_LOW,
  LOW_TO_HIGH,
  MODEL_A_TO_Z,
  MODEL_Z_TO_A,
  Z_TO_A,
} from "../../../constants/filters.constants";
import { ChevronRight } from "@material-ui/icons";
import classNames from "classnames";
import { imageCompressConstants } from "../../../constants/imageCompressConstants";
import ProdcutCompare from "./product.compare/product.compare";
import useWindowWidth from "../../../hooks/useWindowWidth";
import _ from "lodash";
import { DoubleDropdown } from "./doubleDropdown";

interface IMapProps {
  models: Model[];
  category: string;
  isPartner?: boolean;
  isDiscount?: boolean;
}

export const Products: React.FC<IMapProps> = ({
  models = [],
  category,
  isPartner = false,
  isDiscount = false,
}) => {
  const dispatch = useDispatch();
  const filter = useSelector(filterSelector);
  const sort = useSelector(sortSelector);
  const [showAll, setShowAll] = React.useState(false);
  const [activeSerial, setActiveSerial] = React.useState("All" as string);
  const [openDropdown, setOpenDropdown] = React.useState(false as boolean);
  const [compareActive, setCompareActive] = React.useState(false as boolean);
  const [compareArray, setCompareArray] = React.useState<Model[]>([]);
  const [compareModalOpen, setCompareModelOpen] = React.useState(
    false as boolean
  );
  const width = useWindowWidth();

  React.useEffect(() => {
    if (category) {
      setShowAll(category && category === "All Chairs");
    }
  }, [category]);

  const filterHandlerClick = (key: string | any) => {
    setActiveSerial("All");
    dispatch(changeControls({ name: "filter", value: key }));
  };

  const sortHandlerClick = (key: string | any) => {
    setActiveSerial("All");
    dispatch(changeControls({ name: "sort", value: key }));
  };

  const handleActiveSerial = (serial: string) => {
    setActiveSerial(serial);
    dispatch(changeControls({ name: "sort", value: serial }));
  };

  React.useEffect(() => {
    if (compareModalOpen && compareArray.length) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [compareModalOpen, compareArray]);

  const { filteredModels } = useFilterModels(
    models,
    filter,
    sort,
    showAll,
    // category
  );
  
  console.log('MODELS:', models);
  
  const renderCats = () => {
    const modelCategory = Array.from(
      models
        .filter((model: Model) =>
          !showAll
            ? model.category.toLowerCase() === category.toLowerCase()
            : true
        )
        .reduce((acc, model) => {
          return acc.add(model.serial);
        }, new Set())
    ).filter((item) => item !== undefined);

    return (
      models &&
      modelCategory.map((key, index) => {
        return (
          <MenuItem
            key={index}
            className={`${styles.serial__item} ${
              sort === key ? styles.serial__active : ""
            }`}
            onClick={() => handleActiveSerial(key as string)}
          >
            {key}
          </MenuItem>
        );
      })
    );
  };

  const renderModels = () => {
    const categoryGroups = _.groupBy(models, "category");
    {
      /* @ts-ignore */
    }
    console.log(categoryGroups);
    const categoryArr = [];
    for (const [key, value] of Object.entries(categoryGroups)) {
      categoryArr.push({ name: key, chairs: value });
    }
    return categoryArr;
  };

  console.log(filteredModels, sort);

  return (
    <Grid className={styles.wrapper}>
      {isPartner ? null : (
        <Grid item xs={12} className={styles.filter_wrapper}>
          <section className={styles.filter_content}>
            <div className={styles.filter_list}>
              <DropdownComponent
                setOpenDropdown={setOpenDropdown}
                styles={styles.reset_btn}
                contentHTML={
                  <div className={styles.serial}>
                    {activeSerial && activeSerial === "All"
                      ? "View All"
                      : activeSerial}
                    <ChevronRight
                      className={`${styles.serial__icon} ${
                        openDropdown ? styles.serial__icon_open : ""
                      }`}
                    />
                  </div>
                }
              >
                <MenuItem
                  className={`${styles.serial__item} ${
                    sort === "All" ? styles.serial__active : ""
                  }`}
                  onClick={() => handleActiveSerial("All" as string)}
                >
                  View All
                </MenuItem>
                {renderCats() as MenuItemProps}
              </DropdownComponent>

              {/* <DoubleDropdown categories={renderModels()} /> */}
            </div>
            <div className={styles.filters}>
              <div className={styles.filters_item}>
                <DropdownComponent
                  styles={styles.sort_btn}
                  contentHTML={
                    <>
                      <span>Sort</span>
                      <SortIcon styles={styles.sort_icon} />
                    </>
                  }
                >
                  <MenuItem onClick={() => sortHandlerClick("All")}>
                    All
                  </MenuItem>
                  <MenuItem onClick={() => sortHandlerClick(HIGH_TO_LOW)}>
                    Price: High to Low
                  </MenuItem>
                  <MenuItem onClick={() => sortHandlerClick(LOW_TO_HIGH)}>
                    Price: Low to High
                  </MenuItem>
                  <MenuItem onClick={() => sortHandlerClick(A_TO_Z)}>
                    A to Z
                  </MenuItem>
                  <MenuItem onClick={() => sortHandlerClick(Z_TO_A)}>
                    Z to A
                  </MenuItem>
                  <MenuItem onClick={() => sortHandlerClick(MODEL_A_TO_Z)}>
                    By Model Name (A-Z)
                  </MenuItem>
                  <MenuItem onClick={() => sortHandlerClick(MODEL_Z_TO_A)}>
                    By Model Name (Z-A)
                  </MenuItem>
                </DropdownComponent>
              </div>
              <div className={styles.filters_item}>
                <DropdownComponent
                  styles={styles.filter_btn}
                  contentHTML={
                    <>
                      <span>Filter</span>
                      <FilterIcon styles={styles.filter_icon} />
                    </>
                  }
                >
                  <MenuItem onClick={() => filterHandlerClick("All")}>
                    All
                  </MenuItem>
                  <MenuItem onClick={() => filterHandlerClick(GSA_FILTER)}>
                    GSA
                  </MenuItem>
                  <MenuItem onClick={() => filterHandlerClick(FABRIX_FILTER)}>
                    FABRIX
                  </MenuItem>
                  <MenuItem
                    onClick={() => filterHandlerClick(DISCONTINUED_FILTER)}
                  >
                    DISCONTINUED
                  </MenuItem>
                </DropdownComponent>
              </div>
              {category !== "Accessories" ? (
                <>
                  {width > 600 && (
                    <div
                      className={classNames(
                        styles.filters_item,
                        styles.filters_compare,
                        compareActive ? styles.filters_compare_active : ""
                      )}
                      onClick={() =>
                        setCompareActive((prev) => {
                          setCompareArray(() => []);
                          return !prev;
                        })
                      }
                    >
                      <span>Compare</span>
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23 7.53125L12.5 2.75L2 7.53125L12.5 12.4062L23 7.53125Z"
                          fill="white"
                        />
                        <path
                          d="M12.4864 18.9193L5.69844 15.8162L2 17.4685L12.5 22.2498L23 17.4685L19.3114 15.8138L12.4864 18.9193Z"
                          fill="white"
                        />
                        <path
                          d="M23 12.4999L19.4595 10.9281L12.5047 14.1217L5.53109 10.9263L2 12.4999L12.5 17.2811L23 12.4999Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </section>
        </Grid>
      )}

      {category !== "Accessories" ? (
        <>
          {width <= 600 && (
            <div
              className={classNames(
                styles.compare,
                compareActive ? styles.compare_active : ""
              )}
              onClick={() =>
                setCompareActive((prev) => {
                  setCompareArray(() => []);
                  return !prev;
                })
              }
            >
              <span>Compare</span>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23 7.53125L12.5 2.75L2 7.53125L12.5 12.4062L23 7.53125Z"
                  fill="white"
                />
                <path
                  d="M12.4864 18.9193L5.69844 15.8162L2 17.4685L12.5 22.2498L23 17.4685L19.3114 15.8138L12.4864 18.9193Z"
                  fill="white"
                />
                <path
                  d="M23 12.4999L19.4595 10.9281L12.5047 14.1217L5.53109 10.9263L2 12.4999L12.5 17.2811L23 12.4999Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </>
      ) : (
        ""
      )}

      <Grid
        item
        xs={compareArray.length !== 0 ? 8 : 12}
        className={styles.content_wrapper}
      >
        <Grid className={styles.products}>
          {!!models.length &&
            filteredModels &&
            filteredModels
              .filter((el) => el)
              .map((model: Model) => (
                <ProductCard
                  key={model.id}
                  model={model}
                  category={category}
                  isCompareMode={compareActive}
                  compareModalOpen={compareModalOpen}
                  isSelected={compareArray.some(
                    (selected) => selected.id === model.id
                  )}
                  isDiscounted={isDiscount}
                  setCompareArray={setCompareArray}
                  compareArray={compareArray}
                  link={
                    isPartner
                      ? `/${category
                          .split(" ")
                          .join("_")
                          .toLowerCase()}/${model.title
                          .split(" ")
                          .join("_")
                          .toLowerCase()}`
                      : undefined
                  }
                />
              ))}
        </Grid>
        {compareModalOpen && compareArray.length !== 0 && (
          <ProdcutCompare
            category={category}
            compareArray={compareArray}
            setCompareArray={setCompareArray}
            setModalOpen={setCompareModelOpen}
          />
        )}
        {compareArray.length !== 0 && (
          <div className={styles.compareWrapper}>
            <span className={styles.compareWrapper_heading}>Comparing</span>
            {compareArray.map((model) => (
              <div className={styles.compareWrapper_card}>
                <div
                  className={styles.compareWrapper_card_delete}
                  onClick={() => {
                    setCompareArray((prev) =>
                      prev.filter((selected) => selected.id !== model.id)
                    );
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9859 3H10.5V1.5C10.5 1.36739 10.4473 1.24021 10.3536 1.14645C10.2598 1.05268 10.1326 1 10 1H6C5.86739 1 5.74021 1.05268 5.64645 1.14645C5.55268 1.24021 5.5 1.36739 5.5 1.5V3H2.01406L2 4.25H3.03125L3.65906 14.0625C3.67495 14.3163 3.7869 14.5544 3.97214 14.7286C4.15738 14.9027 4.402 14.9998 4.65625 15H11.3438C11.5979 15 11.8424 14.9032 12.0277 14.7294C12.2131 14.5555 12.3253 14.3176 12.3416 14.0641L12.9688 4.25H14L13.9859 3ZM5.5 13L5.21875 5H6.25L6.53125 13H5.5ZM8.5 13H7.5V5H8.5V13ZM9.25 3H6.75V2.125C6.75 2.09185 6.76317 2.06005 6.78661 2.03661C6.81005 2.01317 6.84185 2 6.875 2H9.125C9.15815 2 9.18995 2.01317 9.21339 2.03661C9.23683 2.06005 9.25 2.09185 9.25 2.125V3ZM10.5 13H9.46875L9.75 5H10.7812L10.5 13Z"
                      fill="#D13532"
                    />
                  </svg>
                </div>
                <div className={styles.compareWrapper_card_preview}>
                  <img
                    src={
                      model.angle_thumbnail
                        ? `https:${model.angle_thumbnail}${imageCompressConstants.productCardThumb}`
                        : ""
                    }
                    alt=""
                  />
                </div>
                <div className={styles.compareWrapper_card_text}>
                  <span className={styles.compareWrapper_card_type}>
                    {category ? category : model.category}
                  </span>
                  <span className={styles.compareWrapper_card_name}>
                    {model.title}
                  </span>
                  <span className={styles.compareWrapper_card_price}>
                    {model.price}
                    {"$"}
                  </span>
                </div>
              </div>
            ))}
            <div className={styles.compareWrapper_controls}>
              <div
                className={styles.compareWrapper_clear}
                onClick={() => setCompareArray([])}
              >
                Clear all
              </div>
              <div
                className={styles.compareWrapper_compare}
                onClick={() => setCompareModelOpen(true)}
              >
                Compare
              </div>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
