import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Routes from "../../../app.router/router.constants";
import {
  createStyles,
  FormControl,
  Grid, InputBase, InputLabel,
  Theme,
  Typography, withStyles,
} from "@material-ui/core";

import {LinkButton} from "../../../components/buttons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import {sendMail} from "../../../redux/modules/action.creators/app.actions";
import {errorSelector} from "../../../redux/modules/app.module";

import Message from "../../../types/Message";

import styles from './styles.module.scss';

interface IPublication {
  name: string,
  email: string,
  subject: string,
  message: string
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(2),
        },
      },
    },
    input: {
      position: 'relative',
      width: '100%',
      height: '40px',
      borderRadius: '10px',
      padding: '10px 12px',
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      },
    },
  }),
)(InputBase);

export const ContactUs = () => {

  const [validName, setValidName] = React.useState<boolean>(false);
  const [errorName, setErrorName] = React.useState<boolean>(false);

  const [validEmail, setValidEmail] = React.useState<boolean>(false);
  const [errorEmail, setErrorEmail] = React.useState<boolean>(false);

  const [validSubject, setValidSubject] = React.useState<boolean>(false);
  const [errorSubject, setErrorSubject] = React.useState<boolean>(false);

  const [validMessage, setValidMessage] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const [validForm, setValidForm] = React.useState<boolean>(false);
  const [successful, setSuccessful] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const error = useSelector(errorSelector);

  const [mail, setMailFields] = React.useState<IPublication>({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  React.useEffect(() => {
    checkValidForm();
  }, [validName, validEmail, validSubject, validMessage])


  const handleSendMail = () => {
    dispatch(sendMail(new Message(mail)));

    setMailFields({
      name: '',
      email: '',
      subject: '',
      message: ''
    });

    setValidForm(false);
    !error && setSuccessful(true);
  };

  const handleChangeField = (field: keyof IPublication) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailFields({...mail, [field]: event.target.value});
    switch (field) {
      case 'name': {
        checkValidTextField(event.target.value, 'name');
      }
        break;
      case 'email': {
        checkValidEmail(event.target.value);
      }
        break;
      case 'subject': {
        checkValidTextField(event.target.value, 'subject');
      }
        break;
      case 'message': {
        checkValidTextField(event.target.value, 'message');
      }
        break;
      default:
        return;
    }
  };

  const checkValidForm = () => {
    validName && validEmail && validSubject && validMessage ? setValidForm(true) : setValidForm(false);
  };

  const checkValidTextField = (text: string, field: string) => {
    if (text.length > 3) {
      switch (field) {
        case 'name': {
          setValidName(true);
          setErrorName(false);
        }
          break;
        case 'subject': {
          setValidSubject(true);
          setErrorSubject(false);
        }
          break;
        case 'message': {
          setValidMessage(true);
          setErrorMessage(false);
        }
          break;
        default:
          return;
      }
    } else {
      switch (field) {
        case 'name': {
          setValidName(false);
          setErrorName(true);
        }
          break;
        case 'subject': {
          setValidSubject(false);
          setErrorSubject(true);
        }
          break;
        case 'message': {
          setValidMessage(false);
          setErrorMessage(true);
        }
          break;
        default:
          return;
      }
    }
  };

  const checkValidEmail = (email: string) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setValidEmail(true);
      setErrorEmail(false);
    } else {
      setValidEmail(false);
      setErrorEmail(true);
    }
  };

  return (
    <Grid container className={styles.wrapper}>
      {successful && !error ?
        <Grid item xs={12} md={12} className={styles.form_wrapper}>
          <Grid item xs={12} md={12} className={styles.successful_container}>
            <Typography className={styles.successful}>Your message was successfully sent. We will contact
              you soon.</Typography>
            <LinkButton classes={{root: styles.link_wrapper}}>
              <Link to={Routes.Homepage} className={styles.link}>
                <Typography variant="caption">Home page</Typography>
                <ChevronRightIcon className={styles.link_icon}/>
              </Link>
            </LinkButton>
          </Grid>
        </Grid>
        :
        <Grid item  className={styles.form_wrapper}>
          <div className={styles.inputs_wrapper}>
            <div className={styles.flexRow}>
              <FormControl className={styles.field_container}>
                <InputLabel shrink htmlFor="bootstrap-input" className={styles.label}>
                  Your name <a className={styles.red}>*</a>
                </InputLabel>
                <BootstrapInput
                  className={errorName ? styles.field_error : styles.field}
                  required
                  id="name"
                  value={mail.name}
                  error={true}
                  placeholder={'Write your name, min 4 symblol'}
                  onChange={handleChangeField('name')}
                />
              </FormControl>
              <FormControl className={styles.field_container}>
                <InputLabel shrink htmlFor="bootstrap-input" className={styles.label}>
                  Subject <a className={styles.red}>*</a>
                </InputLabel>
                <BootstrapInput
                  className={errorSubject ? styles.field_error : styles.field}
                  required
                  id="subject"
                  value={mail.subject}
                  placeholder={'Write subject, min 4 symblol'}
                  onChange={handleChangeField('subject')}
                />
              </FormControl>
            </div>
            <div className={styles.flexRow}>
              <FormControl className={styles.field_container}>
                <InputLabel shrink htmlFor="bootstrap-input" className={styles.label}>
                  Your e-mail address <a className={styles.red}>*</a>
                </InputLabel>
                <BootstrapInput
                  className={errorEmail ? styles.field_error : styles.field}
                  required
                  id="email"
                  value={mail.email}
                  placeholder={'Write your email'}
                  onChange={handleChangeField('email')}
                />
              </FormControl>
              <FormControl className={styles.field_container}>
                <InputLabel shrink htmlFor="bootstrap-input" className={styles.label}>
                  Message <a className={styles.red}>*</a>
                </InputLabel>
                <BootstrapInput
                  className={errorMessage ? styles.field_error : styles.field}
                  required
                  multiline
                  rows={4}
                  id="message"
                  placeholder={'Write message, min 4 symblol'}
                  value={mail.message}
                  onChange={handleChangeField('message')}
                />
              </FormControl>
            </div>
          </div>

          {error ?

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Typography className={styles.error}>Message sending failed: {error}</Typography>
              <LinkButton disabled={!validForm} classes={{root: styles.link_wrapper}}
                          onClick={handleSendMail}>
                <div className={styles.link}>
                  <Typography variant="caption">Send</Typography>
                  <ChevronRightIcon className={styles.link_icon}/>
                </div>
              </LinkButton>
            </div>
            :
            <LinkButton disabled={!validForm} classes={{root: styles.link_wrapper}}
                        onClick={() => handleSendMail()}>
              <div className={styles.link}>
                <Typography variant="caption">Send</Typography>
                <ChevronRightIcon className={styles.link_icon}/>
              </div>
            </LinkButton>
          }
        </Grid>
      }
    </Grid>
  )
}
