import React from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from "react-redux";
import {changeControls, fetchAssets} from "../../redux/modules/action.creators/app.actions";

import {modelsSelector} from "../../redux/modules/app.module";

import Seating from "../../types/Seating";
import Model from "../../types/Model";
import {Products} from "./product.catalog";
import {SBannerComponent} from "./banner.component";
import {ContactArticle} from "../../components/contact.article.component";
import Footer from "../../components/footer.component";

interface IMapProps {
    seating: Seating
}

export const Categories: React.FC<IMapProps> = ({
                                                    seating, ...props
                                                }) => {

    const dispatch = useDispatch();
    const models = useSelector(modelsSelector);

    React.useEffect(() => {
        if (seating && seating.id === '2N6xOZdCvTvcUe0nUsjS6P') {
            dispatch(changeControls({name: "allChair", value: "allChair"}));
        } else {
            seating.entries && dispatch(fetchAssets(seating.entries, 'models', {class: Model}))
        }
    }, []);

    console.log(seating)

    return (
        <React.Fragment>
            <Helmet>
                <title>{seating.title + " | Eurotech"}</title>
                <meta name="description" content={seating.description}/>
            </Helmet>
            <SBannerComponent
                category={seating.title}
                heading={seating.heading}
                description={seating.description}
                preview={seating.preview}
            />
            <Products models={models} category={seating.title}/>
            <ContactArticle/>
            <Footer/>
        </React.Fragment>
    )
}
