import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {ProductComponent} from "./components/product";
import {ServiceComponent} from "./components/service";
import {SalesComponent} from "./components/sales";
import styles from './styles.module.scss';
import { CertificationTab } from '../certification.tab'
import { ContactArticle } from '../../../components/contact.article.component'

export const AboutTab = () => {
  return (
    <>
      <Grid  className={styles.wrapper}>
        <Grid  className={`${styles.title_wrapper} ${styles.container}`}>
          <Typography className={styles.title}>ABOUT</Typography>
          <Typography className={styles.sub_title}>Superior Quality and Customer Service in Office Seating</Typography>
          <p className={styles.description}>At Eurotech, we believe quality counts and our customers matter. With Eurotech, you’re guaranteed to get the best seat in the house.</p>
        </Grid>

        <ProductComponent/>
        <SalesComponent/>
        <ServiceComponent/>
        <CertificationTab />
        <ContactArticle />
      </Grid>
    </>
  )
}
