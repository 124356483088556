import React from "react";
import styles from './styles.module.scss';
import {Dialog, DialogContent, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
    selectFabrixListSelector,
    showFabrixViewerSelector,
} from "../../redux/modules/app.module";
import {useDispatch, useSelector} from "react-redux";
import {changeControls} from "../../redux/modules/action.creators/app.actions";
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import {Fabrix} from "../../types/FabrixCatalog";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(() => ({
    paper: {
        width: "100%",
        zIndex: 1500,
        maxWidth: 'auto',
        borderRadius: 10,
        "@media screen and (min-width: 768px) and (max-width: 1280px)": {
            width: "100%",
            maxWidth: 768,
        },
        "@media screen and (min-width: 480px) and (max-width: 768px)": {
            width: "100%",
            maxWidth: 480,
        }
    },
}));

export const FabrixPopup = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const content = useSelector(selectFabrixListSelector);
    const showViewer = useSelector(showFabrixViewerSelector);

    const [select, setSelect] = React.useState<any>();
    const [disabledClick, setDisabledClick] = React.useState(false);

    React.useEffect(() => {
        content && setSelect(content.select);
    }, [content]);

    const handleClose = () => {
        dispatch(changeControls({name: "showFabrixViewer", value: false}))
    };

    const handleClickNext = () => {
        setDisabledClick(true);
        if (select) {
            if (content.list.findIndex((el: Fabrix) => el.title === select.title) === content.list.length - 1) {
                setDisabledClick(false);
                return
            } else {
                const result = content.list.findIndex((el: Fabrix) => el.title === select.title);
                if (result !== -1 || result !== content.list.length) setSelect(content.list[result + 1]);
                setTimeout(() => {
                    setDisabledClick(false);
                }, 500);
            }
        }
    };

    const handleClickBack = () => {
        setDisabledClick(true);
        if (select) {
            if (content.list.findIndex((el: Fabrix) => el.title === select.title) === 0) {
                setDisabledClick(false);
                return
            } else {
                const result = content.list.findIndex((el: Fabrix) => el.title === select.title);
                if (result !== -1 || result !== 0) setSelect(content.list[result - 1]);
                setTimeout(() => {
                    setDisabledClick(false);
                }, 500);
            }
        }
    };

    const handleKeyDown = (e: any) => {
        if ("key" in e) {
            (e.key === "Escape" || e.key === "Esc") && handleClose();
        } else {
            (e.keyCode === 27) && handleClose();
        }

        if ("which" in e) {
            switch (e.which) {
                case 37: {
                    handleClickBack();
                    break;
                }
                case 39: {
                    handleClickNext();
                    break;
                }

                default : {
                    return
                }
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>{"Fabrix | Eurotech"}</title>
                <meta name="description"
                      content="Fabrix Swatch Catalog. To view a larger image, please click on the swatch."/>
            </Helmet>

            <Dialog
                open={showViewer}
                onBackdropClick={handleClose}
                className={styles.fabrix_dialog}
                classes={{paper: classes.paper}}
                onKeyDown={handleKeyDown}
            >
                <DialogContent className={styles.fabrix_wrapper}>
                    <div
                        className={styles.arrow_left}
                        onClick={!disabledClick ? handleClickBack : undefined}
                    >
                        <NavigateBeforeOutlinedIcon fontSize="large"/>
                    </div>

                    <div className={styles.fabrix_container}>
                        <div className={styles.title_container}>
                            <Typography className={styles.title}>{select && select.title}</Typography>
                        </div>
                        <img src={select && select.overview} alt="" className={styles.fabrix}/>
                    </div>

                    <div className={styles.arrow_right}
                         onClick={!disabledClick ? handleClickNext : undefined}
                    >
                        <NavigateNextOutlinedIcon fontSize="large"/>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}