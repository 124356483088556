import React from "react";
import {Helmet} from 'react-helmet';
import {Grid, Typography,} from "@material-ui/core";
import Footer from "../../components/footer.component";
import styles from './styles.module.scss';

export const PoliciesPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{"Warranty Information | Eurotech"}</title>
        <meta name="description"
              content={"In today's high-stakes marketplace and state-of-the-art technology, seating solutions must be available quickly and reliably."}/>
      </Helmet>
      <Grid container className={styles.wrapper}>
        <Grid item xs={12} md={12} id={'warranty'} className={styles.header_container}>
          <Typography className={styles.header_title}>
            WARRANTY INFORMATION
          </Typography>
          <Typography className={styles.sub_title}>
            In today's high-stakes marketplace and state-of-the-art technology, seating solutions must
            be available quickly and reliably. The following information applies to the Eurotech line:
          </Typography>
          <Typography className={styles.title}>
            The Eurotech Seating Limited Lifetime Warranty
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={styles.list_container}>
          <Grid item xs={12} sm={11} md={10} lg={8} className={styles.list}>
            <Typography className={styles.list_item}>
              For all products except for 24/7 chairs (see below), Eurotech warrants to the original
              purchaser all components for the life of the product with the exception of upholstery
              and foam, which will be warranted for five years.
            </Typography>
            <Typography className={styles.list_item}>
              For 24/7 chairs, Eurotech warrants to the original purchaser of all 24/7 chairs all
              components for 5 years with the exception of upholstery and foam which will be
              warranted for 2 years.
            </Typography>
            <Typography className={styles.list_item}>
              Eurotech warrants to the original purchaser that all parts will be free from
              material defects. Eurotech will repair or replace, at its option, any unaltered components.
            </Typography>
            <Typography className={styles.list_item}>
              Eurotech does not warrant any aftermarket hardware that is installed on the product.
              <a className={styles.dark_text}>This warranty is made as of 10/01/2017.</a>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={styles.list_container_right}>
          <Grid item xs={12} sm={11} md={10} lg={8} className={styles.list}>
            <Typography className={styles.list_item}>
              Eurotech’s warranty is limited to the normal use of the product in a forty hour work
              week and a 275 lb. weight limit unless otherwise noted.
            </Typography>
            <Typography className={styles.list_item}>
              Eurotech shall NOT be liable for consequential or incidental damage arising
              from any product defect.
            </Typography>
            <Typography className={styles.list_item}>
              Eurotech’s warranty is limited to replacement or repair and does not cover cost of
              transportation and labor. There are no other warranties expressed or implied other than
              those specifically described. Proof of purchase is required to validate this warranty.
            </Typography>
            <Typography className={styles.list_item}>
              Eurotech does not warrant customer’s own material.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} id={'lume'} className={styles.lume_container}>
          <Typography className={styles.lume_title}>
            LUME RECALL INFORMATION
          </Typography>
          <Typography className={styles.lume_subtitle}>
            For information on the Lume chair recall, visit
            <a target="_blank"
               href="http://www.lumechair.com/recall/"
            >
              www.lumechair.com/recall.
            </a>
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} id={'ship'} className={styles.quick_wrapper}>
          <Typography className={styles.quick_title}>
            QUICK SHIP
          </Typography>
          <Typography className={styles.quick_subtitle}>
            Eurotech guarantees to stay ahead of your needs by inventorying over 60,000 chairs at all
            times. We will guarantee to ship orders within 24 hours:
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={styles.list_container_down}>
          <Grid item xs={12} sm={11} md={10} lg={8} className={styles.list}>
            <Typography className={styles.list_item}>
              Customer must be cleared for credit approval
            </Typography>
            <Typography className={styles.list_item}>
              Orders must be received during normal business hours of 9 a.m. - 5 p.m. EST Monday -
              Friday, excluding holidays
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={styles.list_container_down_right}>
          <Grid item xs={12} sm={11} md={10} lg={8} className={styles.list}>
            <Typography className={styles.list_item}>
              Chairs must be in-stock at time of order
            </Typography>
            <Typography className={styles.list_item}>
              Non-shipment due to acts of God are not covered under this guarantee
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} className={styles.fabrix_container}>
          <Grid item xs={12} md={12} className={styles.images}>
            <img src={"/assets/images/policies/fabrix_logo.png"} alt=""/>
          </Grid>
          <Typography className={styles.title}>
            FABRIX SHIPMENTS
          </Typography>

          <Typography className={styles.fabrix_subtitle}>
            Fabrix by Eurotech will ship any of the 32 Fabrix chairs in your choice of 215 fabrics in 7
            working days. If you have any question or comments about our shipping, you can contact us
            at <a>customerservice@raynorgroup.com</a>.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Footer/>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}