import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import Model from "../../../../types/Model";
import Seating from '../../../../types/Seating'
import Fabric from '../../../../types/Fabric'
import {  CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { imageCompressConstants } from '../../../../constants/imageCompressConstants'
import { IOptions } from '../../../../types/IOptions'
import { useDispatch, useSelector } from 'react-redux'
import { fabricSelector, pendingSelector } from '../../../../redux/modules/customizer.module'
import { changeControls, selectOption } from '../../../../redux/modules/action.creators/customizer.actions'
import { isMobile, isTablet } from 'react-device-detect'
import { useResizeObserver } from '../../../../hooks/useResizeObserver'
import PopupComponent from "../../../../components/popup.component";
import DoneIcon from "@material-ui/icons/Done";
import { setActiveGrade } from "../../../../redux/modules/action.creators/app.actions";

interface IColorsComponent {
  options: IOptions
}

const ModelColors = ({  options } : IColorsComponent) => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null);
  const [visibleSlides, setVisibleSlides] = useState(6)
  const [ activeColor, setActiveColor ] = useState(null)

  const pending = useSelector(pendingSelector)
  const fabrics = useSelector(fabricSelector)

  const { width } = useResizeObserver({ ref });

  useEffect(() => {
    if(width > 550) setVisibleSlides(12)
    else setVisibleSlides(6)
  }, [width])

  const handleSelect = (option: Seating | Model | Fabric) => {
    if (activeColor && activeColor.id === option.id) {
      setActiveColor(null)
      dispatch(setActiveGrade(null))
      return
    }
    setActiveColor(option)
    option instanceof Seating && dispatch(changeControls({ name: 'reload', value: true }))
    dispatch(selectOption({ type: 'fabric', option: option }))
  }

  const checkActiveColor = ( id: string ) => {
    if(fabrics) {
      return fabrics.id === id
    }
    return false
  }

  const RenderInfo = React.memo(( fabric: Fabric ) => {
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltip__title}>{fabric.title.slice(0, fabric.title.indexOf('_'))}</span>
        <span className={styles.tooltip__serial}>{fabric.serial}</span>
        <div className={styles.tooltip__material}>
          <img className={styles.tooltip__image}
               src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`} alt=""/>
        </div>
        {fabric.description
          ? <p className={styles.tooltip__description}>{fabric.description}</p>
          : <p className={styles.tooltip__description}>It's a very pretty color, use it always</p>}
      </div>
    )
  })

  const renderAssets = () => {
    return options.fabrics
      .filter((option: Seating | Model | Fabric) => (option instanceof Model ? !!(option as Model).configurable : true))
      .filter((option: Seating | Model | Fabric) => (option instanceof Seating && option.title === 'Accessories' ? null : option))
      .filter((option: Seating | Model | Fabric) => (option instanceof Seating && option.title === 'All Chairs' ? null : option))
      .map((option: Seating | Model | Fabric, iterator) => option instanceof Fabric && option.title !== 'All Chairs' ? (
        isTablet || isMobile ? (
          <Slide
            key={option.id}
            index={iterator + 1}
          >
            <div
              key={option.id}
              className={styles.colors__item}
              onClick={() => !pending && handleSelect(option)}
              style={pending ? {pointerEvents: "none"} : {}}
            >
              {checkActiveColor(option.id) ? <DoneIcon className={styles.colors__active_color}/> : null}
              <img className={styles.preview} src={`https:${option.thumbnail}${imageCompressConstants.customizerCatalogThumbnail}`}/>
            </div>
          </Slide>
          ) : (
          <PopupComponent key={option.id} contentHTML={<RenderInfo {...option} />}>
            <div
              className={styles.colors__item}
              onClick={() => !pending && handleSelect(option)}
              style={pending ? {pointerEvents: "none"} : {}}
            >
              {checkActiveColor(option.id) ? <DoneIcon className={styles.colors__active_color}/> : null}
              <img className={styles.preview} src={`https:${option.thumbnail}${imageCompressConstants.customizerCatalogThumbnail}`}/>
            </div>
          </PopupComponent>
        )
        ) : null
      )
  }
  return isTablet || isMobile ? (
    <div ref={ref}>
      <CarouselProvider
        infinite={true}
        naturalSlideWidth={20}
        naturalSlideHeight={20}
        totalSlides={options.fabrics.length}
        visibleSlides={visibleSlides}
      >
        <div className={styles.slider}>
          <Slider>
            {renderAssets()}
          </Slider>
        </div>

      </CarouselProvider>
    </div>
    )
      : (
    <div className={styles.colors}>{renderAssets()}</div>
  )
}

export default ModelColors