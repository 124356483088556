// Sort
export const  HIGH_TO_LOW = '9-0'
export const  LOW_TO_HIGH = '0-9'

export const  MODEL_A_TO_Z = 'MODEL_A-Z'
export const  MODEL_Z_TO_A = 'MODEL_Z-A'

export const A_TO_Z = 'A-Z'
export const Z_TO_A = 'Z-A'


// Filters

export const GSA_FILTER = 'GSA'
export const FABRIX_FILTER = 'FABRIX'
export const DISCONTINUED_FILTER = 'DISCONTINUED'
