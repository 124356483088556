import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Routes from "../../../../app.router/router.constants";
import {LinkButton} from "../../../../components/buttons";
import styles from './styles.module.scss';

export const CreateArticle = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} className={styles.content}>
        <article className={styles.content_wrapper}>
          <Typography variant="h2" className={styles.title}>
            Create your own custom chair
          </Typography>
          <Typography variant="h6" className={styles.description}>
            Custom office chairs can take your productivity and branding to the next level.
            Choose from a series of well-designed seats, backs, and fabrics for an office chair that’s
            uniquely you and built with your comfort in mind.
          </Typography>
          <section className={styles.statistic_wrapper}>
            <div className={styles.statistic_content}>
              <h2 className={styles.heading}>36</h2>
              <p className={styles.field}>Chair Models</p>
            </div>
            <div className={styles.statistic_content}>
              <h2 className={styles.heading}>100+</h2>
              <p className={styles.field}>Fabrics</p>
            </div>
            <div className={styles.statistic_content}>
              <h2 className={styles.heading}>05</h2>
              <p className={styles.field}>Days</p>
            </div>
          </section>
          <LinkButton classes={{root: styles.link_wrapper}}>
            <Link to={Routes.Configurator} className={styles.link}>
              <Typography variant="caption">Configure</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
        </article>
      </Grid>
    </Grid>
  )
};
