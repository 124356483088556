import React from 'react'
import {
  ClickAwayListener,
  Grow,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'


interface IDropDownProps {
  children: MenuItemProps[]
  contentHTML: any,
  styles?: string,
  closeAfterChoice?: boolean,
  setOpenDropdown?: any,
}

const DropdownComponent = ({ children, contentHTML, styles, setOpenDropdown = () => {}, closeAfterChoice = true }: IDropDownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpenDropdown(Boolean(event.currentTarget))
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpenDropdown(false)
  }

  return (
    <>
      <button
        className={styles}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {contentHTML}
      </button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal

        onClick={closeAfterChoice ? handleClose : null}
        style={{ zIndex: 999 }}
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  style={{maxHeight: '70vh', overflowY: 'auto'}}
                  id="menu-list-grow"
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default DropdownComponent