import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.scss'
import DoneIcon from '@material-ui/icons/Done'
import Fabric from '../../../types/Fabric'
import PopupComponent from '../../../components/popup.component'
import { imageCompressConstants } from '../../../constants/imageCompressConstants'
import { useSelector } from 'react-redux'
import { adjustmentSelector } from '../../../redux/modules/app.module'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useResizeObserver } from '../../../hooks/useResizeObserver'


interface IColorsModel {
  checkActiveColor?: (id: string) => boolean,
  clickHandler?: (fabric: Fabric) => void,
  renderInfo?: (fabric: Fabric) => JSX.Element
}

const _visibleSliders = 6
const _maxColors = 15

const ExpressColor = ({ checkActiveColor, clickHandler, renderInfo }: IColorsModel) => {
  const adjustment = useSelector(adjustmentSelector)
  const ref = useRef<HTMLDivElement>(null)

  const [visibleSlides, setVisibleSlides] = useState<number>(_visibleSliders)

  const {width} = useResizeObserver({ref})

  const standardFabrics = useMemo(() => {
    if (adjustment) {
      return adjustment.fabrics.filter(fabric => !fabric.default)
    }
  }, [adjustment])

  useEffect(() => {
    if(width && width < 230) {
      setVisibleSlides(4)
      return
    }
    setVisibleSlides(_visibleSliders)
  }, [width])

  return (
    <div className={styles.colors} ref={ref}>
      {adjustment && standardFabrics.length < _maxColors ? (
        standardFabrics.map((fabric) => {
          return (
            <PopupComponent key={fabric.id} contentHTML={renderInfo(fabric)}>
              <div className={styles.colors__item} onClick={() => clickHandler(fabric)}>
                {checkActiveColor(fabric.id) ? <DoneIcon className={styles.colors__active_color}/> : null}
                <img className={styles.colors__img}
                     src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`} alt=""/>
              </div>
            </PopupComponent>
          )
        })
      ) : (
        <CarouselProvider
          naturalSlideWidth={30}
          naturalSlideHeight={30}
          totalSlides={standardFabrics.length}
          visibleSlides={visibleSlides}
        >
          <div className={styles.slider}>
            {standardFabrics.length > _visibleSliders ? (
              <ButtonBack className={styles.slider__back}>
                <ChevronLeft/>
              </ButtonBack>
            ) : null}
            <Slider className={styles.slider__main}>
              {standardFabrics.map((fabric, iterator) => {
                return (
                  <PopupComponent key={`${fabric.id}${iterator}`} contentHTML={renderInfo(fabric)}>
                    <Slide
                      index={iterator + 1}
                    >
                      <div className={styles.slider__item} onClick={() => clickHandler(fabric)}>
                        {checkActiveColor(fabric.id) ? <DoneIcon className={styles.slider__active_color}/> : null}
                        <img className={styles.colors__img}
                             src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`} alt=""/>
                      </div>
                    </Slide>
                  </PopupComponent>
                )
              })}
            </Slider>
            {standardFabrics.length > _visibleSliders ? (
              <ButtonNext className={styles.slider__next}>
                <ChevronRight/>
              </ButtonNext>
            ) : null}
          </div>

        </CarouselProvider>

      )}

    </div>
  )
}

export default React.memo(ExpressColor)
