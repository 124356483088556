import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ContactArticle } from '../../components/contact.article.component'
import Footer from '../../components/footer.component'
import styles from './styles.module.scss'

export const MaterialsPage = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={12} className={styles.header_container}>
        <span className={styles.category}>Material</span>
        <Typography className={styles.title}>Sleek leather. Lightweight, comfy mesh. Classic
          upholstery.</Typography>
        <Typography className={styles.sub_title}>
          Only the highest-quality materials are used to cover Eurotech chairs. Combine different
          materials for a unique look and feel in a range of modern colors.
        </Typography>
      </Grid>

      {/*line1*/}
      <div className={styles.container}>
        <Grid container className={styles.line_container}>
          <Grid item xs={12} sm={6} md={6} id={'leather'} className={`${styles.material_description} ${styles.line_container_left}`}>
            <Typography className={styles.title_material}>LEATHER</Typography>
            <Typography className={styles.sub_title_material}>Sleek. Classic.</Typography>
            <Typography className={styles.description_material}>
              Our premium-grade leather coverings give your chair a look that says classic sophistication.
              Leather chairs lend a professional look to your office and lux to your waiting rooms - unlike
              any other
              material. Eurotech Leather Chair Coverings are easy to clean and luxurious to sit in. Going for
              a
              sophisticated, classic look? Go with leather.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={`${styles.image_line1} ${styles.image}`}>
            <img src="" />
          </Grid>
        </Grid>
        {/*line2*/}
        <Grid container className={styles.line_container_reverse}>
          <Grid item xs={12} sm={6} md={6} id={'mesh'} className={`${styles.image_line2} ${styles.image}`}>
            <img src="" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={styles.material_description}>
            <Typography className={styles.title_material}>MESH</Typography>
            <Typography className={styles.sub_title_material}>Comfortable and Modern</Typography>
            <Typography className={styles.description_material}>
              Mesh chair coverings from Eurotech offer superior breathability. Ideal for long days in the
              office and hot,
              humid environments, Mesh coverings provide a highly comfortable chair with a modern look. Our
              line of Mesh
              Office Chair Coverings gives superior comfort, making distractions from neck and back pain a
              thing of the
              past. Choose from classic black and contemporary grays for a sleek, modern look for your office.
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Grid item xs={12} sm={12} md={12} className={styles.fabrix_wraapper}>
        <img src={'/assets/images/materials/fabrix_ico.png'} className={styles.logo} alt="" />
        <Typography className={styles.title_fabrix}>Thousands of Choices. <br /> Fast <b>7-Day</b> Shipping.</Typography>
        <Typography className={styles.sub_title_fabrix}>
          Our Fabrix Custom Upholstery Program gives you endless possibilities to create the perfect chair
          design for
          your office, conference rooms, waiting rooms, and more. Our Fabrix Upholstery line includes a wide
          range of
          different colors, patterns, and texture choices.
        </Typography>

      </Grid>

      <div className={styles.container}>
        {/*line3*/}
        <Grid container className={styles.line_container}>
          <div className={styles.imgWrapper}>
            <img src={'/assets/images/materials/banner_4_1.jpg'} alt="" />
          </div>
        </Grid>

        <Grid container className={styles.line_container}>
          <Grid item xs={12} sm={6} md={6} className={`${styles.material_description} ${styles.line_container_left}`}>
            <Typography className={styles.sub_title_material}>Showcase your unique tastes.</Typography>
            <Typography className={styles.description_material}>
              Delight clients and customers with beautiful Fabrix chairs that complement your office interior.
              It’s as simple as:<br />
              <br />
              <a className={styles.list}>Choose any Eurotech Chair design with the Fabrix logo</a>
              <a className={styles.list}>Select the pattern</a>
              <a className={styles.list}>Pick the color</a>

            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={`${styles.image_line3} ${styles.image}`}>
            <img src="" />
          </Grid>
        </Grid>
        {/*line4*/}
        <Grid container className={styles.line_container_reverse}>
          <Grid item xs={12} sm={6} md={6} id={'custom'} className={`${styles.image_line4} ${styles.image}`}>
            <img src="" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={styles.material_description}>
            <Typography className={styles.title_material}>CUSTOMER’S OWN MATERIAL</Typography>
            <Typography className={styles.sub_title_material}>Your Fabric. Our Chairs.</Typography>
            <Typography className={styles.description_material}>
              Have a fabric you absolutely love that would look perfect on the best seat in the house? We’ve
              got
              you (and your chair) covered. Our C.O.M design option lets you pick any chair from our
              proprietary
              Fabrix Program line. Next, we’ll upholster the chair you've chosen in your supplied fabric. The
              result?
              An ergonomic chair designed especially for you that showcases your unique aesthetic tastes.
            </Typography>
          </Grid>
        </Grid>
        {/*line5*/}
        <Grid container className={styles.line_container}>
          <Grid item xs={12} sm={12} md={6} id={'outlast'} className={`${styles.material_description} ${styles.line_container_center}`}>
            <Typography className={styles.title_material}>OUTLAST TECHNOLOGY</Typography>
            <Typography className={styles.sub_title_material}>21st Century Chairs.</Typography>
            <Typography className={styles.description_material}>
              The modern office requires a chair that can meet 21st-century demands. Eurotech Office Chairs
              outfitted
              with Outlast Technology provide you with a chair that’s ready to work-from-home and put in a day
              at the
              office as your partner in superior comfort. Any of our chairs that include the Fabrix logo allow
              you to
              add Outlast Technology to the design.<br />
              <br />
              Outlast Technology will:<br />
              <br />
              <a className={styles.list}>Control temperature for a perpetually cool, comfy seat</a>
              <a className={styles.list}>Reduce moisture for superior comfort in an office chair</a>
              <a className={styles.list}>Proactively reduce perspiration, instead of simply wicking it away</a>
              <a className={styles.list}>Absorb, store, and release body heat, maintaining a consistent temperature</a>


            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className={`${styles.image_line5} ${styles.image}`}>
            <img src="" />
          </Grid>
        </Grid>
        {/*line6*/}
        <Grid container className={`${styles.line_container_reverse} ${styles.reverse}`}>
          <Grid item xs={12} sm={12} md={6} id={'healthcare'} className={`${styles.image_line6} ${styles.image}`}>
            <img src="" />
          </Grid>

          <Grid item xs={12} sm={12} md={6} className={styles.material_description}>
            <Typography className={styles.title_material}>HEALTHCARE</Typography>
            <Typography className={styles.sub_title_material}>Prescriptive Coverings for Medical
              Facilities</Typography>
            <Typography className={styles.description_material}>
              Give your patients a clean, fresh environment with beautiful, ergonomic chairs that include
              coverings
              designed specifically for medical environments. Our line of Healthcare Chair Coverings offers a
              variety
              of unique features and components designed specifically for doctors' offices, hospitals, and
              other medical
              facilities. Just what the doctor ordered.<br />
              <br />
              <a className={styles.list}>Antimicrobial</a>
              <a className={styles.list}>Antibacterial</a>
              <a className={styles.list}>Antifungal</a>
              <a className={styles.list}>Moisture-resistant</a>
              <a className={styles.list}>Moisture-proof</a>


            </Typography>
          </Grid>
        </Grid>

        <Grid container className={styles.fabirics_links}>
          <div className={styles.flexRow}>
            <Grid id={'fabrics'} className={styles.container_title}>
              <Typography className={styles.title}>Discover more <br /> fabrics & swatches</Typography>
            </Grid>
            <Grid className={styles.link_container}>
              <a
                target="_blank"
                href="https://www.memosamples.com/"
                className={styles.link}>
                <img src={'/assets/images/materials/links/memo.png'} alt="" className={styles.link_logo} />
              </a>
            </Grid>
            <Grid className={styles.link_container}>
              <a
                target="_blank"
                href="https://www.guilfordofmaine.com/"
                className={styles.link}>
                <img src={'/assets/images/materials/links/guilf.png'} alt="" className={styles.link_logo} />
              </a>
            </Grid>
          </div>
          <div className={styles.flexRow}>
            <Grid className={styles.link_container}>
              <a
                target="_blank"
                href="https://www.culpcontract.com/"
                className={styles.link}>
                <img src={'/assets/images/materials/links/culp.png'} alt="" className={`${styles.link_logo} ${styles.culp_logo}`} />
              </a>
            </Grid>

            <Grid className={styles.link_container}>
              <a
                target="_blank"
                href="https://douglassfabric.com/Default.aspx"
                className={styles.link}>
                <img src={'/assets/images/materials/links/douglass.png'} alt="" className={styles.link_logo} />

              </a>
            </Grid>
            <Grid className={styles.link_container}>
              <a
                target="_blank"
                href="https://www.mayerfabrics.com/"
                className={styles.link}>
                <img src={'/assets/images/materials/links/mayer.png'} alt="" className={styles.link_logo} />

              </a>
            </Grid>
            <Grid className={styles.link_container}>
              <a
                target="_blank"
                href="https://mitchellfauxleathers.com/"
                className={styles.link}>
                <img src={'/assets/images/materials/links/mitchell.png'} alt="" className={styles.link_logo} />
              </a>
            </Grid>
          </div>

        </Grid>
      </div>

      <ContactArticle />
      <Footer />
    </Grid>
  )
}