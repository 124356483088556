import React from "react";
import {Grid, Typography} from "@material-ui/core";
import styles from './styles.module.scss';

export const ContactTab = () => {

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} md={12} className={styles.title_wrapper}>
        <Typography className={styles.title}>OUR HEADQUARTERS</Typography>
        <Typography className={styles.address}>525 Hempstead Turnpike West Hempstead, NY 11552</Typography>

        <Grid item xs={12} md={6} className={styles.phone_fax_wrapper}>
          <Typography className={styles.phone_fax_lf}>
            <img src={"/assets/images/contact/phone.svg"} alt=""/>
            1.800.637.0005
          </Typography>
          <Typography className={styles.phone_fax_rt}>
            <img src={"/assets/images/contact/fax.svg"} alt=""/>
            1.516.481.8141
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
