import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import { Link, useParams } from "react-router-dom";
import Routes from "../../../app.router/router.constants";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  BuyNowButton,
  LinkButton,
  OverviewArrow,
} from "../../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  activeColorSelector,
  activeGradeSelector,
  adjustmentSelector,
  modelSelector,
  screenshotSelector,
} from "../../../redux/modules/app.module";
import {
  selectOption,
  selectSequence,
} from "../../../redux/modules/action.creators/customizer.actions";
import Seating from "../../../types/Seating";
import Model from "../../../types/Model";
import Sequence from "../../../types/Sequence";
import styles from "./styles.module.scss";
import Slider from "react-slick";
import {
  changeControls,
  setActiveGrade,
} from "../../../redux/modules/action.creators/app.actions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DownloadIcon from "../../../components/icons.components/download.icon/download.icon";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { imageCompressConstants } from "../../../constants/imageCompressConstants";
import { formatterPrice } from "../../../utils/app.helpers/price.formatter";
import SpecificationsList from "../specifications.list";
import Accessories from "../accessories.component";

import colorsConstructor from "../colors.component/colors.constructor";
import StandardColor from "../colors.component/standard.color";
import ExpressColor from "../colors.component/express.color";
import AccessoriesSelected from "../accessories.component/accessories.selected";
import Contentful from "../../../utils/app.helpers/contentful";
import Fabric from "../../../types/Fabric";
import { Grade } from "../../../types/IGrades";
import FabricSection from "../../../types/FabricSection";
import Modifications from "../../../types/Modifications";
import classNames from "classnames";

interface IMapProps {
  seating?: Seating;
  model?: Model;
  screenshots: string[];
  specifications: {};
  specifications_new: any[];
}

export const PBannerComponent: React.FC<IMapProps> = ({
  seating,
  model,
  screenshots,
  specifications,
  specifications_new,
}) => {
  const dispatch = useDispatch();
  const adjustment = useSelector(adjustmentSelector);
  const selectScreenshot = useSelector(screenshotSelector);
  const activeGrade = useSelector(activeGradeSelector);
  //@ts-ignore
  let { title } = useParams();

  const [imageSaver, setImageSaver] = useState(false);
  const [customPrice, setCustomPrice] = useState(0 as number);
  const [selectedAccessories, setSelectedAccessories] = useState(null);

  const discountCalculate = (price: number, discountValue: number) => {
    return price - (price * discountValue) / 100;
  };
  const validPrice = (price: string) => {
    return Number(price.split(",").join(""));
  };
  const handleRedirect = () => {
    if (seating && model) {
      dispatch(selectOption({ type: "seating", option: seating }));
      dispatch(selectOption({ type: "model", option: model }));
      dispatch(
        selectSequence(
          new Sequence({ id: 2, type: "fabrics", title: "Fabric", label: "03" })
        )
      );
    }
  };
  const changeScreenShoots = (screen: string) => {
    dispatch(changeControls({ name: "screenshot", value: screen }));
  };

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <div className={styles.next_arrow}>
        <OverviewArrow onClick={onClick}>
          <ArrowForwardIosIcon
            className={styles.arrow_icon}
            style={{ fontSize: 20 }}
          />
        </OverviewArrow>
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { onClick } = props;

    return (
      <div className={styles.prev_arrow}>
        <OverviewArrow onClick={onClick}>
          <ArrowBackIosIcon
            className={styles.arrow_icon}
            style={{ marginLeft: "5px", fontSize: 20 }}
          />
        </OverviewArrow>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      screenshots && screenshots.length >= 4 ? 4 : screenshots.length,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow:
            screenshots && screenshots.length >= 3 ? 3 : screenshots.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:
            screenshots && screenshots.length >= 3 ? 3 : screenshots.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow:
            screenshots && screenshots.length >= 4 ? 3 : screenshots.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow:
            screenshots && screenshots.length >= 4 ? 4 : screenshots.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          vertical: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            screenshots && screenshots.length >= 3 ? 3 : screenshots.length,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  const downloadAllImages = async () => {
    if (imageSaver) return;

    let zip = new JSZip();
    setImageSaver(true);

    Promise.all(
      screenshots.map((el, inx) => {
        return fetch("https:" + el)
          .then((res) => res.blob())
          .then((blob) => {
            return new File([blob], `chair${inx}.jpg`, {
              type: "image/jpeg",
            });
          })
          .then((file) => {
            zip.file(`chair${inx}.jpg`, file, { base64: true });
            return zip;
          });
      })
    ).then((zip) => {
      zip[0]
        .generateAsync({ type: "blob" })
        .then(function (content) {
          saveAs(content, `${title}.zip`);
        })
        .then(() => setImageSaver(false));
    });
  };

  const downloadFabrixPdf = async () => {
    const _instance = Contentful.getInstance();
    const response: any = await _instance.client.getEntry(
      "4dFc2xaGu6VIbH3bpgjDXX"
    );

    const fileUrl = response?.fields?.fabrixFile?.fields?.file?.url;

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = response?.fields.fabrixFile.fields?.file?.title;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  interface Colors {
    express?: boolean;
    standard?: boolean;
  }

  const checkColors = (): Colors => {
    if (adjustment && adjustment.fabrics) {
      return {
        get standard() {
          return adjustment.fabrics.some((el: any) => el.default);
        },
        get express() {
          return (
            adjustment.fabrics.some((el: any) => !el.default) &&
            model.fabrixExpressColors
          );
        },
      };
    }
    return {};
  };

  const calculatePrice = () => {
    salePrice();

    Object.values(selectedAccessories).forEach((el: any) => {
      setCustomPrice((prev) => prev + el.price);
    });
  };

  const salePrice = () => {
    if (model) {
      if (model.isDiscounted) {
        if (activeGrade) {
          setCustomPrice(
            discountCalculate(
              validPrice(activeGrade.price),
              model.discountValue
            )
          );
        } else {
          setCustomPrice(discountCalculate(model.price, model.discountValue));
        }
        return;
      }
      if (activeGrade) {
        setCustomPrice(validPrice(activeGrade.price));
        return;
      }
      setCustomPrice(model.price);
    }
  };

  useEffect(() => {
    setSelectedAccessories(null);

    if (model) {
      if (model.isDiscounted) {
        setCustomPrice(discountCalculate(model.price, model.discountValue));
      } else {
        setCustomPrice(model.price);
      }
    }
  }, [model]);

  useEffect(() => {
    if (selectedAccessories) {
      calculatePrice();
      return;
    }
    salePrice();
  }, [selectedAccessories, activeGrade]);

  const ColorStandard = useMemo(() => colorsConstructor(StandardColor), []);
  const ColorExpress = useMemo(() => colorsConstructor(ExpressColor), []);

  return (
    <div className={styles.wrapper}>
      <Grid className={styles.overview_preview}>
        <div className={styles.preview_container}>
          {model.isDiscounted && (
            <div className={styles.preview_sale}>- {model.discountValue}%</div>
          )}
          <img
            src={`https:${selectScreenshot}${imageCompressConstants.productSliderPreview}`}
            alt=""
          />
          <div
            className={
              imageSaver
                ? `${styles.download_image} ${styles.active}`
                : styles.download_image
            }
            onClick={downloadAllImages}
          >
            <Typography variant={"caption"}>Download image</Typography>
            {imageSaver ? (
              <div className={styles.loader}>
                <div className={styles.element} />
                <div className={styles.element} />
                <div className={styles.element} />
                <div className={styles.large_square} />
              </div>
            ) : (
              <DownloadIcon styles={styles.download_icon} />
            )}
          </div>
        </div>
        <Grid
          item
          sm={12}
          md={12}
          className={styles.screen_container}
          style={
            screenshots && screenshots.length <= 4
              ? { maxWidth: `${150 * screenshots.length}px` }
              : { maxWidth: "600px" }
          }
        >
          <Slider {...settings} className={styles.slider}>
            {screenshots &&
              screenshots.map((screen: string, index: number) => {
                return (
                  <Grid key={index} className={styles.slide_container}>
                    <Grid item className={styles.screen}>
                      <div
                        className={
                          screen === selectScreenshot
                            ? styles.content_active
                            : styles.content
                        }
                        onClick={() => {
                          changeScreenShoots(screen);
                        }}
                      >
                        <img
                          className={styles.preview}
                          src={`https:${screen}${imageCompressConstants.productSliderPreview}`}
                          alt=""
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
          </Slider>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={styles.content}>
        {model && model.title && (
          <div className={styles.model}>
            <div className={styles.model__info}>
              <Typography variant="h2" className={styles.heading}>
                {adjustment.heading}
              </Typography>
              <Typography
                variant="h6"
                className={
                  model.title.includes("iOO") ? styles.label_ioo : styles.label
                }
              >
                {model.title.includes("-")
                  ? model.title.split("-").join("/")
                  : model.title}
              </Typography>
              {model.isDiscounted ? (
                <div className={styles.model_sale_container}>
                  <h6 className={styles.model_price_old}>
                    {formatterPrice(
                      activeGrade ? validPrice(activeGrade.price) : model.price
                    )}
                  </h6>
                  <h6 className={styles.model_price}>
                    {formatterPrice(customPrice)}
                  </h6>
                </div>
              ) : (
                <h6 className={styles.model_price}>
                  {formatterPrice(customPrice)}
                </h6>
              )}
            </div>

            <div className={styles.model_adjustment}>
              <div className={classNames(styles.model_allColors, adjustment.fabrics_new ? styles.model_allColors_multiple : '')}>
                {adjustment.fabrics_new ? (
                  <>
                    {adjustment.fabrics_new.map((section) => (
                      <div className={styles.model_colors}>
                        <span className={styles.model_subtitle}>
                          {section.title}
                        </span>
                        <ColorSection section={section} />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {checkColors().standard ? (
                      <div className={styles.model_colors}>
                        <span className={styles.model_subtitle}>
                          Standard fabric colors
                        </span>
                        <ColorStandard />
                      </div>
                    ) : null}
                  </>
                )}
                {checkColors().express ? (
                  <div className={styles.model_colors}>
                    <span className={styles.model_subtitle}>
                      Fabrix Express Colors
                    </span>
                    <ColorExpress />
                  </div>
                ) : null}
              </div>
              <div className={styles.model_additional_wrapper}>
                {model && model.additionalFabrixOptions && (
                  <Link
                    to={Routes.FabrixCatalog}
                    className={styles.model_additional}
                  >
                    Additional Fabrix options
                  </Link>
                )}

                {model && model.isFabrix && (
                  <button
                    onClick={downloadFabrixPdf}
                    className={`${styles.model_additional} + ${styles.model_additional_download}`}
                  >
                    Fabrix Express Offers
                  </button>
                )}
                {/* {console.log('Hello World', model.fabrixExpressPdf?.fields?.file?.url)} */}
              </div>

              {model.accessories && model.accessories.length ? (
                <div className={styles.model_accessories}>
                  <span className={styles.model_subtitle}>Accessories</span>
                  <div className={styles.model_accessoriesWrapper}>
                    <Accessories
                      model={model}
                      selectedAccessories={selectedAccessories}
                      setSelectedAccessories={setSelectedAccessories}
                    />
                    <AccessoriesSelected
                      selectedAccessories={selectedAccessories}
                      setSelectedAccessories={setSelectedAccessories}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <SpecificationsList
              specifications={specifications}
              specifications_new={specifications_new}
            />
          </div>
        )}

        <section
          className={styles.button_container}
          style={
            model && (model.buyNow || model.configurable)
              ? {}
              : { display: "none" }
          }
        >
          <LinkButton
            classes={{ root: styles.link_wrapper }}
            style={model && model.configurable ? {} : { display: "none" }}
          >
            <Link
              to={Routes.Configurator}
              onClick={() => handleRedirect()}
              className={styles.link}
            >
              <Typography variant="caption">Configure</Typography>
              <ChevronRightIcon className={styles.link_icon} />
            </Link>
          </LinkButton>

          <BuyNowButton
            classes={{ root: styles.BN_link_wrapper }}
            style={model && model.buyNow ? {} : { display: "none" }}
          >
            <a href={model.buyNow} target="_blank" className={styles.link}>
              <Typography variant="caption">Buy now</Typography>
              <ChevronRightIcon className={styles.link_icon} />
            </a>
          </BuyNowButton>
        </section>
      </Grid>
    </div>
  );
};

type Props = {
  section: FabricSection;
};

const ColorSection = ({ section }: Props) => {
  const dispatch = useDispatch();

  const model = useSelector(modelSelector);
  const adjustment = useSelector(adjustmentSelector);
  const activeColor = useSelector(activeColorSelector);
  const [loadedColors, setLoadedColors] = React.useState(null);

  React.useEffect(() => {
    const _instance = Contentful.getInstance();
    const promisesArr = section.fabrics.map((fabric) =>
      //@ts-ignore
      _instance.client.getEntry(fabric.sys.id).then((res) => res)
    );
    Promise.all(promisesArr).then((res) => {
      setLoadedColors(res.map((res) => new Fabric(res)));
    });
  }, [section]);

  const checkActiveColor = (id: string) => {
    if (activeColor) {
      return activeColor.id === id;
    }
    return false;
  };
  const clickHandler = (fabric: Fabric) => {
    if (model && activeColor && activeColor.id === fabric.id) {
      dispatch(changeControls({ name: "activeColor", value: null }));
      dispatch(setActiveGrade(null));
      return;
    }

    dispatch(setActiveGrade(null));
    dispatch(changeControls({ name: "activeColor", value: fabric }));

    if (adjustment && adjustment.grades) {
      adjustment.grades.grades.forEach((item: Grade) => {
        if (!item.title || !fabric.gradeTitle) return;
        if (fabric.gradeTitle.toLowerCase() === item.title.toLowerCase()) {
          dispatch(setActiveGrade(item));
        }
      });
    }
    if (adjustment && adjustment.modifications) {
      adjustment.modifications.forEach((item: Modifications) => {
        if (item.grade) {
          item.grade.forEach((el: Grade) => {
            if (!el.title || !fabric.gradeTitle) return;

            if (fabric.gradeTitle.toLowerCase() === el.title.toLowerCase()) {
              dispatch(setActiveGrade(el));
            }
          });
        }
      });
    }
  };
  const renderInfo = (fabric: Fabric) => {
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltip__title}>
          {fabric.title.slice(0, fabric.title.indexOf("_"))}
        </span>
        <span className={styles.tooltip__serial}>{fabric.serial}</span>
        <div className={styles.tooltip__material}>
          <img
            className={styles.tooltip__image}
            src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`}
            alt=""
          />
        </div>
        {fabric.description ? (
          <p className={styles.tooltip__description}>{fabric.description}</p>
        ) : (
          <p className={styles.tooltip__description}>
            It's a very pretty color, use it always
          </p>
        )}
      </div>
    );
  };
  return (
    <>
      {loadedColors && (
        <StandardColor
          checkActiveColor={checkActiveColor}
          clickHandler={clickHandler}
          renderInfo={renderInfo}
          certainSection={{ ...section, fabrics: loadedColors }}
        />
      )}
    </>
  );
};
