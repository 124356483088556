import React, { useState } from "react";
import { changeControls } from "../../redux/modules/action.creators/app.actions";
import { useDispatch, useSelector } from "react-redux";
import { gsaResSelector } from "../../redux/modules/app.module";
import { Grid } from "@material-ui/core";
import Model from "../../types/Model";
import Footer from "../../components/footer.component";
import styles from './styles.module.scss';
import { AssetsLoader } from "../../components/assets.loader";
import ProductCard from '../categories/product.catalog/product.card/product.card'

export const GsaProgram = () => {
  const dispatch = useDispatch();
  const list = useSelector(gsaResSelector);

  const [loading, setLoading] = useState(true)

  React.useEffect(() => {
    dispatch(changeControls({ name: "gsa", value: 'GSA' }));
  }, []);

  React.useEffect(() => {
    if (list) {
      setLoading(false)
    }
  }, [list])

  return (
    <Grid container className={styles.wrapper}>
      {
        loading ? (
          <section className={styles.preloader}>
            <AssetsLoader />
          </section>
        ) : (
          <Grid item xs={12} className={styles.content_wrapper}>
            <Grid className={styles.products}>
              {
                list && list.map((model: Model) => {
                  return (
                    <ProductCard
                      key={model.id}
                      model={model}
                      isDiscounted={false}
                    />
                  )
                })
              }
            </Grid>
          </Grid>
        )
      }
      <Footer />
    </Grid>
  )
}
