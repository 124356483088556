import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import sprite from './usa-map.svg'
import Region from '../../types/Region'
import { regionSelector } from '../../redux/modules/app.module'
import { changeControls } from '../../redux/modules/action.creators/app.actions'
import styles from './styles.module.scss'

interface IProps {
  regions: Region[]
}

export const RepresentativeComponent: React.FC<IProps> = ({ regions }) => {
  const region = useSelector(regionSelector)
  const dispatch = useDispatch()

  const handleChangeRegion = (_region: Region) => {
    dispatch(changeControls({ name: 'region', value: _region }))
  }

  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={12} md={9} className={styles.map_container}>
        <Typography className={styles.title}>
          EUROTECH REPRESENTATIVES BY REGION
        </Typography>

        <svg className={styles.map}>
          <use href={sprite + '#map-usa'} />
          {regions &&
            region &&
            regions.map((rr, index) => {
              const regionStates = rr.states.split(', ')
              return regionStates.map((el: string) => (
                <use
                  key={el}
                  onClick={() => handleChangeRegion(rr)}
                  className={
                    rr.uuid === region.uuid ? styles.active : styles.region
                  }
                  href={sprite + `#${el.toUpperCase()}`}
                />
              ))
            })}
        </svg>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        className={styles.description_container}
      >
        <Typography className={styles.title}>{region.states}</Typography>

        <div className={styles.distributors_wrapper}>
          {region &&
            region.distributors.map((des, index) => {
              return (
                <section key={index} className={styles.distributor_container}>
                  <Typography className={styles.name}>{des.name}</Typography>
                  <Typography className={styles.text}>
                    {des.description}
                  </Typography>
                  <Typography className={styles.text}>{des.email}</Typography>
                  <Typography className={styles.text}>{des.phone}</Typography>
                  <Typography className={styles.text}>{des.fax}</Typography>
                </section>
              )
            })}
        </div>
      </Grid>
    </Grid>
  )
}
