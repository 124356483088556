import {  useMemo, useEffect } from "react";
import { debounce } from "lodash";
import { useLatest} from './useLatest'

function makeDebouncedHook(debounceFn : (...args: any) => any)  {
  return function useDebounce(cb : (...args: any) => void, ms: number) {
    const latestCb = useLatest(cb);

    const debouncedFn = useMemo(
      () =>
        debounceFn((...args: any) => {
          latestCb.current(...args);
        }, ms),
      [ms, latestCb]
    );

    useEffect(() => () => debouncedFn.cancel(), [debouncedFn]);

    return debouncedFn;
  };
}
export const useDebounce = makeDebouncedHook(debounce);