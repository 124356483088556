import React from 'react';

const useWindowWidth = () => {
  const [width, setWidth] = React.useState(0);
  React.useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth);
    };
    cb();

    window.addEventListener('resize', cb);

    return () => window.removeEventListener('resize', cb);
  }, []);

  return width;
};

export default useWindowWidth;
