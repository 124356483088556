import React, { Reducer, useEffect, useReducer, useState } from "react";
import Model from "../types/Model";
import {
  A_TO_Z,
  DISCONTINUED_FILTER,
  FABRIX_FILTER,
  GSA_FILTER,
  HIGH_TO_LOW,
  LOW_TO_HIGH,
  MODEL_A_TO_Z,
  MODEL_Z_TO_A,
  Z_TO_A,
} from "../constants/filters.constants";

const sortFcBigger = (a: Model, b: Model, searchField: keyof Model) => {
  const nameA = a[searchField].toString().toLowerCase();
  const nameB = b[searchField].toString().toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const sortFcLower = (a: Model, b: Model, searchField: keyof Model) => {
  const nameA = a[searchField].toString().toLowerCase();
  const nameB = b[searchField].toString().toLowerCase();
  if (nameB < nameA) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const useFilterModels = (
  models: Model[],
  filter: string,
  sort: string,
  showAll: boolean,
  category: string = ''
) => {
  const [filteredModels, setFilteredModels] = useState([] as Model[]);

  const filterHandler = (models: Model[], filter: string) => {
    switch (filter) {
      case GSA_FILTER:
        return models.filter((model) => model.gsa);
      case FABRIX_FILTER:
        return models.filter((model) => model.isFabrix);
      case DISCONTINUED_FILTER:
        return models.filter((model) => model.isDiscounted);
      default:
        return models;
    }
  };

  useEffect(() => {
    if (sort && models.length) {
      let copy = [...models];
      setFilteredModels(copy);
      switch (sort) {
        case A_TO_Z:
          copy = filterHandler(
            copy.sort((a, b) => sortFcBigger(a, b, "title")),
            filter
          );
          break;
        case Z_TO_A:
          copy = filterHandler(
            copy.sort((a, b) => sortFcLower(a, b, "title")),
            filter
          );
          break;
        case MODEL_A_TO_Z:
          copy = filterHandler(
            copy.sort((a, b) => sortFcBigger(a, b, "sku")),
            filter
          );
          break;
        case MODEL_Z_TO_A:
          copy = filterHandler(
            copy.sort((a, b) => sortFcLower(a, b, "sku")),
            filter
          );
          break;
        case LOW_TO_HIGH:
          copy = filterHandler(
            copy.sort((a, b) => a.price - b.price),
            filter
          );
          break;
        case HIGH_TO_LOW:
          copy = filterHandler(
            copy.sort((a, b) => b.price - a.price),
            filter
          );
          break;
        default:
          // if (showAll) {
          //   copy = filterHandler(copy.filter((model: Model) => sort === 'All' ? true : model.category === sort), filter)
          //   break
          // }
          console.log(category)
          if (category && category !== "All Chairs") {
            copy = copy.filter(
              (model: Model) =>
                model.category.toLowerCase() === category.toLowerCase()
            );
          }
          copy = filterHandler(
            copy.filter((model: Model) =>
              sort === "All" ? true : model.serial === sort
            ),
            filter
          );
          break;
      }
      setFilteredModels(copy);
    }
  }, [sort, filter, models]);

  return { filteredModels, setFilteredModels };
};

export default useFilterModels;
