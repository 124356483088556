import React from "react";
import {Helmet} from 'react-helmet';
import {AboutTab} from "./about.tab";
import styles from './styles.module.scss';
import Footer from "../../components/footer.component";
import {Box, Grid, Tab, Tabs, withStyles} from "@material-ui/core";

interface TabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabController = withStyles({
  root: {
    justifyContent: 'flex-start',
    padding: 0,
  },
  indicator: {
    height: '1px',
  }
})((props: TabsProps) => <Tabs variant="standard" {...props} />);

const TabButton = withStyles({
  root: {
    width: '100px',
    textTransform: 'capitalize',
    fontFamily: 'Muli-SemiBold, sans-serif',
    fontSize: '13px',
    lineHeight: '30px',
    letterSpacing: '0.13px',
    color: '#444',
    opacity: 1,
    backgroundColor: '#fff',
    '&:hover': {
      color: '',
      opacity: 1,
    },
    '&:focus': {
      color: '#D13532'
    },
  },
  selected: {
    backgroundColor: '#fff',
    color: '#D13532'
  }
})(Tab);

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <section className={styles.panel}
             role="panel"
             hidden={value !== index}
             id={`panel-${index}`}
             aria-labelledby={`tab-${index}`}
             {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </section>
  );
}

export const AboutPage = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>{"About | Eurotech"}</title>
        <meta name="description"
              content={"Eurotech has been the premier representative for quality and ergonomic design in office chairs since 1979. Our commitment to design, function, and form has resulted in over 1 billion dollars in cumulative sales. See the Eurotech difference for yourself."}/>
      </Helmet>
      <Grid container className={styles.wrapper}>
        <Grid item xs={12} md={12} className={styles.tab_panel_wrapper}>
          <AboutTab/>
        </Grid>
        <Footer/>
      </Grid>
    </React.Fragment>
  )
}
