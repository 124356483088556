import { RefObject, useEffect } from 'react'

interface IScrollProps {
  elements: RefObject<any>[],
  depends?: any[],
  time?: number
}

export const useScrollFixHook = ({ elements = [], depends = [], time = 400 }: IScrollProps)  => {

  const checkScrollElement = (offsetHeight : number, scrollHeight: number ) => {
    return offsetHeight === scrollHeight
  }

  const blockScroll = () => {
    elements.forEach((element) => {
      if(!!element.current) {

        const isScroll = checkScrollElement(element.current.offsetHeight, element.current.scrollHeight)

        if (isScroll) {
          element.current.style.touchAction = "none"
        } else {
          element.current.style.touchAction = "pan-y"
          if(element.current.scrollTop === 0) element.current.scrollTop = 1
          element.current.addEventListener("scroll", function () {
            fixScroll(element.current)})
        }
      }
    })
  }

  const fixScroll = (el : any) => {
    if (el.scrollTop === 0) {
      el.scrollTop = 1
    }
    if ((el.scrollTop + el.offsetHeight) === el.scrollHeight) {
      el.scrollTop -= 1
    }
  }

  useEffect(() => {

    elements.forEach((element) => {
      if (!!element.current) {
        setTimeout(blockScroll, time)
        window.addEventListener("resize", ()=>{
          setTimeout(blockScroll, time)
        });
      }
    })

    return () => {
      // window.removeEventListener("resize", blockScroll)
    }
  }, [elements, ...depends])

  return elements
}