import React from "react";
import {Grid, Typography} from "@material-ui/core";
import styles from './styles.module.scss';

export const ServiceComponent = () => {
  return(
    <>
      <Grid container className={styles.wrapper}>
        <Grid item xs={12} sm={12} md={12} className={styles.title_container}>
          <Typography className={styles.title}>Eurotech is committed <br/> to offering personalized service.</Typography>
          <Typography className={styles.sub_title}>
            By limiting its number of retail customers, Eurotech can focus on providing individualized,
            customized care. The result? Fast communication, quick shipping, and personalized products
            for Eurotech’s valued customers.
          </Typography>
        </Grid>

        <Grid  className={styles.card_wrapper}>
          <Grid className={styles.card_line_col}>

            <Grid  className={styles.card_container}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_6.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>Experience</Typography>
                <Typography className={styles.sub_title}>
                  With over 40 years in the business, Eurotech has built quality chairs and a reliable
                  company designed to last.
                </Typography>
              </section>
            </Grid>

            <Grid className={`${styles.card_container} ${styles.card_container__large}`}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_5.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>A leader in Ergonomic Office Seating</Typography>
                <Typography className={styles.sub_title}>
                  Committed to offering seating solutions that are engineered for maximum comfort and efficiency.
                </Typography>
              </section>
            </Grid>

          </Grid>

          <Grid  className={`${styles.card_line_col} ${styles.card_most_items}`}>

            <Grid  className={styles.card_container}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_4.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>Customized Products</Typography>
                <Typography className={styles.sub_title}>
                  Customers can choose from a variety of adjustable components, seat designs,
                  fabrics, and colors to meet their needs and office decor.
                </Typography>
              </section>
            </Grid>

            <Grid  className={styles.card_container}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_2.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>Quality</Typography>
                <Typography className={styles.sub_title}>
                  Eurotech office products are tested against BIFMA industry
                  standards, ensuring high quality and responsibility.
                </Typography>
              </section>
            </Grid>

            <Grid  className={styles.card_container}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_3.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>Design</Typography>
                <Typography className={styles.sub_title}>
                  Dedicated to offering comfortable chairs that are also beautiful for the best seat in the house.
                </Typography>
              </section>
            </Grid>

          </Grid>

          <Grid  className={styles.card_line_col}>

            <Grid  className={`${styles.card_container} ${styles.card_container__large}`}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_1.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>Unparalleled Customer Service</Typography>
                <Typography className={styles.sub_title}>
                  Ability to work one-on-one with a knowledgeable, friendly Eurotech representative
                  for a personalized chair design.
                </Typography>
              </section>
            </Grid>

            <Grid  className={styles.card_container}>
              <section className={styles.card}>
                <img src={"/assets/images/about/icons/ico_7.png"} alt="" className={styles.icon}/>
                <Typography className={styles.title}>Quick, Reliable Shipping</Typography>
                <Typography className={styles.sub_title}>
                  Keeping an inventory of 60,000 chairs at all times ensures fast shipping within 7 business days.
                </Typography>
              </section>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
