export const imageCompressConstants = {
  categoriesBanner: '?h=1280&fm=webp&q=80/',
  _galleryThumb: '?w=1920&h=1080&fm=webp&q=80/',
  searchResultThumb: '?w=100&h=100&fm=webp&q=80',
  productCardThumb: '?h=512&fm=webp&q=70/',
  productBannerThumb: '?h=1920&fm=webp&q=80/',
  productSliderPreview: '?h=1280&fm=webp&q=80/',
  productFabricPreview: '?h=150&fm=webp&q=80/',
  fabrixCatalogThumb: '?fm=webp&q=80/',
  customizerCatalogThumbnail: '?h=512&fm=webp&q=80/',
  bannerImage: '?w=1920&h=1080&fm=webp&q=80',
  fabricConfigurator: '?h=512&fm=webp&q=70/',
  accessoriesPreview: '?h=100&fm=webp&q=70/',
};
