import React from 'react';

import { Grid, Typography } from "@material-ui/core";
import Modifications from "../../../../types/Modifications";
import Adjustment from "../../../../types/Adjustment";
import Model from "../../../../types/Model";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { activeGradeSelector } from "../../../../redux/modules/app.module";
import { Grade } from "../../../../types/IGrades";

interface IMapProps {
  adjustment: Adjustment,
  model: Model,
}

export const Grades: React.FC<IMapProps> = ( { adjustment, model } ) => {
  const activeGrade = useSelector(activeGradeSelector)
  const { modifications, grades } = adjustment;

  const checkActiveGrade = ( title: string ) => {
    if(activeGrade) {
      return activeGrade.title.toLowerCase() === title.toLowerCase()
        ? `${styles.border} ${styles.active}`
        : styles.border
    }
    return styles.border
  }
  return (
    <>
      {
        modifications ? modifications.map(( el: Modifications ) => {
            return (
              <Grid key={el.title} container className={styles.ver2_wrapper}>
                <Grid item md={12} className={!el.grade ? styles.chair_container : styles.chair_container_grade}>
                  <Grid item xs={6} md={6} className={styles.item_container}>
                    <section className={styles.text_container}>
                      <Typography className={styles.sku}>{el.sku}</Typography>
                      <Typography className={styles.title}>{el.title}</Typography>
                      {
                        el.priceDescription &&
                          <Typography className={styles.description}>
                            {el.priceDescription}
                          </Typography>
                      }
                    </section>
                    <Typography className={styles.price}>{el.price}</Typography>
                    {
                      model.surcharge &&
                        <Typography className={styles.price_des}>
                            (add 10% tariff surcharge until further notice)
                        </Typography>
                    }
                  </Grid>

                  {
                    el.option && el.option.map(( option: any ) => {
                      return (
                        <Grid key={option.title} item xs={6} md={3}
                              className={styles.item_container}>
                          <section className={styles.text_container}>
                            <Typography className={styles.sku}>{option.sku}</Typography>
                            <Typography className={styles.title}>{option.title}</Typography>
                          </section>
                          <Typography className={styles.price}>{option.price}</Typography>
                          {
                            model.surcharge &&
                              <Typography className={styles.price_des}>
                                  (add 10% tariff surcharge until further notice)
                              </Typography>
                          }
                        </Grid>
                      )
                    })
                  }

                </Grid>

                {
                  el.grade &&
                    <Grid item xs={12} md={12} className={styles.fabrix_container}>
                      {
                        el.grade.map(( el: Grade ) => (
                          <section key={el.title} className={styles.fabrix_item}>
                            <div className={checkActiveGrade(el.title)} />
                            <Typography className={styles.title}>{el.title}</Typography>
                            <Typography className={styles.price}>${el.price}</Typography>
                          </section>
                        ))
                      }

                    </Grid>
                }

              </Grid>
            )
          })
          : null
      }
      {
        !modifications && !grades || grades && !grades.grades && grades.options && !modifications ?
          <Grid container className={styles.ver1_wrapper}>
            <Grid item xs={12} md={6} className={styles.chair_container} style={{ paddingTop: 0 }}>
              <Typography className={styles.sku}>
                {adjustment.heading}
              </Typography>
              <Typography className={styles.chair_name}>
                {model.title}
              </Typography>
              {
                model.priceDescription &&
                  <Typography className={styles.description}>
                    {model.priceDescription}
                  </Typography>
              }

              <Typography className={styles.price}>
                ${model.price}
              </Typography>
              {
                model.surcharge &&
                  <Typography className={styles.price_des}>
                      (add 10% tariff surcharge until further notice)
                  </Typography>
              }

            </Grid>
            {
              grades && grades.options && grades.options.map(( option ) => {
                return (
                  <Grid key={option.title} item xs={6} md={3} className={styles.item_container}>
                    <section className={styles.text_container}>
                      <Typography className={styles.sku}>{option.heading}</Typography>
                      <Typography className={styles.title}>{option.title}</Typography>
                    </section>
                    <Typography className={styles.price}>${option.price}</Typography>
                  </Grid>
                )
              })
            }
          </Grid> : null
      }
      {
        grades && grades.grades && !modifications ?
          <Grid container className={styles.ver2_wrapper}>
            <Grid item md={12} className={styles.chair_container}>
              <Grid item xs={12} md={6} className={styles.item_container}>
                <section className={styles.text_container}>
                  <Typography className={styles.sku}>{adjustment.heading}</Typography>
                  <Typography className={styles.title}>{model.title}</Typography>
                  {
                    model.priceDescription &&
                      <Typography className={styles.description}>
                        {model.priceDescription}
                      </Typography>
                  }
                </section>
                <Typography className={styles.price}>${model.price}</Typography>
                {
                  model.surcharge &&
                    <Typography className={styles.price_des}>
                        (add 10% tariff surcharge until further notice)
                    </Typography>
                }
              </Grid>

              {
                grades && grades.options && grades.options.map(( option ) => {
                  return (
                    <Grid key={option.title} item xs={6} md={3} className={styles.item_container}>
                      <section className={styles.text_container}>
                        <Typography className={styles.sku}>{option.heading}</Typography>
                        <Typography className={styles.title}>{option.title}</Typography>
                      </section>
                      <Typography className={styles.price}>${option.price}</Typography>
                    </Grid>
                  )
                })
              }

            </Grid>

            <Grid item xs={12} md={12} className={styles.fabrix_container}>

              {grades.grades && grades.grades.map(( el ) => (
                <section key={el.title} className={styles.fabrix_item}>
                  <div className={checkActiveGrade(el.title)} />
                  <Typography className={styles.title}>{el.title}</Typography>
                  <Typography className={styles.price}>${el.price}</Typography>
                </section>
              ))

              }

            </Grid>
          </Grid> : null
      }
    </>
  )
}
