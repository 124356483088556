import {all} from 'redux-saga/effects';
import {saga as appSaga} from "./modules/app.module";
import {saga as dialogSaga} from './modules/dialog.module';
import {saga as customizerSaga} from "./modules/customizer.module";

export default function* root () {
    yield all([
        appSaga(),
        dialogSaga(),
        customizerSaga()
    ])
}