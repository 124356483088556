import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import appReducer, {moduleName as appModule} from "./modules/app.module"; //'app'
import dialogReducer, {moduleName as dialogModule} from "./modules/dialog.module";
import customizerReducer, {moduleName as customizerModule} from "./modules/customizer.module";

const reducer = combineReducers({
    router: routerReducer,
    [appModule]: appReducer,
    [dialogModule]: dialogReducer,
    [customizerModule]: customizerReducer
});

export default reducer;
export type RootState = ReturnType<typeof reducer>;
