import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch, Router, Route } from 'react-router-dom'
import Routes from './router.constants'
import history from '../utils/app.helpers/history'

import { loadingSelector as appLoading, partnerListSelector } from '../redux/modules/app.module'
import {
  adjustmentSelector,
  modelSelector,
  seatingsSelector,
  seatingSelector,
  sequenceSelector, reloadSelector,
} from '../redux/modules/customizer.module'
import {
  fetchDesign,
  fetchEntries,
} from '../redux/modules/action.creators/customizer.actions'
import { changeControls } from '../redux/modules/action.creators/app.actions'

import Seating from '../types/Seating'
import Model from '../types/Model'
import Adjustment from '../types/Adjustment'

import Homepage from '../pages/homepage/homepage.container'
import Customizer from '../pages/customizer/customizer.container'

import Header from '../components/header.component'
import { Intro } from '../pages/intro'
import { AssetsLoader } from '../components/assets.loader'
import { Categories } from '../pages/categories'
import { Product } from '../pages/product'
import { ContactPage } from '../pages/contact'
import { AboutPage } from '../pages/about'
import { MaterialsPage } from '../pages/materials'
import { PoliciesPage } from '../pages/policies'
import { PrivacyPolicy } from '../pages/privacy'
import { GsaAdvantage } from '../pages/gsa.advantage'
import { GsaProgram } from '../pages/gsa.program'
import { VideoPopup } from '../components/video.component'
import ScrollToTop from './ScrollTop'
import { FabrixCatalog } from '../pages/fabrix.catalog'
import { FabrixPopup } from '../components/fabrix.popup.component'
import { PartnerPage } from '../pages/partner'
import Partner from '../types/Partner'
import { PartnerProduct } from '../pages/partner.product'
import { imageCompressConstants } from '../constants/imageCompressConstants'
import { ResourcesPage } from '../pages/resources'
import { Parts } from '../pages/parts'


const AppRouter: React.FC = () => {
  const dispatch = useDispatch()

  const reload = useSelector(reloadSelector)
  const loading = useSelector(appLoading)
  const sequence = useSelector(sequenceSelector)
  const seatings = useSelector(seatingsSelector)
  const seating = useSelector(seatingSelector)
  const model = useSelector(modelSelector)
  const adjustment = useSelector(adjustmentSelector)
  const partnersList = useSelector(partnerListSelector)
  
  React.useEffect(() => {
    if (!sequence && !seating) {
      dispatch(changeControls({ name: 'loading', value: true }))
      dispatch(fetchDesign())
    }
  }, [])

  React.useEffect(() => {
    //TODO Note: after select seating fetch models and build Model instances
    if ((seating && !model) || (seating && reload)) {
      dispatch(fetchEntries(seating.entries, 'models', { class: Model }))
    }
  }, [seating])

  React.useEffect(() => {
    //TODO Note: after select model fetch modifications and build Adjustment instances
    if (model)
      dispatch(
        fetchEntries(model.entries, 'adjustments', { class: Adjustment }),
      )
  }, [model])

  React.useEffect(() => {
    if (adjustment) {
      setTimeout(() => {
        dispatch(changeControls({ name: 'loading', value: false }))
      }, 2000)
    }
  }, [adjustment])

  const renderCategories = () =>
    seatings.map((seating: Seating) => {
      const Category: React.FC = () => <Categories seating={seating} />
      const Details: React.FC = () => <Product seating={seating} />
      return (
        <React.Fragment key={seating.id}>
          <Route
            exact
            path={`/${seating.title.toLowerCase()}`}
            component={Category}
          />
          <Route
            exact
            path={`/${seating.title.toLowerCase()}/:title`}
            component={Details}
          />
        </React.Fragment>
      )
    })

  const renderPartnerProd = () =>
    partnersList.map((partnerEl: Partner) => {
      const Details: React.FC = () => <Product partner={partnerEl} />
      return (
        <React.Fragment key={partnerEl.id}>
          <Route
            exact
            path={`/${partnerEl.partnerName.split(' ').join('_').toLowerCase()}/:title`}
            component={Details}
          />
        </React.Fragment>
      )
    })

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        {
          loading && (
            <Route
              path={'*'}
              children={() => {
                return <section style={{
                  position: 'fixed',
                  bottom: 0,
                  top: 0,
                  width: '100%',
                  overflow: 'hidden',
                  pointerEvents: 'none',
                }}><AssetsLoader /></section>
              }}
            />
          )
        }

        <React.Fragment>
          <Header />
          <VideoPopup />
          <FabrixPopup />
          <div id="event_listener">
            <Route exact path={Routes.Homepage} component={Homepage} />
            <Route path={Routes.Contact} component={ContactPage} />
            <Route path={Routes.About} component={AboutPage} />
            <Route path={Routes.Material} component={MaterialsPage} />
            <Route path={Routes.PoliciesPage} component={PoliciesPage} />
            <Route path={Routes.PrivacyPolicy} component={PrivacyPolicy} />
            <Route path={Routes.GsaAdvantage} component={GsaAdvantage} />
            <Route path={Routes.GsaProgram} component={GsaProgram} />
            <Route path={Routes.FabrixCatalog} component={FabrixCatalog} />
            {/* <Route path={Routes.Parts} component={Parts} /> */}
            {seatings && renderCategories()}
            {partnersList && renderPartnerProd()}
            <Route path={`${Routes.PartnerPage}/:uid`} component={PartnerPage} />
            <Route path={Routes.Configurator} component={Customizer} />
            <Route path={Routes.ResourcesPage} component={ResourcesPage} />

          </div>
        </React.Fragment>
      </Switch>
      {seatings && seatings.length
        ? seatings.map((el: Seating, index: number) => {
          return (
            <div key={index} style={{ display: 'none' }}>
              <img src={`https:${el!.thumbnail}${imageCompressConstants.productCardThumb}`} alt="" />
              <img src={`https:${el!.angle_thumbnail}${imageCompressConstants.productCardThumb}`} alt="" />
            </div>
          )
        })
        : null}
    </Router>
  )
}

export default AppRouter
