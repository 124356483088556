import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import Model from '../../../../types/Model'
import Seating from '../../../../types/Seating'
import Fabric from '../../../../types/Fabric'
import { imageCompressConstants } from '../../../../constants/imageCompressConstants'
import { IOptions } from '../../../../types/IOptions'
import { useDispatch, useSelector } from 'react-redux'
import { adjustmentSelector, modelSelector, pendingSelector } from '../../../../redux/modules/customizer.module'
import { changeControls, selectOption } from '../../../../redux/modules/action.creators/customizer.actions'
import {
  ButtonBack,
  ButtonFirst,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider
} from 'pure-react-carousel'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import PopupComponent from "../../../../components/popup.component";
import { isMobile, isTablet } from "react-device-detect";

interface IModelSelect {
  options: IOptions
}

const ModelSelect = ({  options }: IModelSelect) => {
  const dispatch = useDispatch()

  const startSlider = useRef(null)

  const pending = useSelector(pendingSelector)
  const adjustment = useSelector(adjustmentSelector)
  const model = useSelector(modelSelector)

  const [changeSlide, setChangeSlide] = useState<boolean>(false)

  const _visibleSlides = 4

  useEffect(() => {
    if(startSlider.current && !changeSlide ) {
      startSlider.current.click()
    }
    return () => {
      setChangeSlide(false)
    }
  }, [options, startSlider])

  const handleSelect = (option: Seating | Model | Fabric) => {
    option instanceof Seating && dispatch(changeControls({ name: 'reload', value: true }))
    dispatch(selectOption({ type: 'model', option: option }))
    setChangeSlide(true)
  }
  const RenderInfo = React.memo(( fabric: Seating ) => {
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltip__title}>{fabric.title.slice(0, fabric.title.indexOf('_'))}</span>
        <span className={styles.tooltip__serial}>{fabric.serial}</span>
        <div className={styles.tooltip__material}>
          <img className={styles.tooltip__image}
               src={`https:${fabric.thumbnail}${imageCompressConstants.productCardThumb}`} alt=""/>
        </div>
      </div>
    )
  })
  const renderAssets = () => {
    return options.models
      .filter((option: Seating | Model | Fabric) => (option instanceof Model ? !!(option as Model).configurable : true))
      .filter((option: Seating | Model | Fabric) => (option instanceof Seating && option.title === 'Accessories' ? null : option))
      .filter((option: Seating | Model | Fabric) => (option instanceof Seating && option.title === 'All Chairs' ? null : option))
      .map((option: Seating | Model | Fabric, iterator) => option instanceof Seating && option.title !== 'All Chairs' ? (
          <Slide
            key={option.id}
            index={iterator + 1}
          >
            {isTablet || isMobile ? (
              <div
                onClick={() => !pending && handleSelect(option)}
                style={pending ? { pointerEvents: 'none' } : {}}
                className={model && option.id === model.id ? `${styles.slider__item} ${styles.active}`: styles.slider__item}
              >
                <img src={`${option.thumbnail}${imageCompressConstants.bannerImage}`}/>
              </div>
            ) : (
              <PopupComponent key={option.id} contentHTML={<RenderInfo {...option} />}>
                <div
                  onClick={() => !pending && handleSelect(option)}
                  style={pending ? { pointerEvents: 'none' } : {}}
                  className={model && option.id === model.id ? `${styles.slider__item} ${styles.active}`: styles.slider__item}
                >
                  <img src={`${option.thumbnail}${imageCompressConstants.bannerImage}`}/>
                </div>
              </PopupComponent>
            )}
          </Slide>

        ) : null
      )
  }
  const checkVisibleSlides = () => {
    let vs = 4
    if(isTablet) {
      vs = 6
    }
    return options.models.length < vs
      ? options.models.length
      : vs
  }

  return adjustment ? (
    <CarouselProvider
      infinite={true}
      naturalSlideWidth={40}
      naturalSlideHeight={40}
      totalSlides={options.models.length}
      visibleSlides={checkVisibleSlides()}
    >
      <div className={styles.slider}>
        <ButtonFirst className={styles.slider__start}>
          <span ref={startSlider} />
        </ButtonFirst>

        {options.models.length > _visibleSlides ? (
          <ButtonBack className={styles.slider__back}>
            <ChevronLeft />
          </ButtonBack>
        ) : null}

        <Slider className={styles.slider__main}>
          {renderAssets()}
        </Slider>
        {options.models.length > _visibleSlides ? (
          <ButtonNext className={styles.slider__next}>
            <ChevronRight />
          </ButtonNext>
        ) : null}
      </div>

    </CarouselProvider>
  ) : null
}

export default ModelSelect