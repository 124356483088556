import React from 'react';
import {useSelector} from "react-redux";
import {
  fullscreenSelector,
  pendingSelector,
  sequenceSelector,
  sequencesSelector
} from "../../redux/modules/customizer.module";

import Model from "../../types/Model";
import Adjustment from "../../types/Adjustment";
import Fabric from "../../types/Fabric";
import SceneController from "./scene.controller";
import {SceneControl} from "../buttons";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import {isMobile} from "react-device-detect";
import {AssetsLoader} from "../assets.loader";
import styles from './styles.module.scss';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MobileAr } from '../../pages/customizer/customizer.mobileAr';
import { ARIcon } from '../../pages/customizer/arViews/pcArView/arIcon';
import { useQRCode } from 'next-qrcode';


interface IViewerProps {
  model?: Model,
  adjustment?: Adjustment,
  fabric?: Fabric,
  saving: boolean,
  changeControls: Function,
}

const SceneViewer: React.FC<IViewerProps> = ({
                                               model,
                                               adjustment,
                                               fabric,
                                               saving,
                                               changeControls,
                                             }) => {

  const node = React.useRef<null | HTMLDivElement>(null);
  const [controller, setController] = React.useState<null | SceneController>(null);
  const [scale, setScale] = React.useState<number>(0);
  const [visible, setVisible] = React.useState<boolean>(true);
  const [mesh, setMesh] = React.useState<THREE.Object3D>(null);
  const [isQrCodeOpen, setQrCodeOpen] = React.useState(false);
  const [isArAvailible, setArAvailble] = React.useState(true);
  const { Canvas } = useQRCode();

  const fullscreen = useSelector(fullscreenSelector);
  const sequences = useSelector(sequencesSelector);
  const sequence = useSelector(sequenceSelector);
  const pending = useSelector(pendingSelector);
  const _min = 6;
  const _max = 17;

  React.useEffect(() => {
    changeControls({name: 'pending', value: true});

    return () => {
      controller && controller.clearThree();
    }
  }, []);

  React.useEffect(() => {
    if (node.current && !controller) {
      const _controller = new SceneController({
        node: node.current,
        canvas: node.current.querySelector('canvas')!,
        zoomChangedCb,
        controlsChangedCb
      });
      setController(_controller)
    }
  }, [node, controller, setController]);

  

  React.useEffect(() => {
    changeControls({name: 'pending', value: true});

    if (controller && model && adjustment && fabric) {
      const techpack = {
        object: model.object,
        config: adjustment.config,
        materials: adjustment.materials,
        fabric: fabric,
        fabricModification: adjustment.fabricModification
      };
      controller.updateModel(techpack, (mesh: THREE.Object3D) => {
        setMesh(mesh);
        changeControls({name: 'pending', value: false})
        changeControls({name: "reload", value: false})
      })
    }
  }, [controller, adjustment, fabric]);

  React.useEffect(() => {
    if (controller && saving) {
      controller.getScreenshots()
        .then(result => changeControls({name: 'screenshot', value: result}))
        .then(() => changeControls({name: 'saving', value: false}))
        .catch(reject => console.log('rejected', reject));
    }
  }, [controller, saving]);

  React.useEffect(() => {
    if (controller) {
      controller.setZoom(scale);
    }
  }, [scale, controller]);

  const handleClick = (step: number) => {
    setScale((scale) => {
      if (scale < _min) return _min;
      else if (scale > _max) return _max;
      else return scale += step;
    })
  };

  const zoomChangedCb = (_scale: number) => {
    if (scale !== _scale) setScale(_scale)
  };

  const controlsChangedCb = () => {
    setVisible(false)
  }

  const handleResize = () => {
    changeControls({name: 'fullscreen', value: !fullscreen});
    if (sequence!.type === 'preview') {
      const _sequence = sequences!.find(sequence => sequence.type === 'summary');
      changeControls({name: 'sequence', value: _sequence})
    }
  };

  const currentUrl = window.location.href;

  return (
    <section className={styles.wrapper}>
      <div ref={node} className={`${styles.viewer} ${saving ? styles.screenshots : ''}`}>
        <canvas/>
      </div>
      {
        pending && (
          <AssetsLoader/>
        )
      }
      <div className={`${styles.drag} ${visible ? styles.active : styles.not_active}`}>
        <span>Drag to Rotate</span>
      </div>
      {adjustment && adjustment.product_sheet ? (
        <div className={fullscreen ? `${styles.download} ${styles.active}` : styles.download}>
          <a href={`https:${adjustment.product_sheet}`} target="_blank"
             className={styles.download_link}>Download
            Product Sheet <ExpandMoreIcon className={styles.download_icon}/></a>
        </div>
      ) : null}
      <div className={styles.options}>
        <div className={styles.scale_actions}>
          <SceneControl aria-label="zoom-out" disabled={scale <= _min} onClick={() => handleClick(-1)}>
            <RemoveIcon fontSize="inherit"/>
          </SceneControl>
          <SceneControl aria-label="zoom-in" disabled={scale >= _max} onClick={() => handleClick(1)}>
            <AddIcon fontSize="inherit"/>
          </SceneControl>
        </div>
        <div className={styles.scale_actions}>
          <SceneControl aria-label="zoom-out" onClick={() => handleResize()}>
            {
              fullscreen ? (
                <CloseIcon fontSize="inherit"/>
              ) : (
                <FullscreenIcon fontSize="inherit"/>
              )
            }
          </SceneControl>
          {(mesh && !pending && isMobile) &&
              <SceneControl aria-label="ar-view" disabled={!isArAvailible}>
                  <MobileAr mesh={mesh} setArAvailble={setArAvailble}/>
              </SceneControl>}
        </div>
        {!isMobile && 
        <SceneControl aria-label="ar-view" onClick={() => setQrCodeOpen(prev => !prev)}> 
          <ARIcon /> 
        </SceneControl>}
         
        {isQrCodeOpen &&
          <div className={styles.qrCode}>
            <Canvas
              text={currentUrl}
              options={{
                level: 'M',
                margin: 2,
                scale: 4,
                width: 170,
                color: {
                  dark: '#000',
                  light: '#FFF',
                },
              }}
            />
          </div>}
      </div>

    </section>
  )
};

export default SceneViewer;
