import React, { useRef, useState } from 'react'
import styles from './styles.module.scss'
import Adjustment from '../../../types/Adjustment'
import FeaturesList from './components/features.list'
import PriceComponent from "./components/price.component";
import Specifications from './components/specifications'
import { useScrollFixHook } from '../../../hooks/useScrollFix'

interface IRightSidebar {
  adjustment: Adjustment,
  price: number,
  activePreview: boolean
}

const RightSidebar = ({ adjustment, price, activePreview }: IRightSidebar) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useScrollFixHook({ elements: [scrollRef] })

  return adjustment ? (
    <div className={activePreview ? `${styles.sidebar} ${styles.active}` : styles.sidebar}>

      <div ref={scrollRef} className={styles.sidebar__info}>
        {adjustment.specifications ? (
          <div className={styles.sidebar__specifications}>
            <span className={styles.sidebar__title}>Specifications</span>
            <Specifications specifications={adjustment.specifications} />
          </div>
        ) : null}
        {adjustment.features && adjustment.features.length ? (
          <div className={styles.sidebar__features}>
            <span className={styles.sidebar__title}>Features</span>
            <FeaturesList adjustment={adjustment} />
          </div>
        ) : null}
        <div className={styles.sidebar__price}>
          <PriceComponent adjustment={adjustment} price={price} />
        </div>
      </div>

    </div>

  ) : null
}

export default RightSidebar