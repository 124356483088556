import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {RootState} from "../../redux/root.reducer";

import {CustomizerViewer} from "./customizer.viewer";

import {
  loadingSelector,
  sequencesSelector,
  optionsSelector,
  sequenceSelector,
  seatingSelector,
  modelSelector,
  adjustmentSelector,
  fabricSelector,
  savingSelector,
} from "../../redux/modules/customizer.module";

import {
  fetchDesign,
  fetchEntries,
  fetchOptionsSuccess,
  changeControls
} from "../../redux/modules/action.creators/customizer.actions";
import {showDialog} from "../../redux/modules/action.creators/dialog.actions";

const mapStateToProps = (state: RootState) => ({
  loading: loadingSelector(state),
  sequences: sequencesSelector(state),
  sequence: sequenceSelector(state),
  seating: seatingSelector(state),
  options: optionsSelector(state),
  model: modelSelector(state),
  adjustment: adjustmentSelector(state),
  fabric: fabricSelector(state),
  saving: savingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
  bindActionCreators({
    fetchDesign,
    fetchEntries,
    fetchOptionsSuccess,
    showDialog,
    changeControls
  }, dispatch)
);

export type ICustomizerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(CustomizerViewer)