import {Record} from 'immutable';
import {createSelector} from "reselect";
import {cloneDeep} from 'lodash';
import {all, takeEvery} from 'redux-saga/effects';

import {RootState} from "../root.reducer";
import * as actions from './action.constants/customizer.constants';
import {IActionTypes, IStateRecord} from "./module.interfaces/customizer.interface";
import {
    fetchDesignSaga,
    selectOptionSaga,
    selectSequenceSaga,
    changeControlsSaga,
    fetchEntriesSaga
} from "./action.generators/customizer.generators";

import Sequence from "../../types/Sequence";
import Model from "../../types/Model";

export const moduleName = 'customizer';

const StateRecord: IStateRecord = {
    loading: false,
    error: undefined,
    sequences: undefined,
    sequence: undefined,
    seatings: undefined,
    seating: undefined,
    models: undefined,
    model: undefined,
    adjustments: undefined,
    adjustment: undefined,
    options: undefined,
    fabric: undefined,
    screenshot: undefined,
    reload: false,
    saving: false,
    fullscreen: false,
    pending: false
};

export const ReducerRecord: Record.Factory<IStateRecord> = Record(cloneDeep(StateRecord));
type RecordType = ReturnType<typeof ReducerRecord>;

export default (state = new ReducerRecord(), action: IActionTypes) => {
    switch (action.type) {
        case actions.FETCH_DESIGN_REQUEST:
        case actions.FETCH_ENTRIES_REQUEST: {
            return state
                .set('loading', true)
                .set('error', undefined)
        }
        case actions.FETCH_DESIGN_SUCCESS: {
            return state
                .set('loading', !action.status)
                .set('error', undefined)
        }
        case actions.FETCH_SEQUENCES_SUCCESS: {
            const sequences = [...action.sequences].sort((a: Sequence, b: Sequence) => a.id - b.id);
            return state
                .set('sequences', sequences)
                .set('sequence', sequences[0])
        }
        case actions.FETCH_SEATINGS_SUCCESS: {
            return state
                .set('seatings', [...action.seatings])
                .set('seating', action.seatings[0])
        }
        case actions.FETCH_ENTRIES_SUCCESS: {
            if(action.payload.name === 'adjustments') {
                return state
                    .set(action.payload.name, action.payload.value)
                    .set(action.payload.name.slice(0, -1) as keyof IStateRecord, action.payload.value[0])
                    .set('options', {
                        seatings: state.get('seatings'),
                        models: state.get('models'),
                        fabrics: action.payload.value[0].fabrics,
                    })
                    .set('fabric', action.payload.value[0].fabrics[0])

            } else {
                return state
                    .set(action.payload.name, action.payload.name === "models" ? action.payload.value.filter((model: Model) => model && model.configurable) : action.payload.value)
                    .set(action.payload.name.slice(0, -1) as keyof IStateRecord,
                        action.payload.name.slice(0, -1) === 'model'?
                            action.payload.value.filter((model: Model) => model && model.configurable)[0]
                            :
                            action.payload.value[0])
            }
        }
        case actions.FETCH_OPTIONS_SUCCESS: {
            return state
                .set('loading', false)
                .set('error', undefined)
        }
        case actions.SELECT_SEQUENCE_SUCCESS: {
            return state
                .set('sequence', action.sequence)
        }
        case actions.SELECT_OPTION_SUCCESS: {
            return state
                .set(action.payload.type as keyof IStateRecord, action.payload.option)
        }
        case actions.CHANGE_CONTROLS_SUCCESS: {
            return state
                .set(action.payload.name, action.payload.value)
        }
        case actions.FETCH_DESIGN_FAILURE:
        case actions.FETCH_ENTRIES_FAILURE: {
            return state
                .set('loading', false)
                .set('error', action.error)
        }
        default: return state;
    }
}

export const stateSelector = (state: RootState): RecordType => state[moduleName];
export const optionsSelector = createSelector(stateSelector, state => state.get('options'));
export const loadingSelector = createSelector(stateSelector, state => state.get('loading'));
export const sequencesSelector = createSelector(stateSelector, state => state.get('sequences'));
export const sequenceSelector = createSelector(stateSelector, state => state.get('sequence'));
export const seatingsSelector = createSelector(stateSelector, state => state.get('seatings'));
export const seatingSelector = createSelector(stateSelector, state => state.get('seating'));
export const modelSelector = createSelector(stateSelector, state => state.get('model'));
export const adjustmentSelector = createSelector(stateSelector, state => state.get('adjustment'));
export const fabricSelector = createSelector(stateSelector, state => state.get('fabric'));
export const reloadSelector = createSelector(stateSelector, state => state.get('reload'));
export const savingSelector = createSelector(stateSelector, state => state.get('saving'));
export const pendingSelector = createSelector(stateSelector, state => state.get('pending'));
export const fullscreenSelector = createSelector(stateSelector, state => state.get('fullscreen'));

export const saga = function* () {
    yield all([
        takeEvery(actions.FETCH_DESIGN, fetchDesignSaga),
        takeEvery(actions.FETCH_ENTRIES, fetchEntriesSaga),
        takeEvery(actions.SELECT_SEQUENCE, selectSequenceSaga),
        takeEvery(actions.SELECT_OPTION, selectOptionSaga),
        takeEvery(actions.CHANGE_CONTROLS, changeControlsSaga)
    ])
};