import React from "react";
import styles from "./styles.module.scss";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { featuresSelector } from "../../../redux/modules/app.module";
import { FeatureNew } from "../../../types/Adjustment";

interface IFeaturesProps {
  features?: { key: string; description: string }[];
  features_new?: FeatureNew[];
}

const FeaturesList = ({ features, features_new }: IFeaturesProps) => {
  const _features = useSelector(featuresSelector);
  const featuresList = () => {
    if (features_new) {
      console.log(features_new);
      return (
        <>
          {features_new.map((feature, iterator) => {
            return (
              <Grid
                key={iterator}
                item
                md={6}
                sm={6}
                xs={12}
                className={styles.features_content}
              >
                <div className={styles.features_icon}>
                  <img
                    alt=""
                    src={feature.image}
                  />
                </div>
                <article className={styles.features_article}>
                  <Typography variant="caption" className={styles.heading}>
                    {feature.title}
                  </Typography>
                  <Typography variant="caption" className={styles.details}>
                    {feature.description}
                  </Typography>
                </article>
              </Grid>
            );
          })}
        </>
      );
    }
    if (features) {
      return (
        <>
          {features.map((feature) => {
            return (
              <Grid
                key={feature.key}
                item
                md={6}
                sm={6}
                xs={12}
                className={styles.features_content}
              >
                <div className={styles.features_icon}>
                  {feature.key &&
                    _features.find(
                      (el) => el.key.toLowerCase() === feature.key.toLowerCase()
                    )?.icon && (
                      <img
                        alt=""
                        src={`/assets/images/features/${
                          _features.find(
                            (el) =>
                              el.key.toLowerCase() === feature.key.toLowerCase()
                          )?.icon
                        }`}
                      />
                    )}
                </div>
                <article className={styles.features_article}>
                  <Typography variant="caption" className={styles.heading}>
                    {feature.key}
                  </Typography>
                  <Typography variant="caption" className={styles.details}>
                    {feature.description}
                  </Typography>
                </article>
              </Grid>
            );
          })}
        </>
      );
    }
  };

  return (
    <Grid container className={styles.features_wrapper}>
      {featuresList()}
    </Grid>
  );
};

export default FeaturesList;
