import React from "react";
import {Helmet} from 'react-helmet';
import styles from "./styles.module.scss";
import { Grid, Typography } from "@material-ui/core";
import Footer from "../../components/footer.component";

export const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{"Privacy Policy | Eurotech"}</title>
        <meta name="description" content={"Eurotech / Raynor Group Legal Terms & Conditions and Privacy Policy."} />
      </Helmet>
      <Grid container className={styles.wrapper}>
        <Grid item xs={12} md={12} className={styles.policy_container}>
          <Typography className={styles.header_title}>Privacy Policy</Typography>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              EUROTECH / THE RAYNOR GROUP LEGAL TERMS &amp; CONDITIONS AND PRIVACY
              POLICY
            </Typography>
            <Typography className={styles.policy_itm_text} />
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              PERSONAL INFORMATION:
            </Typography>
            <Typography className={styles.policy_itm_text}>
              Thank you for visiting eurotech and the raynor group's website ("the
              Site"). We respect your privacy and strive to protect the
              confidentiality of your personal information. In general, you can
              visit the Site without telling us who you are or revealing any
              information about yourself.
            </Typography>
            <Typography className={styles.policy_itm_text}>
              It is our intent to let you know before we collect personal
              information from you on the Internet. If you choose to give us
              personal information via the Internet so that we, for example, could
              respond to your questions, it is our intent to let you know how we
              will use such information. If you tell us that you do not wish to
              have this information used as a basis for further contact with you,
              we will respect your wishes. We sometimes review domains from which
              people visit us. We analyze this data for trends and statistics and
              then we discard it.
            </Typography>
            <Typography className={styles.policy_itm_text}>
              We never provide personal information to a third-party unless we
              have first received your approval. We will never sell your
              information that you provide on the Site to another company.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>COOKIES</Typography>
            <Typography className={styles.policy_itm_text}>
              Thank you for visiting eurotech and the raynor group's website ("the
              Site"). We respect your privacy and strive to protect the
              confidentiality of your personal information. In general, you can
              visit the Site without telling us who you are or revealing any
              information about yourself.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>LINKS</Typography>
            <Typography className={styles.policy_itm_text}>
              The eurotech/raynor group website may contain links to other web
              sites. We are not responsible for the privacy practices or the
              content of such other web sites. We provide such links for your
              convenience only and are not responsible for the content of any site
              linked to, or from, this Site. Links from this Site to any other
              site do not mean that we approve of, or endorse that site.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              YOUR ACCEPTANCE OF OUR TERMS &amp; CONDITIONS AND PRIVACY POLICY
            </Typography>
            <Typography className={styles.policy_itm_text}>
              The eurotech/raynor group website may contain links to other web
              sites. We are not responsible for the privacy practices or the
              content of such other web sites. We provide such links for your
              convenience only and are not responsible for the content of any site
              linked to, or from, this Site. Links from this Site to any other
              site do not mean that we approve of, or endorse that site.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              CHANGES TO THESE LEGAL TERMS &amp; CONDITIONS AND PRIVACY POLICY OR
              CONTENT
            </Typography>
            <Typography className={styles.policy_itm_text}>
              We may add to, change, or remove any part of these Legal Terms and
              Conditions and Privacy Policy, or content on the Site at any time,
              without notice. Any changes to these Legal Terms and Conditions and
              Privacy Policy posted on this Site apply as soon as they are posted.
              By continuing to use this Site after any changes are posted, you are
              indicating your acceptance of those changes.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              DISCLAIMER OF WARRANTIES, DAMAGES AND LIMITATION OF LIABILITY
            </Typography>
            <Typography className={styles.policy_itm_text}>
              We make no representations or warranties of any kind whatsoever: (1)
              for the accuracy, merchantability, fitness for a particular purpose
              of any product available through the Site; or, (2) that the server
              that makes this Site available is free of viruses or other
              components that may infect, harm or cause damage to your computer
              equipment or any other property when you access, or use the Site.
              Any expected availability due dates, if published on the Site, are
              approximate dates only and are not binding to us.
            </Typography>
            <Typography className={styles.policy_itm_text}>
              We shall not be liable for any consequential, direct, incidental,
              indirect, punitive or special damages related to (a) the use of; (b)
              the inability to use; or, (c) errors or omissions in the contents
              and functions of this Site for any reason including viruses, bugs,
              errors, failures or delays.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              AVAILABILITY OF PRODUCTS AND SERVICES
            </Typography>
            <Typography className={styles.policy_itm_text}>
              The products and services displayed on the Site may not be available
              for purchase in your particular country or locality. You should
              check with your local authorized dealer or representative for the
              availability of specific products and services in your area. We may
              add, change, and discontinue products described or depicted on the
              Site, temporarily or permanently. Any expected availability due
              dates, if published on the Site, are approximate dates only and are
              not binding.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={styles.policy_itm}>
            <Typography className={styles.policy_itm_title}>
              QUESTIONS OR COMMENTS
            </Typography>
            <Typography className={styles.policy_itm_text}>
              If you have any question or comments about our privacy practices,
              you can contact us at{" "}
              <a
                href="mailto:customerservice@raynorgroup.com"
                className={styles.link}
              >
                customerservice@raynorgroup.com
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
