import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {ContactArticle} from "../../components/contact.article.component";
import Footer from "../../components/footer.component";
import styles from './styles.module.scss';

export const GsaAdvantage = () => {
    return (
        <Grid container className={styles.wrapper}>
            <Grid item xs={12} sm={12} md={12} className={styles.title_wrapper}>
                <img src={"/assets/images/GSA/GSA.png"} alt={''} className={styles.logo}/>
            </Grid>


            <Grid container className={styles.contract_container}>
                <Grid item md={12} className={styles.title_container}>
                    <Typography className={styles.title}>
                        Terms and Conditions
                    </Typography>
                    <Typography className={styles.sub_title}>CONTRACT & ORDERING INFORMATION</Typography>

                    <Grid className={styles.contact}>
                        <Typography className={styles.label}>GSA CONTRACT:</Typography>
                        <Typography className={styles.label}>GS-27F-010CA</Typography>
                    </Grid>

                    <Grid className={styles.contact}>
                        <Typography className={styles.label}>CONTRACT PERIOD:</Typography>
                        <Typography className={styles.label}>20 February 2020 thru 19 February 2025</Typography>
                    </Grid>

                    <Grid className={styles.contact}>
                        <Typography className={styles.label}>CLASSIFIED:</Typography>
                        <Typography className={styles.label}>SMALL BUSINESS</Typography>
                    </Grid>

                </Grid>

                <Grid item sm={12} md={6} className={styles.column_list}>
                    <Typography className={styles.list}>
                        1A. SPECIAL ITEM NUMBER: <ins className={styles.dark}>SIN 33721...Office Furniture</ins> <br/>
                        2. MAXIMUM ORDER LIMITATION: <ins className={styles.dark}>$250,000, SIN 33721</ins><br/>
                        3. MINIMUM ORDER: <ins className={styles.dark}> $100.00</ins><br/>
                        4. GEOGRAPHIC COVERAGE: <ins className={styles.dark}>48 CONUS and The District of
                        Columbia</ins><br/>
                        5. POINT OF PRODUCTION: <ins className={styles.dark}>West Hempstead, NY 11552</ins><br/>
                        6. DISCOUNT: <ins className={styles.dark}>61.7% off List</ins><br/>
                        7. SINGLE ORDER QUANTITY DISCOUNT: <ins className={styles.dark}>$197,371 List to $542,740.00
                        List62.9% off List</ins><br/>
                        8. PROMPT PAYMENT TERMS: <ins className={styles.dark}>NET 30 DAYS</ins><br/>
                        9A. <ins className={styles.dark}>GOVERNMENT PURCHASE CARDS ARE ACCEPTED AT OR BELOW THE
                        MICRO-PURCHASE THRESHOLD</ins><br/>
                        9B. <ins className={styles.dark}>GOVERNMENT PURCHASE CARDS ARE ACCEPTED ABOVE THE MICRO-PURCHASE
                        THRESHOLD</ins><br/>
                        10. FOREIGN TERMS: <ins className={styles.dark}>NOT AVAILABLE</ins><br/>
                        11A. TIME OF DELIVERY: <ins className={styles.dark}>15 DAYS A.R.O.</ins><br/>
                        11B. EXPEDITIED DELIVERY: <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                        11C. OVERNIGHT AND 2 DAY DELIVERY: <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                        11D. URGENT REQUIREMENTS: <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                        12. FOB POINT: <ins className={styles.dark}>ORIGIN; FREIGHT PREPAID AND ALLOWED</ins><br/>
                        13. ORDERING ADDRESS:<br/>

                        <ins className={styles.description}>
                            THE RAYNOR GROUP DBA EUROTECH C/O<br/>
                            SERVICING DEALER (DEALER NAME)<br/>
                            525 HEMPSTEAD TURNPIKE<br/>
                            WEST HEMPSTEAD NY 11552<br/>


                            <ins className={styles.atth}>
                                Attn.: GSA Customer Service<br/>
                            </ins>

                            <ins className={styles.contact}>
                                PHONE: 800-637-0005<br/>
                                FAX: 516-481-8141<br/>
                                EMAIL:<br/>
                            </ins>
                        </ins>
                    </Typography>
                </Grid>

                <Grid item sm={12} md={6} className={styles.column_list_rt}>
                    <Typography className={styles.list}>
                        13B. ORDERING PROCEDURES: <ins className={styles.dark}>FOR SUPPLIES AND SERVICES, THE
                        ORDERING PROCEDURES, INFORMATION ON BLANKET<br/>
                        PURCHASE AGREEMENTS (BPA’S) ARE FOUND IN FEDERAL<br/>
                        ACQUISTION REGUATION (FAR) 8.405-3</ins><br/>
                        14. PAYMENT ADDRESS: <ins className={styles.dark}>SAME AS ABOVE</ins><br/>
                        15. WARRANTY: Eurotech warrants to the originalpurchaser all<br/>
                        components for the life of the productwith the exception of<br/>
                        Upholstery and foam, which will be warranted for Five (5) years.<br/>
                        16. EXPORT PACKING CHARGES: <ins className={styles.dark}>N/A</ins><br/>
                        17. TERMS OF GOVERNMENT COMMERCIAL CREDIT CARD<br/>
                        ACCEPTANCE: <ins className={styles.dark}>N/A</ins><br/>
                        18. RENTAL MAINTENANCE, AND REPAIRS TERMS: <ins className={styles.dark}>N/A</ins><br/>
                        19. INSTALLATION TERMS: <ins className={styles.dark}>CONTACT FACTORY OR PARTICIPATING<br/>
                        DEALER</ins><br/>
                        20. REPAIR PARTS, ETC.: <ins className={styles.dark}>SEE WARRANTY</ins><br/>
                        21. LIST OF SERVICE AND DISTRIBUTION POINTS:<br/>
                        <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                        22. LIST OF PARTICIPATING DEALERS: <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                        23. PREVENTIVE MAINTENANCE: <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                        24. ENVIROMENTAL ATTRIBUTES: <ins className={styles.dark}>IN COMPLIANCE</ins><br/>
                        24a. Special attributes such as environmental attributes (e.g., recycled <br/>
                        content, energy efficiency and/or reduced Pollutants).:<br/>
                        <ins className={styles.dark}>CONTACT FACTORY</ins><br/>
                    </Typography>
                </Grid>
            </Grid>


            <Grid container className={styles.information_container}>
                <Grid item xs={12} sm={12} md={12} className={styles.title_container}>
                    <Typography className={styles.title}>GSA Information</Typography>
                </Grid>

                <Grid item sm={12} md={6} className={styles.column_container}>
                    <Grid className={styles.column_wrapper}>
                        <Grid item xs={5} sm={4} md={4} lg={3} className={styles.column_info}>
                            <Typography className={styles.item}>SAM NETWORK:</Typography>
                            <Typography className={styles.item}>TAX ID NO. (TIN):</Typography>
                            <Typography className={styles.item}>CAGE CODE:</Typography>
                            <Typography className={styles.item}>DUNS NO.:</Typography>
                            <Typography className={styles.item}>NAICS NO.: </Typography>
                        </Grid>

                        <Grid item xs={7} sm={8} md={8} lg={9} className={styles.column_info}>
                            <Typography className={styles.item}>ACTIVE REGISTRATION</Typography>
                            <Typography className={styles.item}>11-2510441</Typography>
                            <Typography className={styles.item}>74AE2</Typography>
                            <Typography className={styles.item}>037051737</Typography>
                            <Typography className={styles.item}>337214</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={styles.text_line}>
                        <Typography className={styles.text}>
                            Re-stocking: Returned merchandise will not be accepted unless authorized in writing
                            prior to return. Please contact The Raynor Group Customer Service to obtain Return
                            Merchandise Authorization (RMA). Government Customer will ship freight prepaid in
                            originalcarton, and if applicable, will be subject to a restocking charge of 20%.
                            Customer will be responsible for any damage and the cost of re-boxing if required prior
                            to any credits being issued.
                        </Typography>
                    </Grid>

                </Grid>

                <Grid item sm={12} md={6} className={styles.column_info_container}>
                    <Grid item xs={12} sm={12} md={12} className={styles.column_info_wrapper}>
                        <Grid item xs={5} sm={4} md={4} lg={4} className={styles.column_info}>
                            <Typography className={styles.item}>GSA CONTRACT:</Typography>
                            <Typography className={styles.item}>CONTRACT PERIOD:</Typography>
                            <Typography className={styles.item}>2020CLASSIFIED: </Typography>
                        </Grid>
                        <Grid item xs={7} sm={8} md={8} lg={8} className={styles.column_info}>
                            <Typography className={styles.item}>GS-27F-010CA</Typography>
                            <Typography className={styles.item}>20 February2015 thru 19 February </Typography>
                            <Typography className={styles.item}>SMALL BUSINESS</Typography>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} md={12} className={styles.column_info_wrapper}>
                        <Grid item xs={5} sm={4} md={4} lg={4} className={styles.column_info}>
                            <Typography className={styles.item}>SAM Network:</Typography>
                            <Typography className={styles.item}>Tax ID No. (TIN):</Typography>
                            <Typography className={styles.item}>Cage Code:</Typography>
                            <Typography className={styles.item}>DUNS No.:</Typography>
                            <Typography className={styles.item}>NAICS No.:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={8} md={8} lg={8} className={styles.column_info}>
                            <Typography className={styles.item}>Active Registration</Typography>
                            <Typography className={styles.item}>11-2510441</Typography>
                            <Typography className={styles.item}>74AE2</Typography>
                            <Typography className={styles.item}>037051737</Typography>
                            <Typography className={styles.item}>337214</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <ContactArticle/>
                <Footer/>
            </Grid>
        </Grid>
    )
}