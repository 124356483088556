import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CarouselArrowControl } from "../buttons";

import Slider, { Settings } from "react-slick";

import styles from "./styles.module.scss";
import "./active.css";
import { formatterPrice } from "../../utils/app.helpers/price.formatter";

const Slide: React.FC<any> = (props: any) => {
  return (
    <div className={styles.slide} style={{ ...props.style }}>
      <div className={styles.logo_container}>
        {props.isDiscounted && (
          <div className={styles.logo_container_sale}>
            - {props.discountValue} %
          </div>
        )}
        {props.isFabrix && (
          <div className={styles.logo_container__fabrix}>
            <img src={"/assets/images/fabrix_icon/fabrix_icon.png"} alt="" />
          </div>
        )}
        {props.gsa && (
          <div className={styles.logo_container__gsa}>
            <img src={"/assets/images/gsa_icon/gsa_logo.png"} alt="" />
          </div>
        )}
      </div>
      <div className={styles.slide_preview}>
        <img
          className={styles.slide_preview_thumbnail}
          src={props.thumbnail}
          alt={props.title}
        />
      </div>
      <div className={styles.slide_description}>
        <Typography
          className={styles.slide_description_serial}
          variant="caption"
        >
          {props.label}
        </Typography>
        <Typography
          className={styles.slide_description_title}
          variant="caption"
        >
          {props.title.includes("-")
            ? props.title.split("-").join("/")
            : props.title}
        </Typography>
        {props.isDiscounted ? (
          <span className={styles.sale_container}>
            <b className={styles.card_price_old}>
              {formatterPrice(props.price)}
            </b>
            <b className={styles.card_price}>
              {formatterPrice(
                props.price * ((100 - props.discountValue) / 100)
              )}
            </b>
          </span>
        ) : (
          <b className={styles.card_price}>{formatterPrice(props.price)}</b>
        )}
      </div>
    </div>
  );
};

function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <div className={styles.next_arrow}>
      <CarouselArrowControl className={styles.arrow} onClick={onClick}>
        <ArrowForwardIosIcon color={"error"} fontSize="small" />
      </CarouselArrowControl>
    </div>
  );
}

function PrevArrow(props: any) {
  const { onClick } = props;

  return (
    <div className={styles.prev_arrow}>
      <CarouselArrowControl className={styles.arrow} onClick={onClick}>
        <ArrowBackIosIcon
          color={"error"}
          fontSize="small"
          style={{ marginLeft: "5px" }}
        />
      </CarouselArrowControl>
    </div>
  );
}

interface IContent {
  id: number | string;
  title: string;
  category: string;
  label: string;
  thumbnail: string;
  price?: number;
}

interface ICarouselProps {
  title?: string;
  contents: IContent[];
  settings: Settings;
  emphasize?: string;
}

const Carousel: React.FC<ICarouselProps> = ({
  title,
  contents,
  settings,
  emphasize,
}) => {
  const _settings: Settings = {
    ...settings,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: styles.slider_container,
    dotsClass: styles.slider_dots,
  };

  if (emphasize) {
    console.log(title, emphasize)
  }

  return (
    <section className={styles.wrapper}>
      {(emphasize && title) ? (
        <Typography variant="h4" className={styles.title}>
          {title.split(emphasize)[0]}{" "}<b className={styles.emphasize}>{emphasize}</b>{" "}{title.split(emphasize)[1]}
        </Typography>
      ) : (
        <Typography variant="h4" className={styles.title}>
          {title}
        </Typography>
      )}
      <Slider {..._settings}>
        {contents &&
          contents.map((el: IContent) => {
            return (
              <Link
                key={el.id}
                to={`/${el.category.toLocaleLowerCase()}/${el.title
                  .toLowerCase()
                  .split(" ")
                  .join("_")}`}
                className={styles.link}
              >
                <Slide {...el} />
              </Link>
            );
          })}
      </Slider>
    </section>
  );
};

export default Carousel;
