import Contentful from "../utils/app.helpers/contentful";
import Fabric from "./Fabric";

export default class FabricSection {
  id: string;
  title: string;
  fabrics: Fabric[];
  constructor(data: any = {}) {
    console.log(data)
    this.id = data.sys.id;
    this.title = data.fields.title;
    this.fabrics = data.fields.fabrics;
  }
}
