import React, {useEffect} from "react";
import styles from "./styles.module.scss";
import Model from "../../../../types/Model";
import {imageCompressConstants} from "../../../../constants/imageCompressConstants";
import {Link} from "react-router-dom";
//@ts-ignore
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Contentful from "../../../../utils/app.helpers/contentful";

interface Props {
  compareArray: Model[];
  setCompareArray: Function;
  setModalOpen: Function;
  category?: string;
}

export default function ProdcutCompare({
                                         compareArray,
                                         setCompareArray,
                                         setModalOpen,
                                         category = ''
                                       }: Props) {
  const specs = [
    "overall_dimensions",
    "back",
    "seat",
    "seat_height",
    "weight",
    "cube",
  ];
  const features = [
    "Tilt Tension Control",
    "Back Angle Adjustment",
    "Seat Height Adjustment",
    "Back Height Adjustment",
    "Arm Height Adjustment",
    "Arm Depth Adjustment",
    "Tilt Lock",
    "Synchro-Tilt",
    "Waterfall Seat",
    "Seat Depth Adjustment",
    "Swivel Arm",
  ];
  const tableRef = React.useRef(null);
  const [openPanels, setOpenPanels] = React.useState({
    spec: true,
    features: true,
  });
  
  const downloadReport = () => {
    if (tableRef.current) {
      html2canvas(tableRef.current, {allowTaint: true, useCORS: true}).then(
        function (canvas) {
          let downloadLink = document.createElement("a");
          downloadLink.setAttribute("download", "Report.png");
          let dataURL = canvas.toDataURL("image/png");
          let url = dataURL.replace(
            /^data:image\/png/,
            "data:application/octet-stream"
          );
          downloadLink.setAttribute("href", url);
          downloadLink.click();
        }
      );
    }
  };
  
  // console.log(compareArray)
  useEffect(() => {
    compareArray.forEach(async (el) => {
      // const _instance = Contentful.getInstance();она       `
      // const response: any = await _instance.client.getEntry( el.adjustment[0].fields.featuresNew[0].sys.id);
      // const response2: any = await _instance.client.getEntry( el.adjustment[0].fields.featuresNew[1].sys.id);
      // const response3: any = await _instance.client.getEntry( el.adjustment[0].fields.featuresNew[2].sys.id);
      // const response4: any = await _instance.client.getEntry( el.adjustment[0].fields.featuresNew[3].sys.id);
      // console.log('@res',el.adjustment[0].fields.featuresNew)
    });
  }, [compareArray]);
  
  const isModelIncludeFeature = (model: Model, feature: string) => {
    console.log(model.adjustment)
    if (model.adjustment[0].fields.features?.details) {
      return model.adjustment[0].fields.features?.details?.some(
        (detail: any) => detail.key === feature
      );
    }
    
    
    if (
      model.adjustment[0].fields?.featuresNew?.some(
        (fNew: any) => fNew.fields && fNew.fields.name === feature
      )
    ) {
      return true;
    }
    
    return false;
  };
  
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <div className={styles.modal_header}>
          <span className={styles.modal_h1}>Comparing</span>
          <span className={styles.modal_h2}>
            {`(${compareArray.length} ${
              compareArray.length === 1 ? "item" : "items"
            })`}
          </span>
          <svg
            className={styles.modal_close}
            onClick={() => setModalOpen(false)}
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 8.5L8 24.5"
              stroke="black"
              stroke-width="4"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
            <path
              d="M8 8.5L24 24.5"
              stroke="black"
              stroke-width="4"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={styles.tableWrapper}>
          <table className={styles.table} ref={tableRef}>
            <tr className={styles.table_card}>
              <td className={styles.table_emptyTd}/>
              {compareArray.map((model) => (
                <td>
                  <div className={styles.productCard}>
                    <img
                      className={styles.productCard_image}
                      src={
                        model.angle_thumbnail
                          ? `https:${model.angle_thumbnail}${imageCompressConstants.productCardThumb}`
                          : ""
                      }
                      alt="model"
                      crossOrigin="anonymous"
                    />
                    <Link
                      className={styles.productCard_header}
                      key={model.id}
                      onClick={() => document.documentElement.style.overflow = "auto"}
                      to={
                        category ?
                          `/${category.toLowerCase()}/${model.title
                            .split(" ")
                            .join("_")
                            .toLowerCase()}`
                          :
                          `/${model.category.toLowerCase()}/${model.title
                            .split(" ")
                            .join("_")
                            .toLowerCase()}`
                      }
                    >
                      View product
                    </Link>
                    <span
                      className={styles.productCard_delete}
                      onClick={() => {
                        setCompareArray((prev: Model[]) =>
                          prev.filter((selected) => selected.id !== model.id)
                        );
                      }}
                    >
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="square"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span className={styles.productCard_price}>
                      $ {model.price}
                    </span>
                    <span className={styles.productCard_name}>
                      {model.title}
                    </span>
                  </div>
                </td>
              ))}
            </tr>
            <tr className={styles.table_specs}>
              <td>Specifications</td>
              {compareArray.map((e, index) => {
                if (index === compareArray.length - 1) {
                  return (
                    <td>
                      <div>
                        <svg
                          onClick={() =>
                            setOpenPanels((prev) => ({
                              ...prev,
                              spec: !prev.spec,
                            }))
                          }
                          className={!openPanels.spec ? styles.rotated : ""}
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 15.5L12 9.5L6 15.5"
                            stroke="#D13532"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </td>
                  );
                }
                return <td/>;
              })}
            </tr>
            {openPanels.spec && (
              <>
                {specs.map((spec: any) => (
                  <tr className={styles.table_basic}>
                    <td style={{textTransform: "capitalize"}}>
                      {spec.replace("_", " ")}
                    </td>
                    {compareArray.map((model) => (
                      <td>
                        <div className={styles.table_spec}>
                          {model.adjustment[0].fields.specifications[spec]}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
            <tr className={styles.table_specs}>
              <td>Features</td>
              {compareArray.map((e, index) => {
                if (index === compareArray.length - 1) {
                  return (
                    <td>
                      <div>
                        <svg
                          onClick={() =>
                            setOpenPanels((prev) => ({
                              ...prev,
                              features: !prev.features,
                            }))
                          }
                          className={!openPanels.features ? styles.rotated : ""}
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 15.5L12 9.5L6 15.5"
                            stroke="#D13532"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </td>
                  );
                }
                return <td/>;
              })}
            </tr>
            {openPanels.features && (
              <>
                {features.map((feature) => (
                  <tr className={styles.table_basic}>
                    <td>{feature}</td>
                    {compareArray.map((model) => (
                      <td>
                        <div className={styles.table_icon}>
                          {isModelIncludeFeature(model, feature) ? (
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.75 2.75C7.37391 2.75 3 7.12391 3 12.5C3 17.8761 7.37391 22.25 12.75 22.25C18.1261 22.25 22.5 17.8761 22.5 12.5C22.5 7.12391 18.1261 2.75 12.75 2.75ZM17.8242 9.23234L11.5242 16.7323C11.4551 16.8147 11.3691 16.8812 11.2721 16.9273C11.175 16.9735 11.0692 16.9983 10.9617 17H10.9491C10.8439 17 10.74 16.9778 10.644 16.935C10.548 16.8922 10.462 16.8297 10.3917 16.7516L7.69172 13.7516C7.62315 13.6788 7.56981 13.5931 7.53483 13.4995C7.49986 13.4059 7.48395 13.3062 7.48805 13.2063C7.49215 13.1064 7.51617 13.0084 7.5587 12.9179C7.60124 12.8275 7.66142 12.7464 7.73572 12.6796C7.81002 12.6127 7.89694 12.5614 7.99136 12.5286C8.08579 12.4958 8.18581 12.4822 8.28556 12.4886C8.38531 12.495 8.48277 12.5213 8.57222 12.5659C8.66166 12.6106 8.74128 12.6726 8.80641 12.7484L10.9294 15.1072L16.6758 8.26766C16.8047 8.11863 16.987 8.02631 17.1835 8.01066C17.3799 7.99501 17.5746 8.05728 17.7254 8.18402C17.8763 8.31075 17.9712 8.49176 17.9897 8.68793C18.0082 8.8841 17.9488 9.07966 17.8242 9.23234Z"
                                fill="#08B778"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.75 2.75C7.37391 2.75 3 7.12391 3 12.5C3 17.8761 7.37391 22.25 12.75 22.25C18.1261 22.25 22.5 17.8761 22.5 12.5C22.5 7.12391 18.1261 2.75 12.75 2.75ZM16.2802 14.9698C16.3527 15.0388 16.4108 15.1216 16.4508 15.2133C16.4909 15.305 16.5122 15.4039 16.5135 15.504C16.5148 15.6041 16.496 15.7034 16.4583 15.7961C16.4206 15.8889 16.3647 15.9731 16.2939 16.0439C16.2231 16.1147 16.1389 16.1706 16.0461 16.2083C15.9534 16.246 15.8541 16.2648 15.754 16.2635C15.6539 16.2622 15.555 16.2409 15.4633 16.2008C15.3716 16.1608 15.2888 16.1027 15.2198 16.0302L12.75 13.5608L10.2802 16.0302C10.1384 16.1649 9.94955 16.2389 9.75398 16.2364C9.5584 16.2339 9.37155 16.1551 9.23325 16.0168C9.09495 15.8785 9.01614 15.6916 9.01364 15.496C9.01114 15.3005 9.08513 15.1116 9.21984 14.9698L11.6892 12.5L9.21984 10.0302C9.08513 9.88836 9.01114 9.69955 9.01364 9.50398C9.01614 9.3084 9.09495 9.12155 9.23325 8.98325C9.37155 8.84495 9.5584 8.76614 9.75398 8.76364C9.94955 8.76114 10.1384 8.83513 10.2802 8.96984L12.75 11.4392L15.2198 8.96984C15.3616 8.83513 15.5505 8.76114 15.746 8.76364C15.9416 8.76614 16.1285 8.84495 16.2668 8.98325C16.4051 9.12155 16.4839 9.3084 16.4864 9.50398C16.4889 9.69955 16.4149 9.88836 16.2802 10.0302L13.8108 12.5L16.2802 14.9698Z"
                                fill="#D13532"
                              />
                            </svg>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </table>
          <div
            className={styles.downloadReport}
            onClick={() => downloadReport()}
          >
            Download Report
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5002 12.5V15.8333C17.5002 16.2754 17.5 17 17.5 17.5C17 17.5 16.2756 17.5 15.8336 17.5H4.16691C3.72488 17.5 3 17.5 2.5 17.5C2.5 17 2.50024 16.2754 2.50024 15.8333V12.5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M5.83301 8.33337L9.99967 12.5L14.1663 8.33337"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M9.25 12.5C9.25 12.9142 9.58579 13.25 10 13.25C10.4142 13.25 10.75 12.9142 10.75 12.5H9.25ZM10.75 2.5V1.75H9.25V2.5H10.75ZM10.75 12.5V2.5H9.25V12.5H10.75Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
