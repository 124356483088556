import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import ObjectCache from "./scene.cache";
import {fitCameraToObject, wait} from "../../utils/three.helpers";

export interface IEngineProps {
  node: HTMLDivElement,
  canvas: HTMLCanvasElement,
  zoomChangedCb: Function,
  controlsChangedCb: () => void,
}

export default class SceneEngine {
  protected props: IEngineProps;
  protected renderer!: THREE.WebGLRenderer;
  protected uuid!: string;
  scene: THREE.Scene = new THREE.Scene();

  protected camera!: THREE.PerspectiveCamera;
  protected controls!: OrbitControls;
  protected fl_light!: THREE.DirectionalLight;
  protected fr_light!: THREE.DirectionalLight;
  protected bl_light!: THREE.DirectionalLight;
  protected br_light!: THREE.DirectionalLight;
  protected ambient!: THREE.AmbientLight;
  protected FBXLoader = new FBXLoader();
  protected ObjectCache = new ObjectCache();
  protected positions = {
    front: [0, 0, 0]
  };
  protected distance: number = 0;
  protected polar?: number;
  protected azimuth?: number;
  protected controller: THREE.Group;

  constructor(props: IEngineProps) {
    this.props = props;

    this.uuid = THREE.MathUtils.generateUUID();
    this.initRenderer();
    this.initCamera();
    this.initControls();
    this.initLight();
    this.updateRendererSize();
  }

  protected initRenderer() {
    const {canvas, node} = this.props;

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
      canvas,
    });

    node.appendChild(this.renderer.domElement);

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(node.offsetWidth, node.offsetHeight);
    this.renderer.setClearColor('#fafafa');
  }

  protected initLight() {
    this.fl_light = new THREE.DirectionalLight(0xbfbfbf, .6);
    this.fr_light = new THREE.DirectionalLight(0xbfbfbf, .6);
    this.bl_light = new THREE.DirectionalLight(0xbfbfbf, .6);
    this.br_light = new THREE.DirectionalLight(0xbfbfbf, .6);
    this.fl_light.position.set(-7.5, 10, 7.5);
    this.fr_light.position.set(7.5, 10, 7.5);
    this.bl_light.position.set(-7.5, 10, -7.5);
    this.br_light.position.set(7.5, 10, -7.5);
    this.ambient = new THREE.AmbientLight(0xffffff, 0.1);

    this.scene.add(this.fl_light);
    this.scene.add(this.fr_light);
    this.scene.add(this.bl_light);
    this.scene.add(this.br_light);
    this.scene.add(this.ambient);
  }

  protected initControls() {
    this.controls = new OrbitControls(this.camera, this.props.node);

    this.controls.enablePan = false;
    this.controls.enableKeys = false;
    this.controls.enableZoom = true;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minPolarAngle = Math.PI / 3;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1;
    this.controls.autoRotate = false;
    this.controls.autoRotateSpeed = 0.2;
    this.controls.minDistance = 250;
    this.controls.maxDistance = 675;
  }

  protected initCamera() {
    const {node} = this.props;

    this.camera = new THREE.PerspectiveCamera(45, node.offsetWidth / node.offsetHeight, .1, 1000);
    this.camera.aspect = node.offsetWidth / node.offsetHeight;
    this.camera.position.set(500, 200, 0);
    this.camera.updateProjectionMatrix();

    this.scene.add(this.camera);
  }

  protected updateRendererSize = () => {
    const {node, zoomChangedCb, controlsChangedCb} = this.props;
    this.renderer.setSize(node.offsetWidth, node.offsetHeight);
    this.camera.aspect = node.offsetWidth / node.offsetHeight;
    this.camera.updateProjectionMatrix();
    if (this.distance !== Math.ceil(this.camera.position.distanceTo(this.controls.target))) {
      this.distance = Math.ceil(this.camera.position.distanceTo(this.controls.target));

      zoomChangedCb(Math.round(10 / (this.distance / (this.controls.maxDistance - this.controls.minDistance))));
    }
    if ((this.polar && this.polar !== this.controls.getPolarAngle()) || (this.azimuth && this.azimuth !== this.controls.getAzimuthalAngle())) {
      controlsChangedCb()
    }
  };

  public loadModel(url: string): Promise<THREE.Object3D> {
    return new Promise(async (resolve, reject) => {
      const _load = (object: THREE.Object3D) => {
        this.ObjectCache.addObject(url, object);
        object.userData.url = url;
        resolve(object);
      };
      this.FBXLoader.load(url, _load, undefined, reject);
    })
  }

  public addModel(target: THREE.Object3D, type: string) {
    this.scene.add(target);
    target.userData.type = type;
  }

  public clearThree() {
    this.renderer && this.renderer.forceContextLoss();
  }

  public clearScene() {
    const target = this.scene.children.find(child => {
      return child.userData.type;
    }) as any;

    if (target) {
      this.destroy(target)
      this.scene.remove(target)
    }
  }

  protected destroy(object: any) {
    while (object.children.length > 0) {
      this.destroy(object.children[0]);
      object.remove(object.children[0]);
    }
    if (object.geometry) object.geometry.dispose();
    if (object.material) {
      Object.keys(object.material).forEach(_key => {
        if (!object.material[_key]) return;
        if (object.material[_key] !== null && typeof object.material[_key].dispose === "function") object.material[_key].dispose()
      });
      object.material.dispose();
    }
  }

  public fitCamera = (selection: THREE.Object3D[]) => {
    if (!selection.length) return;

    fitCameraToObject({
      fitRatio: .9,
      camera: this.camera,
      controls: this.controls,
      selection,
    });
  };

  takeScreenShot = async () => {
    let screenshot = {};
    for await(const key of Object.keys(this.positions)) {
      //@ts-ignore
      const position = this.positions[key];

      this.scene.children.forEach((model) => model.rotation.set(...position as [number, number, number]));
      await wait(500);
      this.fitCamera(this.scene.children.filter(i => i.userData.type));
      const image = this.renderer.domElement.toDataURL();
      return Object.assign(screenshot, {[key]: image})
    }
    return screenshot;
  };

  updateZoom = (scale: number) => {
    const _factor = ((10 / scale * (this.controls.maxDistance - this.controls.minDistance)) / this.distance);

    this.camera.position.x *= _factor;
    this.camera.position.y *= _factor;
    this.camera.position.z *= _factor;
  };

  update = () => {
    // if( !this.renderer ) return false;
    const {scene, camera } = this;

    this.updateRendererSize();
    this.controls.update();

    this.polar = this.controls.getPolarAngle();
    this.azimuth = this.controls.getAzimuthalAngle();

    this.renderer.render(scene, camera);
  }
}
