import * as actions from "../action.constants/dialog.constants";
import {ReactNode} from "react";

export const showDialog = (content: ReactNode, settings?: any): {type: typeof actions.SHOW_DIALOG, content: ReactNode, settings?: any} => ({
    type: actions.SHOW_DIALOG,
    content,
    settings
});

export const showDialogSuccess = (content: ReactNode, settings?: any): {type: typeof actions.SHOW_DIALOG_SUCCESS, content: ReactNode, settings?: any} => ({
    type: actions.SHOW_DIALOG_SUCCESS,
    content,
    settings
});