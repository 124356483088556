import React from "react";
import styles from "./styles.module.scss";
import DoneIcon from "@material-ui/icons/Done";
import Fabric from "../../../types/Fabric";
import PopupComponent from "../../../components/popup.component";
import { imageCompressConstants } from "../../../constants/imageCompressConstants";
import { useSelector } from "react-redux";
import { adjustmentSelector } from "../../../redux/modules/app.module";
import FabricSection from "../../../types/FabricSection";

interface IColorsModel {
  checkActiveColor?: (id: string) => boolean;
  clickHandler?: (fabric: Fabric) => void;
  renderInfo?: (fabric: Fabric) => JSX.Element;
  certainSection?: FabricSection;
}

const StandardColor = ({
  checkActiveColor,
  clickHandler,
  renderInfo,
  certainSection,
}: IColorsModel) => {
  const adjustment = useSelector(adjustmentSelector);

  console.log(certainSection)

  return (
    <div className={`${styles.colors} ${styles.express}`}>
      {certainSection ? (
        <>
          {certainSection.fabrics
            .map((fabric: any) => {
              return (
                <PopupComponent
                  key={fabric.id}
                  contentHTML={renderInfo(fabric)}
                >
                  <div
                    className={styles.colors__item}
                    onClick={() => clickHandler(fabric)}
                  >
                    {checkActiveColor(fabric.id) ? (
                      <DoneIcon className={styles.colors__active_color} />
                    ) : null}
                    <img
                      className={styles.colors__img}
                      src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`}
                      alt=""
                    />
                  </div>
                </PopupComponent>
              );
            })}
        </>
      ) : (
        <>
          {adjustment &&
            adjustment.fabrics
              .filter((fabric: any) => fabric.default)
              .map((fabric: any) => {
                return (
                  <PopupComponent
                    key={fabric.id}
                    contentHTML={renderInfo(fabric)}
                  >
                    <div
                      className={styles.colors__item}
                      onClick={() => clickHandler(fabric)}
                    >
                      {checkActiveColor(fabric.id) ? (
                        <DoneIcon className={styles.colors__active_color} />
                      ) : null}
                      <img
                        className={styles.colors__img}
                        src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`}
                        alt=""
                      />
                    </div>
                  </PopupComponent>
                );
              })}
        </>
      )}
    </div>
  );
};

export default React.memo(StandardColor);
