import React from 'react'
import { Link } from 'react-router-dom'
import Routes from '../../../../app.router/router.constants'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { LinkButton } from '../../../../components/buttons'

import styles from './styles.module.scss'

export const ProductCatalog = () => {
  return (
    <section>
      <h1 className={styles.header_title}>Browse our current <b>Catalogue</b></h1>
      <div className={styles.wrapper}>

        <Grid item className={`${styles.content} ${styles.executive}`}>
          <Typography variant="caption" className={styles.title}>Executive</Typography>
          <Typography variant="h6" className={styles.description}>Comfort, Meet Style</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.ExecutivePage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.ExecutivePage} className={styles.content_link}/>
        </Grid>
        <Grid item className={`${styles.content} ${styles.guest}`}>
          <Typography variant="caption" className={styles.title}>Guest</Typography>
          <Typography variant="h6" className={styles.description}>Make a Great<br/>First Impression</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.GuestPage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.GuestPage} className={styles.content_link}/>
        </Grid>
        <Grid item className={`${styles.content} ${styles.task}`}>
          <Typography variant="caption" className={styles.title}>Task</Typography>
          <Typography variant="h6" className={styles.description}>The Unsung Hero</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.TaskPage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.TaskPage} className={styles.content_link}/>
        </Grid>
        <Grid item className={`${styles.content} ${styles.stack}`}>
          <Typography variant="caption" className={styles.title}>Stack</Typography>
          <Typography variant="h6" className={styles.description}>Fast Set Up<br/>and Storage</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.StackPage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.StackPage} className={styles.content_link}/>
        </Grid>
        <Grid item className={`${styles.content} ${styles.stools}`}>
          <Typography variant="caption" className={styles.title}>Stools</Typography>
          <Typography variant="h6" className={styles.description}>Work Hard.<br/>Work Comfortably.</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.StoolsPage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.StoolsPage} className={styles.content_link}/>
        </Grid>
        <Grid item className={`${styles.content} ${styles.education}`}>
          <Typography variant="caption" className={styles.title}>School</Typography>
          <Typography variant="h6" className={styles.description}>Quality and<br/>Affordability</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.SchoolPage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.EducationPage} className={styles.content_link}/>
        </Grid>
        <Grid item className={`${styles.content} ${styles.conference}`}>
          <Typography variant="caption" className={styles.title}>Conference</Typography>
          <Typography variant="h6" className={styles.description}>Professional.<br/>Affordable.</Typography>
          <LinkButton classes={{ root: styles.link_wrapper }}>
            <Link to={Routes.ConferencePage} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
          <Link to={Routes.ConferencePage} className={styles.content_link}/>
        </Grid>
      </div>
    </section>
  )
}
