import axios from "axios";
import { all, call, put } from "redux-saga/effects";
import {
  changeControls,
  changeControlsSuccess,
  fetchDistributorsFailure,
  fetchDistributorsRequest,
  fetchDistributorsSuccess,
  sendMailFailure,
  searchSuccess,
  sendMail,
  gsaSuccess,
  fetchToolsRequest,
  fetchToolsSuccess,
  fetchToolsFailure,
  fetchContractsRequest,
  fetchContractsFailure,
  fetchContractsSuccess,
  fetchFabrixCatalogRequest,
  fetchFabrixCatalogSuccess,
  fetchFabrixCatalogFailure,
  initPartnerListRequest,
  initPartnerListSuccess,
  initPartnerListFailure,
  initPartnerList,
  initBannerImages,
  initBannerImagesRequest,
  initBannerImagesSuccess,
  initBannerImagesFailure,
} from "../action.creators/app.actions";
import {
  fetchAssets,
  fetchAssetsRequest,
  fetchAssetsSuccess,
  fetchAssetsFailure,
} from "../action.creators/app.actions";

import Contentful from "../../../utils/app.helpers/contentful";
import Model from "../../../types/Model";
import Region from "../../../types/Region";
import Distributor from "../../../types/Distributor";

import { SERVER_API } from "../../api.constants";
import Tools from "../../../types/ITools";
import FabrixCatalog from "../../../types/FabrixCatalog";
import Partner from "../../../types/Partner";
import { BannerImage } from "../../../types/BannerImage";
import Adjustment from "../../../types/Adjustment";

const _instance = Contentful.getInstance();
const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const checkIsPublish = (data: any[], constructor: any) => {
  const published: any = [];

  data &&
    data.forEach(async (el: any) => {
      const entry = el.hasOwnProperty("fields");

      if (entry) {
        published.push(new constructor(el));
      }
    });
  return published;
};

export const changeControlsSaga = function* ({
  payload,
}: ReturnType<typeof changeControls>) {
  yield put(changeControlsSuccess({ ...payload }));

  if (
    payload.name === "search" ||
    payload.name === "gsa" ||
    payload.name === "allChair"
  ) {
    switch (payload.value) {
      case "GSA": {
        try {
          //@ts-ignore
          const response = yield call(async () => {
            return await _instance.client.getEntries({
              "fields.gsa": true,
              content_type: "model",
            });
          });
          const models = response.items
            .map((el: any) => new Model(el))
            .filter((item: Model) => {
              if (item.adjustment[0].hasOwnProperty("fields")) return item;
              else return null;
            });
          yield put(gsaSuccess(models));
        } catch (e) {
          console.error("e", e);
        }
        break;
      }
      case "allChair": {
        try {
          //@ts-ignore
          const response = yield call(async () => {
            return await _instance.client.getEntries({
              content_type: "model",
            });
          });
          let result = response.items
            .filter((el: any) => el)
            .filter((el: any) => el.fields.category !== "Accessories")
            .map((el: any) => new Model(el))
            .filter((item: Model) => {
              if (item.adjustment[0].hasOwnProperty("fields")) return item;
              else return null;
            });

          yield put(fetchAssetsSuccess({ name: "models", value: result }));
        } catch (e) {
          console.error("e", e);
          yield put(fetchAssetsFailure(e.message));
        }
        break;
      }
      default: {
        if (payload.value) {
          try {
            //@ts-ignore
            const response_title = yield call(async () => {
              return await _instance.client.getEntries({
                "fields.title[match]": payload.value,
                content_type: "model",
              });
            });
            //@ts-ignore
            const response_sku = yield call(async () => {
              return await _instance.client.getEntries({
                "fields.sku[match]": payload.value,
                content_type: "model",
              });
            });
            //@ts-ignore
            const response_keyWords = yield call(async () => {
              return await _instance.client.getEntries({
                "fields.key_words[match]": payload.value,
                content_type: "model",
              });
            });
            const response = {
              title: response_title.items.map((el: any) => new Model(el)),
              sku: response_sku.items.map((el: any) => new Model(el)),
              keyWords: response_keyWords.items.map((el: any) => new Model(el)),
            };

            const uniqueModels: Model[] = [];
            response.title
              .concat(response.sku)
              .concat(response.keyWords)
              .forEach((el: Model) => {
                if (!uniqueModels.find((ui) => ui.id === el.id)) {
                  uniqueModels.push(el);
                }
              });

            yield put(searchSuccess(uniqueModels));
          } catch (e) {
            console.error("e", e);
          }
        } else {
          yield put(changeControlsSuccess({ ...payload }));
        }
      }
    }
  }
};

export const fetchAssetsSaga = function* (
  payload: ReturnType<typeof fetchAssets>
) {
  try {
    yield put(fetchAssetsRequest());
    const sd = payload.uuid.join(",");
    //@ts-ignore
    const response = yield call(async () => {
      const entries = await _instance.client
        .getEntries({
          "sys.id[in]": sd,
          limit: 300,
        })
        .then((response) => response.items);

      // const result = await Promise.all(entries.map((entrie: any) => {
      //     console.log(entrie)
      //     return Promise.all(entrie.fields.entries[0].fields?.featuresNew?.map(async (element: any) => {
      //         await _instance.client.getEntries({
      //             'sys.id': element.sys.id
      //         })
      //     }))
      // }))

      // console.log('@RESSS',result)
      // return entries;

      const result = await Promise.all(
        entries
          .map((el: any) => new payload.constructor.class(el))
          .filter(async (item: any) => {
            if (item instanceof Adjustment) {
                return item;
            } else {
              const fsNew = Array.isArray(item.adjustment[0].fields.featuresNew)
                ? item.adjustment[0].fields.featuresNew
                : null;

              if (fsNew) {
                item.adjustment[0].fields.featuresNew = await Promise.all(
                  fsNew?.map(async (element: any) => {
                    return _instance.client.getEntry(element.sys.id);
                  })
                );
              }

              if (item instanceof Model) {
                if (item.adjustment[0].hasOwnProperty("fields")) return item;
                else return null;
              }
              return item;
            }
          })
      );

      console.log("@result", result);
      return result;
    });

    yield put(fetchAssetsSuccess({ name: payload.key, value: response }));
  } catch (error) {
    console.error("ERROR", error);
    //@ts-ignore
    yield put(fetchAssetsFailure(error.message));
  }
};

export const fetchDistributorsSaga = function* () {
  try {
    yield put(fetchDistributorsRequest());
    //@ts-ignore
    const response: any = yield call(async () => {
      return await _instance.client
        .getEntries({
          content_type: "region",
        })
        .catch((err) => console.log(err));
    });

    const _regions: Region[] =
      response.items &&
      response.items
        .filter((el: Region) => el.hasOwnProperty("fields"))
        .map((el: Region) => new Region(el));

    yield put(fetchDistributorsSuccess(_regions));
  } catch (error) {
    console.error("ERROR", error);
    //@ts-ignore
    yield put(fetchDistributorsFailure(error.message));
  }
};

export const sendMailSaga = function* (payload: ReturnType<typeof sendMail>) {
  try {
    yield call(
      async () =>
        await axios.post(`${SERVER_API}/contact`, {
          name: payload.publication.name,
          email: payload.publication.email,
          subject: payload.publication.subject,
          message: payload.publication.message,
        })
    );
  } catch (error) {
    console.log("ERROR", error);
    yield put(sendMailFailure(error.message));
  }
};

export const fetchToolsSaga = function* () {
  try {
    yield put(fetchToolsRequest());

    const response: Tools = yield call(async () => {
      return await _instance.client
        .getEntry("6369Jya5GjRhq3Ps3DMl0Q")
        .catch((err) => console.log(err));
    });

    const tools: Tools = new Tools(response);

    yield put(fetchToolsSuccess(tools));
  } catch (error) {
    console.error("ERROR", error);
    yield put(fetchToolsFailure(error.message));
  }
};

export const fetchContractsSaga = function* () {
  try {
    yield put(fetchContractsRequest());

    const response: Tools = yield call(async () => {
      const entry = await _instance.client
        .getEntry("2qdnGUQbgM1ZTmf9vKOQfg")
        .catch((err) => console.log(err));
      return entry;
    });

    const tools: Tools = new Tools(response);

    yield put(fetchContractsSuccess(tools));
  } catch (error) {
    console.error("ERROR", error);
    yield put(fetchContractsFailure(error.message));
  }
};

export const fetchFabrixCatalogSaga = function* () {
  try {
    yield put(fetchFabrixCatalogRequest());

    const sortFc = (a: any, b: any) => {
      if (a.fields.title > b.fields.title) return 1;
      if (a.fields.title < b.fields.title) return -1;
      return 0;
    };
    //@ts-ignore
    const response: any = yield call(async () => {
      return await _instance.client
        .getEntries({
          "sys.id[match]": "3jnRzSUDgiGmFyk8FnbITh",
        })
        .catch((err) => console.log(err));
    });

    const result = {
      fields: {
        title: response.items[0].fields.title,
        subtitle: response.items[0].fields.subtitle,
        fabrix_categories: response.includes.Entry.sort(sortFc),
      },
    };

    yield all(
      response.includes.Entry.map(async (category: any) => {
        const fabrixLinks = category.fields.fabrix.map((el: any) => el.sys.id);

        const fabrics = await _instance.client.getEntries({
          "sys.id[in]": fabrixLinks.join(","),
          limit: 200,
        });
        category.fields.fabrix = fabrics.items.sort(sortFc);
      })
    );

    const catalog = new FabrixCatalog(result);

    yield put(fetchFabrixCatalogSuccess(catalog));
  } catch (error) {
    console.error("ERROR", error);
    yield put(fetchFabrixCatalogFailure(error.message));
  }
};

export const initPartnerListSaga = function* (
  payload: ReturnType<typeof initPartnerList>
) {
  try {
    yield put(initPartnerListRequest());
    
    const payloadWithProducts = payload.data.filter((el: any) => {
      if (!el.hasOwnProperty("fields")) {
        return;
      }
      if (!!el.fields.products) {
        return {
          id: el.sys.id,
          products: el.fields.products,
        };
      }
    });

    const payloadWithoutProducts = payload.data.filter((el: any) => {
      if (!el.hasOwnProperty("fields")) {
        return;
      }
      if (!el.fields.products) {
        return {
          id: el.sys.id,
          products: el.fields.products,
        };
      }
    });
    
    //@ts-ignore
    const payloadWithModels = yield all(
      payloadWithProducts.map(async (el: any) => {
        const models = await Promise.all(
          el.fields.products.map(async (prod: any) => {
            try {
              const entry = await _instance.client
                .getEntry(prod.sys.id)
                .catch((err) => console.log(err));
              
              if (entry) {
                return new Model(entry);
              }
            } catch (e) {
              console.error('FETCH:PARTNER:PRODUCTS:', e);
            }
          })
        );

        return {
          ...el,
          fields: {
            ...el.fields,
            products: models.filter(i => i),
          },
        };
      })
    );

    const payloadAll = [...payloadWithModels, ...payloadWithoutProducts];
    
    const result = payloadAll.map((el: any) => new Partner(el));

    yield put(initPartnerListSuccess(result));
  } catch (error) {
    console.error("ERROR:PARTNERS:FETCH", error);
    yield put(initPartnerListFailure(error.message));
  }
};

export const initBannerImagesSaga = function* (
  payload: ReturnType<typeof initBannerImages>
) {
  try {
    yield put(initBannerImagesRequest());
    const result = payload.data.map((el: any) => new BannerImage(el));
    yield put(initBannerImagesSuccess(result));
  } catch (error) {
    console.error("ERROR", error);
    yield put(initBannerImagesFailure(error.message));
  }
};
