import React, { useRef, useState } from "react";
import styles from './styles.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { changeControls, fetchFabrixCatalog } from "../../redux/modules/action.creators/app.actions";
import { fabrixCatalogSelector, loadingCatalogSelector } from "../../redux/modules/app.module";
import { ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography } from "@material-ui/core";
import { Fabrix, FabrixCategory } from "../../types/FabrixCatalog";
import { AssetsLoader } from "../../components/assets.loader";
import { SidebarCategories } from "./sidebar";
import { LinkButton } from "../../components/buttons";
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import { imageCompressConstants } from '../../constants/imageCompressConstants'

export const FabrixCatalog = () => {

    const dispatch = useDispatch();
    const catalog = useSelector(fabrixCatalogSelector);
    const isCatalogLoading = useSelector(loadingCatalogSelector);
    const [loading, setLoading] = React.useState(true);
    const [firstrender, setFirstrender] = React.useState(false);
    const [categoriesSet, setCategoriesSet] = React.useState([]);

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);


    React.useLayoutEffect(() => {
        if (!catalog && !isCatalogLoading) {
            dispatch(fetchFabrixCatalog());
        }
    }, [isCatalogLoading]);

    React.useEffect(() => {
        if (catalog) {
            setLoading(false);
            setTimeout(() => setFirstrender(true), 350);
            setCategoriesSet(catalog.farbixCategories.map((category: FabrixCategory) => {
                return category.title
            }));
        }
    }, [catalog]);

    const handleClickFabrix = (select: Fabrix, list: Fabrix[]) => {
        dispatch(changeControls({
            name: 'selectFabrixList', value: {
                select: select,
                list: list
            }
        }));
        dispatch(changeControls({ name: 'showFabrixViewer', value: true }));
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container className={styles.wrapper}>
            {
                loading ? (
                    <section className={styles.preloader}>
                        <AssetsLoader />
                    </section>
                ) : (
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={styles.header_container}>
                            <Typography className={styles.title}>{catalog && catalog.title}</Typography>
                            <Typography className={styles.sub_title}>{catalog && catalog.subTitle}</Typography>
                        </Grid>

                        <Grid item md={3} className={styles.sidebar_wrapper}>
                            <SidebarCategories categories={categoriesSet} />
                        </Grid>

                        <Grid item sm={12} xs={12} className={styles.mobile_categories}>
                            <LinkButton
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                classes={open ? { root: styles.link_wrapper_open } : { root: styles.link_wrapper }}
                            >
                                <div className={styles.link}>
                                    <Typography className={styles.link_heading} variant="caption">
                                        Quick Navigation
                                    </Typography>
                                    <ListRoundedIcon fontSize="large" className={styles.link_icon} />
                                </div>
                            </LinkButton>

                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                                style={{
                                    position: 'sticky',
                                    zIndex: 2000,
                                    top: 0,
                                    left: 0,
                                    transform: 'translate3d(97px, 50px, 0px)',
                                }}
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center top' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="menu-list-grow"
                                                    onKeyDown={handleListKeyDown}
                                                    className={styles.list_wrapper}
                                                >
                                                    <MenuItem className={styles.list_item}
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <SidebarCategories categories={categoriesSet} />
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>

                        <Grid item md={9} className={styles.fabrix_catalog}>
                            {catalog && catalog.farbixCategories.map((categories: FabrixCategory, index) => {
                                const needRender = firstrender || index < 4

                                if (!needRender) return null

                                return (
                                    <Grid item xs={12} sm={12} md={12} key={categories.title} id={categories.title}
                                        className={styles.categories_container}>
                                        <Typography className={styles.categories_title}>{categories.title}</Typography>
                                        <Grid item xs={12} sm={12} md={12} className={styles.fabrix_wrapper}>
                                            {
                                                categories && categories.fabrixList.map((fabrix: Fabrix) => {
                                                    return (
                                                        <Grid item xs={12} sm={4} md={3} xl={2}
                                                            className={styles.fabrix_container}
                                                            key={fabrix.title}
                                                            onClick={() => handleClickFabrix(fabrix, categories.fabrixList)}
                                                        >
                                                            <img src={`${fabrix.preview}${imageCompressConstants.fabrixCatalogThumb}`} alt=""
                                                                className={styles.preview} />
                                                            <Typography
                                                                className={styles.fabrix_title}>{fabrix.title}</Typography>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}
