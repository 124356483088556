import React, {useEffect, useState} from 'react'
import styles from './styles.module.scss'
import DropdownComponent from '../../../components/dropdown.component/dropdown.component'
import {formatterPrice} from '../../../utils/app.helpers/price.formatter'
import Model from "../../../types/Model";

interface IAccessoriesProps {
    model: any,
    selectedAccessories : any,
    setSelectedAccessories: any
}

const Accessories = ({model ,selectedAccessories, setSelectedAccessories}: IAccessoriesProps) => {
    const [accessoriesList, setAccessoriesList] = useState([])

    const getAccessoriesEntry = () => {
        setAccessoriesList([])
        if (model) {
            model.accessories && model.accessories.forEach((el: Model) => {
                setAccessoriesList((prev) => [...prev, el])
            })
        }
    }

    const selectAccessories = (item: any) => {
        const key = item.sku

        if (selectedAccessories && selectedAccessories.hasOwnProperty(key)) {
            const res = {...selectedAccessories}

            delete res[key]
            setSelectedAccessories(res)

            return
        }
        setSelectedAccessories((prev : Model) => ({...prev, [key]: item}))
    }

    const isSelectedItem = (item: any) => selectedAccessories && selectedAccessories.hasOwnProperty(item.sku)

    useEffect(() => {
        getAccessoriesEntry()
    }, [model])

    return (
        <DropdownComponent
            styles={styles.button}
            closeAfterChoice={false}
            contentHTML={
                <span className={styles.button__title}>Add+</span>
            }>
            {accessoriesList && accessoriesList.map((el) => {
                return (
                    <div key={el.sku}
                         className={`${styles.dropdown} ${isSelectedItem(el) ? styles.dropdown__active : ''}`}
                         onClick={() => selectAccessories(el)}>
                        <span className={styles.dropdown__title}>{el.title}</span>
                        <span className={styles.dropdown__price}>({formatterPrice(el.price)})</span>
                    </div>
                )
            })}
        </DropdownComponent>

    )
}

export default Accessories