export default class Tools {
    categories?: ToolsCategories[];

    constructor(data: any = {}) {
        this.categories = data.fields.tools_categories ? data.fields.tools_categories.filter((el:any) => el.hasOwnProperty('fields')).map((el: any) => new ToolsCategories(el)) : undefined;
    }
};

export class ToolsCategories {
    name: string;
    contentType?: string;
    content: CategoriesContent[];

    constructor(data: any = {}) {
        this.name = data.fields.categories_name;
        this.contentType = data.fields.content_type ? data.fields.content_type : undefined;
        this.content = data.fields.content ? data.fields.content.filter((el:any) => el.hasOwnProperty('fields')).map((el: any) => new CategoriesContent(el)) : undefined;
    }
}

export class CategoriesContent {
    title: string;
    entries: string;

    constructor(data: any = {}) {
        this.title = data.fields.title;
        this.entries = data.fields.file ? data.fields.file.url : undefined;
    }
}



