import React from 'react';
import {Provider} from "react-redux";
import store from "./redux";

import styles from './app.module.scss';
import DialogContainer from "./components/dialog.component";
import AppRouter from "./app.router";

const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    e.ctrlKey
    && ![`ArrowLeft`, `ArrowRight`].includes(e.key)
    && e.preventDefault()
}

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <main className={styles.main}
                  contentEditable={false}
                  suppressContentEditableWarning
                  onKeyDown={onKeyDown}>
                <AppRouter/>
                <DialogContainer/>
            </main>
        </Provider>
    )
};

export default App;
