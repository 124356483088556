import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';
import { all, takeEvery } from 'redux-saga/effects';

import { RootState } from '../root.reducer';
import * as actions from './action.constants/app.constants';
import { IActionTypes, IStateRecord } from './module.interfaces/app.interface';

import { categories, features, panels, sliders } from './presset.constants';
import {
  changeControlsSaga,
  fetchAssetsSaga, fetchContractsSaga,
  fetchDistributorsSaga, fetchFabrixCatalogSaga, fetchToolsSaga, initBannerImagesSaga, initPartnerListSaga,
  sendMailSaga,
} from './action.generators/app.generators';

export const moduleName = 'app';

const StateRecord: IStateRecord = {
  loading: false,
  error: undefined,
  panels: [...panels],
  sliders: [...sliders],
  categories: [...categories],
  features: [...features],
  drawer_state: false,
  filter: 'All',
  sort: 'All',
  search: undefined,
  searching: false,
  matches: undefined,
  gsa: undefined,
  gsaRes: undefined,
  models: undefined,
  model: undefined,
  adjustments: undefined,
  adjustment: undefined,
  screenshot: '',
  regions: undefined,
  region: undefined,
  tools: undefined,
  contracts: undefined,
  selectVideo: undefined,
  showVideo: false,
  fabrixCatalog: undefined,
  showFabrixViewer: false,
  selectFabrixList: undefined,
  loadingCatalog: false,
  partnerList: undefined,
  selectedPartner: undefined,
  activeGrade: undefined,
  bannerImagesList: undefined,
  activeColor: null,
};

export const ReducerRecord: Record.Factory<IStateRecord> = Record(cloneDeep(StateRecord));
type RecordType = ReturnType<typeof ReducerRecord>;

export default (state = new ReducerRecord(), action: IActionTypes) => {
  switch (action.type) {
    case actions.INIT_PARTNER_LIST_SUCCESS: {
      return state
        .set('partnerList', action.payload);
    }
    case actions.INIT_PARTNER_LIST_FAILURE: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case actions.INIT_BANNER_IMAGES_SUCCESS: {
      return state
        .set('bannerImagesList', action.payload);
    }
    case actions.INIT_BANNER_IMAGES_FAILURE: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case actions.FETCH_ASSETS_REQUEST: {
      return state
        .set('error', undefined);
    }
    case actions.FETCH_ASSETS_SUCCESS: {
      if (action.payload.name === 'adjustments') {
        if (action.payload.value.length) {
          return state
            .set(action.payload.name.slice(0, -1) as keyof IStateRecord, action.payload.value[0])
            .set('screenshot', action.payload.value[0].screenshots[0])
            .set('loading', false)
            .set('error', undefined);
        }
        return state;
      } else {
        return state
          .set(action.payload.name, action.payload.value)
          .set('loading', false)
          .set('error', undefined);
      }
    }
    case actions.FETCH_ASSETS_FAILURE: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case actions.SEARCH_REQUEST: {
      return state
        .set('searching', true)
        .set('matches', undefined);
    }
    case actions.SEARCH_SUCCESS: {
      return state
        .set('searching', false)
        .set('matches', action.matches);
    }
    case actions.GSA_SUCCESS: {
      return state
        .set('gsaRes', action.response);
    }
    case actions.CHANGE_CONTROLS_SUCCESS: {
      return state
        .set(action.payload.name, action.payload.value);
    }
    case actions.FETCH_DISTRIBUTORS_REQUEST: {
      return state
        .set('loading', true);
    }
    case actions.FETCH_DISTRIBUTORS_SUCCESS: {
      return state
        .set('regions', action.regions)
        .set('region', action.regions[0])
        .set('loading', false);
    }
    case actions.FETCH_DISTRIBUTORS_FAILURE: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case actions.SEND_MAIL_FAILURE: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case actions.FETCH_TOOLS_REQUEST: {
      return state;
    }
    case actions.FETCH_TOOLS_SUCCESS: {
      return state
        .set('tools', action.tools);
    }
    case actions.FETCH_TOOLS_FAILURE: {
      return state
        .set('error', action.error);
    }
    case actions.FETCH_CONTRACTS_REQUEST: {
      return state;
    }
    case actions.FETCH_CONTRACTS_SUCCESS: {
      return state
        .set('contracts', action.tools);
    }
    case actions.FETCH_CONTRACTS_FAILURE: {
      return state
        .set('error', action.error);
    }
    case actions.FETCH_FABRIX_CATALOG_REQUEST: {

            return state
                .set('loadingCatalog', true)
        }
        case actions.FETCH_FABRIX_CATALOG_SUCCESS: {
            return state
                .set('fabrixCatalog', action.fabrixCatalog)
                .set('loadingCatalog', false)
        }
        case actions.FETCH_FABRIX_CATALOG_FAILURE: {
            return state
                .set('error', action.error)
                .set('loadingCatalog', false)
        }
        case actions.SET_ACTIVE_GRADE: {
            return state
              .set('activeGrade', action.payload)
        }
        default:
            return state;
    }
}

export const stateSelector = (state: RootState): RecordType => state[moduleName];
export const loadingSelector = createSelector(stateSelector, state => state.get('loading'));
export const errorSelector = createSelector(stateSelector, state => state.get('error'));
export const drawerStateSelector = createSelector(stateSelector, state => state.get('drawer_state'));
export const panelsSelector = createSelector(stateSelector, state => state.get('panels'));
export const slidersSelector = createSelector(stateSelector, state => state.get('sliders'));
export const featuresSelector = createSelector(stateSelector, state => state.get('features'));
export const filterSelector = createSelector(stateSelector, state => state.get('filter'));
export const sortSelector = createSelector(stateSelector, state => state.get('sort'));
export const searchSelector = createSelector(stateSelector, state => state.get('search'));
export const searchingSelector = createSelector(stateSelector, state => state.get('searching'));
export const matchesSelector = createSelector(stateSelector, state => state.get('matches'));
export const gsaResSelector = createSelector(stateSelector, state => state.get('gsaRes'));
export const modelsSelector = createSelector(stateSelector, state => state.get('models'));
export const modelSelector = createSelector(stateSelector, state => state.get('model'));
export const adjustmentSelector = createSelector(stateSelector, state => state.get('adjustment'));
export const regionsSelector = createSelector(stateSelector, state => state.get('regions'));
export const regionSelector = createSelector(stateSelector, state => state.get('region'));
export const screenshotSelector = createSelector(stateSelector, state => state.get('screenshot'));
export const toolsSelector = createSelector(stateSelector, state => state.get('tools'));
export const contractsSelector = createSelector(stateSelector, state => state.get('contracts'));
export const videoSelector = createSelector(stateSelector, state => state.get('selectVideo'));
export const showVideoSelector = createSelector(stateSelector, state => state.get('showVideo'));
export const fabrixCatalogSelector = createSelector(stateSelector, state => state.get('fabrixCatalog'));
export const loadingCatalogSelector = createSelector(stateSelector, state => state.get('loadingCatalog'));
export const showFabrixViewerSelector = createSelector(stateSelector, state => state.get('showFabrixViewer'));
export const selectFabrixListSelector = createSelector(stateSelector, state => state.get('selectFabrixList'));

export const partnerListSelector = createSelector(stateSelector, state => state.get('partnerList'));
export const bannerImagesListSelector = createSelector(stateSelector, state => state.get('bannerImagesList'));
export const activeGradeSelector = createSelector(stateSelector, state => state.get("activeGrade"));
export const activeColorSelector = createSelector(stateSelector, state => state.get("activeColor"));

export const saga = function* () {
  yield all([
    takeEvery(actions.CHANGE_CONTROLS, changeControlsSaga),
    takeEvery(actions.FETCH_ASSETS, fetchAssetsSaga),
    takeEvery(actions.FETCH_DISTRIBUTORS, fetchDistributorsSaga),
    takeEvery(actions.SEND_MAIL, sendMailSaga),
    takeEvery(actions.FETCH_TOOLS, fetchToolsSaga),
    takeEvery(actions.FETCH_TOOLS, fetchContractsSaga),
    takeEvery(actions.FETCH_FABRIX_CATALOG, fetchFabrixCatalogSaga),
    takeEvery(actions.INIT_PARTNER_LIST, initPartnerListSaga),
    takeEvery(actions.INIT_BANNER_IMAGES, initBannerImagesSaga),
  ]);
};
