import React from 'react';
import { exportUSDZ } from './exportUSDZ';
import styles from '../../styles.module.scss';

type Props = {
  mesh: THREE.Object3D
}

export const IosArView: React.FC<Props> = ({ mesh }) => {
  const [link, setLink] = React.useState('');
  
  React.useEffect(() => {
    exportUSDZ(mesh).then(res => setLink(res));
  }, []);

  return (
    <>
      {link ? 
      <a href={link} rel="ar" download="asset.usdz">
        <img src='assets/images/arIcon.svg' alt='arIcon'className={styles.iosARButton}/>
      </a>
      : ''}
    </>
  )
}