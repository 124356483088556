export const FETCH_DESIGN = '[customizer] FETCH DESIGN';
export const FETCH_DESIGN_REQUEST = '[customizer] FETCH DESIGN REQUEST';
export const FETCH_DESIGN_SUCCESS = '[customizer] FETCH DESIGN SUCCESS';
export const FETCH_DESIGN_FAILURE = '[customizer] FETCH DESIGN FAILURE';

export const FETCH_ENTRIES = '[customizer] FETCH ENTRIES';
export const FETCH_ENTRIES_REQUEST = '[customizer] FETCH ENTRIES REQUEST';
export const FETCH_ENTRIES_SUCCESS = '[customizer] FETCH ENTRIES SUCCESS';
export const FETCH_ENTRIES_FAILURE = '[customizer] FETCH ENTRIES FAILURE';

export const FETCH_OPTIONS_SUCCESS = '[customizer] FETCH OPTIONS SUCCESS';

export const FETCH_SEQUENCES_SUCCESS = '[customizer] FETCH SEQUENCES SUCCESS';
export const SELECT_SEQUENCE_SUCCESS = '[customizer] SELECT SEQUENCE SUCCESS';

export const FETCH_SEATINGS_SUCCESS = '[customizer] FETCH SEATINGS SUCCESS';
export const SELECT_SEQUENCE = '[customizer] SELECT SEQUENCE';

export const SELECT_OPTION = '[customizer] SELECT OPTION';
export const SELECT_OPTION_SUCCESS = '[customizer] SELECT OPTION SUCCESS';

export const CHANGE_CONTROLS = '[customizer] CHANGE_CONTROLS';
export const CHANGE_CONTROLS_SUCCESS = '[customizer] CHANGE_CONTROLS_SUCCESS';