import React from 'react';
import {connect} from "react-redux";
import {RootState} from "../../redux/root.reducer";
import {contentSelector, settingsSelector} from "../../redux/modules/dialog.module";
import Dialog from "@material-ui/core/Dialog";

import styles from './styles.module.scss';

const DialogContainer: React.FC<DialogProps> = ({content, settings}) => {
  return (
    !!content ? <Dialog
      open={!!content}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="max-width-dialog"
      children={content}
      PaperProps={settings && settings.transparent ? {
        className: styles.transparent
      } : {}}
    /> : null
  )
};

const mapStateToProps = (state: RootState) => ({
  content: contentSelector(state),
  settings: settingsSelector(state)
});

type DialogProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, {})(DialogContainer);