import React from "react";
import { Grid } from "@material-ui/core";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import Model from "../../../../types/Model";
import { imageCompressConstants } from "../../../../constants/imageCompressConstants";
import { formatterPrice } from "../../../../utils/app.helpers/price.formatter";
import classNames from "classnames";

interface IProductCard {
  model: Model;
  link?: string;
  isDiscounted?: boolean;
  isCompareMode?: boolean;
  isSelected?: boolean;
  setCompareArray?: Function;
  compareArray?: Model[];
  compareModalOpen?: boolean;
  category?: string;
}

const ProductCard = ({
  model,
  link,
  isDiscounted,
  isCompareMode,
  isSelected,
  setCompareArray,
  compareArray,
  compareModalOpen,
  category = '',
}: IProductCard) => {
  return (
    <Grid
      key={model.id}
      className={classNames(
        styles.card,
        compareArray && compareArray.length !== 0 ? styles.activeMode : ""
      )}
    >
      <div
        className={classNames(
          styles.selector,
          !isCompareMode ? styles.selector_disabled : "",
          isSelected ? styles.selector_active : ""
        )}
      >
        <div className={styles.selector_dot} />
      </div>
      {(isCompareMode && !compareModalOpen) && (
        <div
          className={styles.overlay}
          onClick={(e) => {
            e.stopPropagation();
            setCompareArray((prev: Model[]) => {
              if (prev.some((selected) => selected.id === model.id)) {
                return prev.filter((selected) => selected.id !== model.id);
              } else {
                return [...prev, model];
              }
            });
          }}
        />
      )}
      <Link
        className={styles.content}
        key={model.id}
        to={
          link && !isDiscounted
            ? link
            : `/${model.category.toLowerCase()}/${model.title
                .split(" ")
                .join("_")
                .toLowerCase()}`
        }
      >
        <div className={styles.front_side}>
          <img
            className={styles.preview}
            src={
              model.angle_thumbnail
                ? `https:${model.angle_thumbnail}${imageCompressConstants.productCardThumb}`
                : ""
            }
            alt=""
          />
        </div>

        <div className={styles.back_side}>
          <img
            className={styles.preview}
            src={
              model.thumbnail
                ? `https:${model.thumbnail}${imageCompressConstants.productCardThumb}`
                : ""
            }
            alt=""
          />
        </div>

        <p
          className={
            model.title && model.title.includes("iOO")
              ? styles.title_ioo
              : styles.title
          }
        >
          <span>{category ? category : model.category}</span>
          {model.title.includes("-")
            ? model.title.split("-").join("/")
            : model.title}
          {model.isDiscounted ? (
            <span className={styles.sale_container}>
              <b className={styles.card_price_old}>
                {formatterPrice(model.price)}
              </b>
              <b className={styles.card_price}>
                {formatterPrice(
                  model.price * ((100 - model.discountValue) / 100)
                )}
              </b>
            </span>
          ) : (
            <b className={styles.card_price}>{formatterPrice(model.price)}</b>
          )}
        </p>
      </Link>

      <div className={styles.logo_container}>
        {model.isDiscounted && (
          <div className={styles.logo_container_sale}>
            - {model.discountValue} %
          </div>
        )}
        {model.isFabrix && (
          <div className={styles.logo_container__fabrix}>
            <img src={"/assets/images/fabrix_icon/fabrix_logo.png"} alt="" />
          </div>
        )}
        {model.gsa && (
          <div className={styles.logo_container__gsa}>
            <img src={"/assets/images/gsa_icon/gsa_logo.png"} alt="" />
          </div>
        )}
      </div>
    </Grid>
  );
};

export default ProductCard;
