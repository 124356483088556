import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { drawerStateSelector } from "../../redux/modules/app.module";

import { Settings } from "react-slick";
import { IHomepageProps } from "./homepage.container";

import Carousel from "../../components/carousel.component";

import { HBannerComponent } from "./static.content/banner.component";
import { WelcomeArticle } from "./static.content/welcome.article";
import { ProductCatalog } from "./static.content/product.catalog";
import { CreateArticle } from "./static.content/create.article";
import { LearnArticle } from "./static.content/learn.article";
import { ContactArticle } from "../../components/contact.article.component";
import Footer from "../../components/footer.component";
import Contentful from "../../utils/app.helpers/contentful";
import Switch from "react-switch";
import styles from "./styles.module.scss";

export const HomepageViewer: React.FC<IHomepageProps> = ({ sliders }) => {
  const [slidersContentful, setSliderContentful] = React.useState(null);
  const [activeSection, setActiveSection] = React.useState(0);
 
  React.useEffect(() => {
    const _instance = Contentful.getInstance();
    _instance.client.getEntries({content_type: 'mostSliderSection'}).then((res) => {

      const slidres = res.items.map((slider: any) => {
        return {
          title: slider.fields.name || "",
          heading: slider.fields.heading || "",
          emphasize: slider.fields.emphasiseWord || "",
          chairs: Array.isArray(slider.fields.chairs)
            ? slider.fields.chairs.map((chair: any, index: number) => ({
                title: chair.fields?.title || '',
                price: chair.fields?.price || 0,
                id: index,
                label: chair.fields?.category ? chair.fields.category.toUpperCase() : '',
                category: chair.fields?.category ? chair.fields.category.toUpperCase() : '',
                thumbnail: chair.fields?.thumbnail ? chair.fields.thumbnail.fields.file.url : '',
              }))
            : [],
        };
      });
      
      setSliderContentful(slidres);
    });
  }, []);

  const renderSlider = (type: string, section: any, key: number) => {
    // const slider = sliders.find(el => el.location === type);

    const settings: Settings = {
      dots: false,
      swipe: true,
      arrows: true,
      infinite: true,
      speed: 500,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dotsClass: "slider_dots",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            swipe: true,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipe: true,
            arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: true,
            arrows: false,
            dots: true,
          },
        },
      ],
    };
    return (
      <Carousel
        title={section.heading}
        contents={section.chairs}
        settings={settings}
        emphasize={section.emphasize}
        key={key + ''}
      />
    );
  };

  // const renderSalesSlider = (type: string) => {
  //   const slider = sliders.find(el => el.location === type);
  //   const settings: Settings = {
  //     dots: false,
  //     swipe: true,
  //     arrows: true,
  //     infinite: true,
  //     speed: 500,
  //     autoplay: false,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     dotsClass: "slider_dots",
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 1,
  //           swipe: true,
  //         }
  //       },
  //       {
  //         breakpoint: 1023,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 1,
  //           swipe: true,
  //           arrows: false,
  //           dots: true
  //         }
  //       },
  //       {
  //         breakpoint: 720,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //           swipe: true,
  //           arrows: false,
  //           dots: true
  //         }
  //       }
  //     ]
  //   };
  //   console.log(slidersContentful[0].chairs)
  //   return (
  //     <Carousel title={slider!.title} contents={slidersContentful[0].chairs} settings={settings}/>
  //   )
  // };

  return (
    <React.Fragment>
      <Helmet>
        <title>Home | Eurotech</title>
        <meta
          name="description"
          content="Exceptional Seating without Exception. Welcome to Eurotech."
        />
      </Helmet>
      <section>
        <HBannerComponent />
        <WelcomeArticle />
        <ProductCatalog />
        <CreateArticle />
        {slidersContentful && (
          <div className={styles.switchWrapper}>
            <p className={activeSection === 0 ? styles.switchWrapper_active : ''}>{slidersContentful[0].title}</p>
            <Switch
              onChange={() => setActiveSection((prev) => (prev === 0 ? 1 : 0))}
              checked={activeSection === 1}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#fff"
              offColor="#fff"
              offHandleColor="#000"
              onHandleColor="#000"
              // className={styles.switch}
            />
            <p className={activeSection === 1 ? styles.switchWrapper_active : ''}>{slidersContentful[1].title}</p>
          </div>
        )}
        {slidersContentful &&
          slidersContentful.map(
            (section: any, index: number) =>
              index === activeSection && renderSlider("homepage", section, index)
          )}
        <LearnArticle />
        <ContactArticle />
        <Footer />
      </section>
    </React.Fragment>
  );
};
