import * as actions from '../action.constants/customizer.constants';

import Sequence from "../../../types/Sequence";
import Seating from "../../../types/Seating";
import Model from "../../../types/Model";
import Fabric from "../../../types/Fabric";
import {IStateRecord} from "../module.interfaces/customizer.interface";

// ====================================
// Requests
// ====================================
export const fetchDesign = (): {type: typeof actions.FETCH_DESIGN} => ({
   type: actions.FETCH_DESIGN
});

export const fetchDesignRequest = (): {type: typeof actions.FETCH_DESIGN_REQUEST} => ({
    type: actions.FETCH_DESIGN_REQUEST
});

export const fetchDesignSuccess = (status: boolean): {type: typeof actions.FETCH_DESIGN_SUCCESS, status: boolean } => ({
    type: actions.FETCH_DESIGN_SUCCESS,
    status
});

export const fetchDesignFailure = (error: string): {type: typeof actions.FETCH_DESIGN_FAILURE, error: string} => ({
    type: actions.FETCH_DESIGN_FAILURE,
    error
});

export const fetchSequencesSuccess = (sequences: Sequence[]): {type: typeof actions.FETCH_SEQUENCES_SUCCESS, sequences: Sequence[]} => ({
   type: actions.FETCH_SEQUENCES_SUCCESS,
    sequences
});

export const fetchSeatingsSuccess = (seatings: Seating[]): {type: typeof actions.FETCH_SEATINGS_SUCCESS, seatings: Seating[]} => ({
    type: actions.FETCH_SEATINGS_SUCCESS,
    seatings
});

export const fetchEntries = (uuid: string[], key: keyof IStateRecord, constructor: {class: any}): {type: typeof actions.FETCH_ENTRIES, uuid: string[], key: keyof IStateRecord, constructor: {class: any}} => ({
    type: actions.FETCH_ENTRIES,
    uuid,
    key,
    constructor
});

export const fetchEntriesRequest = (): {type: typeof actions.FETCH_ENTRIES_REQUEST} => ({
    type: actions.FETCH_ENTRIES_REQUEST
});

export const fetchEntriesSuccess = (payload: {name: keyof IStateRecord, value: any}): {type: typeof actions.FETCH_ENTRIES_SUCCESS, payload: {name: keyof IStateRecord, value: any}} => ({
    type: actions.FETCH_ENTRIES_SUCCESS,
    payload
});

export const fetchEntriesFailure = (error: string): {type: typeof actions.FETCH_ENTRIES_FAILURE, error: string} => ({
    type: actions.FETCH_ENTRIES_FAILURE,
    error
});

export const fetchOptionsSuccess = (): {type: typeof actions.FETCH_OPTIONS_SUCCESS} => ({
   type: actions.FETCH_OPTIONS_SUCCESS
});

// ====================================
// User actions
// ====================================
export const selectSequence = (sequence: Sequence): {type: typeof actions.SELECT_SEQUENCE, sequence: Sequence} => ({
    type: actions.SELECT_SEQUENCE,
    sequence
});

export const selectSequenceSuccess = (sequence: Sequence): {type: typeof actions.SELECT_SEQUENCE_SUCCESS, sequence: Sequence} => ({
    type:  actions.SELECT_SEQUENCE_SUCCESS,
    sequence
});

export const selectOption = (payload: {type: string, option: Seating | Model /*| Color */ | Fabric}): {type: typeof actions.SELECT_OPTION, payload: {type: string, option: Seating | Model /*| Color */ | Fabric}} => ({
    type: actions.SELECT_OPTION,
    payload
});

export const selectOptionSuccess = (payload: {type: string, option: Seating | Model /*| Color */ | Fabric}): {type: typeof actions.SELECT_OPTION_SUCCESS, payload: {type: string, option: Seating | Model /*| Color */ | Fabric}} => ({
    type: actions.SELECT_OPTION_SUCCESS,
    payload
});
export const changeControls = (payload: {name: keyof IStateRecord, value: any}): {type: typeof actions.CHANGE_CONTROLS, payload: {name: keyof IStateRecord, value: any}} => ({
    type: actions.CHANGE_CONTROLS,
    payload
});

export const changeControlsSuccess = (payload: {name: keyof IStateRecord, value: any}): {type: typeof actions.CHANGE_CONTROLS_SUCCESS, payload: {name: keyof IStateRecord, value: any}} => ({
    type: actions.CHANGE_CONTROLS_SUCCESS,
    payload
});