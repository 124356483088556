import {put, call, all} from 'redux-saga/effects';
import {
    fetchDesignRequest,
    fetchDesignSuccess,
    fetchDesignFailure,
    fetchSequencesSuccess,
    fetchSeatingsSuccess,
    fetchEntries,
    fetchEntriesRequest,
    fetchEntriesSuccess,
    fetchEntriesFailure,
    selectSequence,
    selectSequenceSuccess,
    selectOption,
    selectOptionSuccess,
    changeControls,
    changeControlsSuccess
} from "../action.creators/customizer.actions";

import Contentful from "../../../utils/app.helpers/contentful";
import Sequence from "../../../types/Sequence";
import Seating from "../../../types/Seating";
import { initBannerImages, initPartnerList } from '../action.creators/app.actions';

const _instance = Contentful.getInstance();

export const fetchDesignSaga = function* () {
    try {
        yield put(fetchDesignRequest());
        //@ts-ignore
        const response: any = yield call(async () => {
            return await _instance.client.getEntry("2s3qGlF2wi6efHjhVTPpdM").catch(err => console.log(err));
        });

      yield put(initPartnerList(response.fields.partner_list));
      yield put(initBannerImages(response.fields.banner_images));

        const sequences = response.fields.sequences.map((el: any) => {
            return new Sequence(el.fields);
        }).filter((el: Sequence) => el.id !== undefined);
        console.log(response.fields.seatings)

        const seatings = response.fields.seatings.filter((el: any) => el.hasOwnProperty('fields')).map((el: any) => {
            return new Seating(el)
        });


        yield put(fetchSequencesSuccess(sequences));
        yield put(fetchSeatingsSuccess(seatings));
        yield put(fetchDesignSuccess(true));
    } catch (error) {
        console.log('ERROR', error);
        //@ts-ignore
        yield put(fetchDesignFailure(error.message))
    }
};

export const fetchEntriesSaga = function* (payload: ReturnType<typeof fetchEntries>) {
    try {
        yield put(fetchEntriesRequest());
        //@ts-ignore
        const entries = yield all(payload.uuid.map((uuid: string) => call(async () => {
            const entry = await _instance.client.getEntry(uuid).catch(err => console.log(err));

            if(entry) {
                return new payload.constructor.class(entry);
            }
        })));

        yield put(fetchEntriesSuccess({name: payload.key, value: entries}));
    } catch (error) {
        console.error('ERROR', error);
        yield put(fetchEntriesFailure(error.message))
    }
};

export const selectSequenceSaga = function* (payload: ReturnType<typeof selectSequence>) {
    yield put(selectSequenceSuccess(payload.sequence))
};

export const selectOptionSaga = function* ({payload}: ReturnType<typeof selectOption>) {
    yield put(selectOptionSuccess({...payload}))
};

export const changeControlsSaga = function* ({payload}: ReturnType<typeof changeControls>) {
    yield put(changeControlsSuccess({...payload}));
};
