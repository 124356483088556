import { Theme, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

export const LinkButton = withStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    bottom: "10%",
    margin: "0 auto",
    height: "70px",
    minWidth: "240px",
    maxWidth: "240px",
    padding: 0,
    overflow: "hidden",
    border: "1px solid #ddd",
    borderRadius: "35px",
    backgroundColor: "#fff",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      minWidth: "180px",
      maxWidth: "180px",
    },
    "&:hover": {
      backgroundColor: "#eee",
    },
    "&:disabled": {
      backgroundColor: "#eee",
      pointerEvents: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  label: {
    height: "inherit",
  },
}))(Button);

export const BuyNowButton = withStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    bottom: "10%",
    margin: "0 auto",
    height: "70px",
    minWidth: "240px",
    maxWidth: "240px",
    padding: 0,
    overflow: "hidden",
    border: "1px solid #ddd",
    borderRadius: "35px",
    backgroundColor: "#D13532",
    transition: "all .45s ease",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      minWidth: "180px",
      maxWidth: "180px",
    },
    "&:hover": {
      backgroundColor: "#be302e",
      transform: "scale(.98)",
    },
    "&:disabled": {
      backgroundColor: "#be302e",
      pointerEvents: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  label: {
    height: "inherit",
  },
}))(Button);

export const LinkButtonIntro = withStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    bottom: "35%",
    margin: "0 auto",
    height: "70px",
    minWidth: "240px",
    maxWidth: "240px",
    padding: 0,
    overflow: "hidden",
    border: "1px solid #ddd",
    borderRadius: "35px",
    backgroundColor: "#fff",
    cursor: "pointer",
    opacity: 1,
    transition: "opacity .55s ease",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      minWidth: "180px",
      maxWidth: "180px",
    },
    "&:hover": {
      backgroundColor: "#eee",
    },
    "&:disabled": {
      opacity: 0,
      backgroundColor: "#eee",
      pointerEvents: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  label: {
    height: "inherit",
  },
}))(Button);

export const CarouselArrowControl = withStyles(() => ({
  root: {
    height: "70px",
    minWidth: "70px",
    maxWidth: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    textTransform: "none",
    backgroundColor: "rgba(0, 0, 0, .25)",
    color: "#fff",
    borderRadius: "50%",
    border: "none",
    cursor: 'pointer',
    transition: "all .15s easy-in-out",
    "@media screen and (min-width: 1024px) and (max-width: 1280px)": {
      height: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
    "&:hover": {
      color: "#fff",
      backgroundColor: "rgba(0, 0, 0, .45)",
    },
    "&:before": {
      display: "none",
    },
  },
}))(Button);

export const OverviewArrow = withStyles(() => ({
  root: {
    height: "40px",
    minWidth: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    textTransform: "none",
    color: "#fff",
    borderRadius: "50%",
    border: "none",
    opacity: 0.5,
    transition: "all .15s easy-in-out",
    "@media screen and (min-width: 1024px) and (max-width: 1280px)": {
      height: "40px",
      minWidth: "40px",
    },
    "&:hover": {
      color: "#fff",
      opacity: 1,
    },
    "&:before": {
      display: "none",
    },
  },
}))(Button);

export const SceneControl = withStyles(() => ({
  root: {
    height: "70px",
    minWidth: "70px",
    maxWidth: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    overflow: "hidden",
    fontSize: "24px",
    textTransform: "none",
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.03)",
    borderRadius: "50%",
    border: "1px solid rgba(0,0,0, 0.1)",
    transition: "all .15s easy-in-out",
    "&:hover": {
      color: "#000",
      backgroundColor: "#c3c3c3",
    },
    "&:disabled": {
      opacity: 0.3,
      backgroundColor: '#fff',
    },
    "&:before": {
      display: "none",
    },
    "@media screen and (max-width: 1450px)": {
      minWidth: "60px",
      maxWidth: "60px",
      height: "60px",
    },
    "@media screen and (max-width: 960px)": {
      minWidth: "60px",
      maxWidth: "60px",
      height: "60px",
    },
    "@media screen and (max-width: 768px)": {
      minWidth: "50px",
      maxWidth: "50px",
      height: "50px",
    },
    "@media screen and (max-width: 600px)": {
      minWidth: "40px",
      maxWidth: "40px",
      height: "40px",
    },
  },
}))(IconButton);

export const MenuControl = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    minWidth: "80px",
    maxWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    overflow: "hidden",
    fontSize: "32px",
    textTransform: "none",
    backgroundColor: "#fff",
    color: "#000",
    borderRight: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "unset",
    "@media screen and (max-width: 1024px)": {
      fontSize: "24px",
      minWidth: "80px",
      maxWidth: "80px",
    },
    "@media screen and (max-width: 600px)": {
      fontSize: "24px",
      minWidth: "60px",
      maxWidth: "60px",
    },
    "&:hover": {
      color: "#000",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    "&:before": {
      display: "none",
    },
  },
}))(Button);

export const SearchControl = withStyles((theme: Theme) => ({
  root: {
    height: "80px",
    minWidth: "160px",
    maxWidth: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 0,
    overflow: "hidden",
    fontSize: "32px",
    textTransform: "none",
    backgroundColor: "#fff",
    color: "#000",
    borderLeft: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "unset",
    "@media screen and (max-width: 1024px)": {
      fontSize: "24px",
      minWidth: "80px",
      maxWidth: "80px",
      height: "80px",
    },

    "@media screen and (max-width: 600px)": {
      fontSize: "24px",
      minWidth: "60px",
      maxWidth: "60px",
      height: "60px",
    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#fff",
    },
    "&:before": {
      display: "none",
    },
  },
}))(Button);
