import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Routes from "../../../../app.router/router.constants";
import {LinkButton} from "../../../../components/buttons";
import styles from "./styles.module.scss";

export const LearnArticle = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} sm={4} md={4} className={`${styles.content} ${styles.materials}`}>
        <Typography variant="h3" className={styles.title}>Materials</Typography>
        <Typography variant="caption" className={styles.description}>
          Sleek leather. Lightweight, comfy mesh. Classic upholstery.
          Only the highest-quality materials are used to cover Eurotech chairs.
          Combine different materials for a unique look and feel in a range
          of modern colors.
        </Typography>
        <LinkButton classes={{root: styles.link_wrapper}}>
          <Link to={Routes.Material} className={styles.link}>
            <Typography variant="caption">Learn More</Typography>
            <ChevronRightIcon className={styles.link_icon}/>
          </Link>
        </LinkButton>
      </Grid>
      <Grid item xs={12} sm={4} md={4} className={`${styles.content} ${styles.solutions}`}>
        <Typography variant="h3" className={styles.title}>Solutions</Typography>
        <Typography variant="caption" className={styles.description}>
          The world of work, both remote and at the office, is changing dramatically.
          Eurotech chairs come with a variety of solutions for maximum comfort and ergonomic function.
          Synchro Tilt, adjustable back angles, swivel arms, and more mean you don’t just work in your chair.
          Your chair works for you.
        </Typography>
        <LinkButton classes={{root: styles.link_wrapper}}>
          <HashLink to={`${Routes.Material}#outlast`} className={styles.link}
                    scroll={(el: HTMLElement) => el.scrollIntoView({behavior: "smooth"})}>
            <Typography variant="caption">Learn More</Typography>
            <ChevronRightIcon className={styles.link_icon}/>
          </HashLink>
        </LinkButton>
      </Grid>
      <Grid item xs={12} sm={4} md={4} className={`${styles.content} ${styles.about}`}>
        <Typography variant="h3" className={styles.title}>About</Typography>
        <Typography variant="caption" className={styles.description}>
          Eurotech has been the premier representative for quality and ergonomic design in office chairs
          since 1979. Our commitment to design, function, and form has resulted in over 1 billion dollars in
          cumulative sales. See the Eurotech difference for yourself.
        </Typography>
        <LinkButton classes={{root: styles.link_wrapper}}>
          <Link to={Routes.About} className={styles.link}>
            <Typography variant="caption">Learn More</Typography>
            <ChevronRightIcon className={styles.link_icon}/>
          </Link>
        </LinkButton>
      </Grid>
    </Grid>
  )
};
