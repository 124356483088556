import Distributor from "./Distributor";
import { checkIsPublish } from "../redux/modules/action.generators/app.generators";

export default class Region {
    uuid: string;
    states?: string;
    entries?: string[];
    distributors?: Distributor[]

    constructor(data: any = {}) {
        this.uuid = data.sys.id;
        this.states = data.fields.states ? data.fields.states : undefined;
        this.distributors = data.fields.distributors ? checkIsPublish(data.fields.distributors, Distributor)  : undefined;
    }
}