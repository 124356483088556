import React from 'react';
import styles from '../../styles.module.scss';

export const ARIcon = () => (
  <div className={styles.pcArButton}>
    <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6328 3.21429L14.4289 1L18.225 3.21429" stroke="#D13532" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.4287 1V6.53571" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M18.225 29.7856L14.4289 31.9999L10.6328 29.7856" stroke="#D13532" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.4287 32.0001V26.4644" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M1.14258 13.1447V8.75008L4.82037 6.5697" stroke="#D13532" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.14258 8.75L5.79881 11.5179" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M27.7139 19.8553V24.25L24.0361 26.4304" stroke="#D13532" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.7148 24.25L23.0586 21.4822" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M4.82037 26.4643L1.14258 24.25V19.8553" stroke="#D13532" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.14258 24.25L5.75383 21.4822" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M24.0361 6.5697L27.7139 8.75008V13.1447" stroke="#D13532" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.7148 8.75L23.0586 11.5179" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.4287 20.9285V16.4999L18.2248 14.2856" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.4289 16.4999L10.6328 14.2856" stroke="#D13532" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  </div>
  )