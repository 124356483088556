import React from "react";
import {Grid, Typography} from "@material-ui/core";
import styles from './styles.module.scss';

export const ProductComponent = () => {

  return (
    <section className={styles.container}>
      <Grid container className={styles.wrapper}>
        <Grid container direction={"row"} className={styles.line_container}>
          <Grid item xs={12} sm={6} md={6} className={styles.description_container}>
            <Typography className={styles.title}>Since 1979</Typography>
            <Typography className={styles.description}>
              Raynor Group has been manufacturing, marketing,
              and distributing quality office seating products under the Eurotech name.
              <br/>
              As one of the leading brands in office seating, Eurotech provides a comprehensive collection
              of office and school seating solutions engineered to meet rigorous, modern demands.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={styles.image_line1}>
            <img src="" alt=""/>
          </Grid>
        </Grid>

        <Grid container className={styles.line_container}>
          <Grid item xs={12} sm={6} md={6} className={styles.image_line2}>
            <img src="" alt=""/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={styles.description_container}>
            <Typography className={styles.title}>
              Comfortable and Modern
            </Typography>
            <Typography className={styles.description}>
              Eurotech’s line of office and school seating products are sold worldwide and renowned for their
              comfort and unsurpassed ergonomic engineering. Diversified distribution channels enable Eurotech
              to provide unparalleled seating solutions to any location - no matter the order size.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </section>
  )
}
