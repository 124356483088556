import Material from "./Material";
import Fabric from "./Fabric";
import { IGrades } from "./IGrades";
import Modifications from "./Modifications";
import { AssemblyVideo } from "./AssemblyVideo";
import FabricSection from "./FabricSection";

export type FeatureNew = {
  title: string;
  description: string;
  image: string;
};

export default class Adjustment {
  id: string;
  title: string;
  price: number;
  heading?: string;
  preview?: string;
  screenshots?: string[];
  description?: string;
  features?: { key: string; description: string }[];
  specifications?: any;
  modifications?: Modifications[];
  grades?: IGrades;
  product_sheet?: string;
  dimension_sheet?: string;
  assembly_instruction?: string;
  assembly_video?: AssemblyVideo[];
  materials: Material[];
  fabrics: Fabric[];
  fabrics_new?: FabricSection[];
  config?: {
    visible_meshes: [];
  };
  fabricModification?: string[];
  specifications_new?: [];
  features_new?: FeatureNew[];
  angleMeasurements?: any;
  profileMeasurements?: any;
  enablePdfDownloading?: boolean;

  constructor(data: any = {}) {
    console.log(data);
    this.id = data.sys.id;
    this.title = data.fields.title;
    this.price = data.fields.price;
    this.heading = data.fields.heading ? data.fields.heading : undefined;
    this.preview = data.fields.preview
      ? data.fields.preview.fields.file.url
      : undefined;
    this.screenshots = data.fields.screenshots
      ? data.fields.screenshots.map((el: any) => el.fields.file.url)
      : undefined;
    this.description = data.fields.description
      ? data.fields.description
      : undefined;
    this.features = data.fields.features
      ? data.fields.features.details
      : undefined;
    this.features_new = data.fields.featuresNew
      ? data.fields.featuresNew.map((feature: any) => ({
          title: feature.fields.name,
          description: feature.fields.description,
          image: feature.fields.image.fields.file.url,
        }))
      : undefined;
    this.specifications = data.fields.specifications;
    this.modifications = data.fields.modification
      ? data.fields.modification.modifications.map(
          (el: any) => new Modifications(el)
        )
      : undefined;
    this.grades = data.fields.grades;
    this.product_sheet = data.fields.product_sheet
      ? data.fields.product_sheet.fields.file.url
      : undefined;
    this.dimension_sheet = data.fields.dimension_sheet
      ? data.fields.dimension_sheet.fields.file.url
      : undefined;
    this.assembly_instruction = data.fields.assembly_instruction
      ? data.fields.assembly_instruction.fields.file.url
      : undefined;
    this.assembly_video = data.fields.assembly_video
      ? data.fields.assembly_video.map((el: any) => new AssemblyVideo(el))
      : undefined;
    this.materials = data.fields.materials
      ? data.fields.materials.map((el: any) => new Material(el))
      : undefined;
    this.fabrics = data.fields.fabrics
      ? data.fields.fabrics.map((el: any) => new Fabric(el))
      : undefined;
    this.fabrics_new = data.fields.fabricsNew
      ? data.fields.fabricsNew.map((el: any) => new FabricSection(el))
      : undefined;
    this.config = data.fields.config ? data.fields.config : undefined;
    this.specifications_new = data.fields.specificationsNew
      ? data.fields.specificationsNew.map((spec: any) => ({
          name: spec.fields.name,
          value: spec.fields.value,
        }))
      : undefined;
    this.fabricModification = data.fields.fabricModification
      ? data.fields.fabricModification
          .split(",")
          .map((item: string) => item.toLowerCase().trim())
      : ["sit"];
    this.enablePdfDownloading = data.fields.enablePdfDownloading;
  }
}
