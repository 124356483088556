import React, { useState } from 'react'
import styles from './styles.module.scss'
import Model from '../../../../types/Model'
import Seating from '../../../../types/Seating'
import Fabric from '../../../../types/Fabric'
import { IOptions } from '../../../../types/IOptions'
import { useDispatch, useSelector } from 'react-redux'
import {
  adjustmentSelector,
  pendingSelector,
} from '../../../../redux/modules/customizer.module'
import { changeControls, selectOption } from '../../../../redux/modules/action.creators/customizer.actions'
import DropdownComponent from '../../../../components/dropdown.component/dropdown.component'
import { MenuItem } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'

interface IModelSeating {
  options: IOptions
}

const ModelSeating = ({ options }: IModelSeating) => {
  const dispatch = useDispatch()

  const pending = useSelector(pendingSelector)
  const adjustment = useSelector(adjustmentSelector)

  const [activeSeating, setActiveSeating] = useState('Task' as string)
  const [openDropdown, setOpenDropdown] = React.useState(false as boolean);

  const handleSelect = (option: Seating | Model | Fabric) => {
    option instanceof Seating && dispatch(changeControls({ name: 'reload', value: true }))
    dispatch(selectOption({ type: 'seating', option: option }))
    setActiveSeating(option.title)
  }
  const renderAssets = () => {
    return (options.seatings as [])
      .filter((option: Seating | Model | Fabric) => (option instanceof Model ? !!(option as Model).configurable : true))
      .filter((option: Seating | Model | Fabric) => (option instanceof Seating && option.title === 'Accessories' ? null : option))
      .filter((option: Seating | Model | Fabric) => (option instanceof Seating && option.title === 'All Chairs' ? null : option))
      .map((option: Seating | Model | Fabric) => option instanceof Seating && option.title !== 'All Chairs' ?
        (
          <MenuItem
            key={option.id}
            onClick={() => !pending && handleSelect(option)}
            style={pending ? { pointerEvents: 'none' } : {}}>
            {option.title}
          </MenuItem>
        ) : null
      )
  }



  return adjustment ? (
    <div>
      <DropdownComponent
        setOpenDropdown={setOpenDropdown}
        styles={styles.seating__select} contentHTML={
        <div className={styles.seating__button}>
          <span>{activeSeating}</span>
          <ChevronRight className={`${styles.seating__chevron} ${openDropdown ? styles.open : ''}`}/>
        </div>
      }>
        {renderAssets()}
      </DropdownComponent>
    </div>
  ) : null
}

export default ModelSeating