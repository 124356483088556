export const CHANGE_CONTROLS = '[app] CHANGE CONTROLS';
export const CHANGE_CONTROLS_SUCCESS = '[app] CHANGE CONTROLS SUCCESS';

export const SEARCH_REQUEST = '[app] SEARCH REQUEST';
export const SEARCH_SUCCESS = '[app] SEARCH SUCCESS';

export const GSA_REQUEST = '[app] GSA REQUEST';
export const GSA_SUCCESS = '[app] GSA SUCCESS';

export const DISCOUNT_CHAIRS = '[app] DISCOUNT CHAIRS';
export const DISCOUNT_CHAIRS_REQUEST = '[app] DISCOUNT CHAIRS REQUEST';
export const DISCOUNT_CHAIRS_SUCCESS = '[app] DISCOUNT CHAIRS SUCCESS';
export const DISCOUNT_CHAIRS_FAILURE = '[app] DISCOUNT CHAIRS FAILURE';

export const FETCH_ASSETS = '[app] FETCH ASSETS';
export const FETCH_ASSETS_REQUEST = '[app] FETCH ASSETS REQUEST';
export const FETCH_ASSETS_SUCCESS = '[app] FETCH ASSETS SUCCESS';
export const FETCH_ASSETS_FAILURE = '[app] FETCH ASSETS FAILURE';

export const FETCH_DISTRIBUTORS = '[app] FETCH DISTRIBUTORS';
export const FETCH_DISTRIBUTORS_REQUEST = '[app] FETCH DISTRIBUTORS REQUEST';
export const FETCH_DISTRIBUTORS_SUCCESS = '[app] FETCH DISTRIBUTORS SUCCESS';
export const FETCH_DISTRIBUTORS_FAILURE = '[app] FETCH DISTRIBUTORS FAILURE';

export const SEND_MAIL = '[app] SEND MAIL';
export const SEND_MAIL_REQUEST = '[app] SEND MAIL REQUEST';
export const SEND_MAIL_SUCCESS = '[app] SEND MAIL SUCCESS';
export const SEND_MAIL_FAILURE = '[app] SEND MAIL FAILURE';

export const FETCH_TOOLS = '[app] FETCH TOOLS';
export const FETCH_TOOLS_REQUEST = '[app] FETCH TOOLS REQUEST';
export const FETCH_TOOLS_SUCCESS = '[app] FETCH TOOLS SUCCESS';
export const FETCH_TOOLS_FAILURE = '[app] FETCH TOOLS FAILURE';

export const FETCH_CONTRACTS = '[app] FETCH CONTRACTS';
export const FETCH_CONTRACTS_REQUEST = '[app] FETCH CONTRACTS TOOLS REQUEST';
export const FETCH_CONTRACTS_SUCCESS = '[app] FETCH CONTRACTS SUCCESS';
export const FETCH_CONTRACTS_FAILURE = '[app] FETCH CONTRACTS FAILURE';

export const FETCH_FABRIX_CATALOG = '[app] FETCH FABRIX CATALOG';
export const FETCH_FABRIX_CATALOG_REQUEST = '[app] FETCH FABRIX CATALOG REQUEST';
export const FETCH_FABRIX_CATALOG_SUCCESS = '[app] FETCH FABRIX CATALOG SUCCESS';
export const FETCH_FABRIX_CATALOG_FAILURE = '[app] FETCH FABRIX CATALOG FAILURE';

export const INIT_PARTNER_LIST = '[app] INIT PARTNER LIST';
export const INIT_PARTNER_LIST_REQUEST = '[app] INIT PARTNER LIST REQUEST';
export const INIT_PARTNER_LIST_SUCCESS = '[app] INIT PARTNER LIST SUCCESS';
export const INIT_PARTNER_LIST_FAILURE = '[app] INIT PARTNER LIST FAILURE';

export const INIT_BANNER_IMAGES = '[app] INIT BANNER IMAGES';
export const INIT_BANNER_IMAGES_REQUEST = '[app] INIT BANNER IMAGES REQUEST';
export const INIT_BANNER_IMAGES_SUCCESS = '[app] INIT BANNER IMAGES SUCCESS';
export const INIT_BANNER_IMAGES_FAILURE = '[app] INIT BANNER IMAGES FAILURE';

export const SET_ACTIVE_GRADE = '[app] SET ACTIVE GRADE'
