import React from "react";
import {Grid, Typography} from "@material-ui/core";
import styles from './styles.module.scss';

export const SalesComponent = () => {
  return (
    <>
      <Grid container className={styles.wrapper}>
        <Grid  className={styles.header_container}>
          <Typography className={styles.title}>
            Having reached cumulative sales of over  <label className={styles.red_item}>1 billion dollars</label>,
            Eurotech offers dependability you can expect from a legacy company.
          </Typography>

          <Typography className={styles.sub_title}>
            By providing personalized, built-to-order service, Eurotech’s customers can easily spec their
            seating projects and get precisely what they’ve envisioned for the office or classroom.
          </Typography>

        </Grid>
      </Grid>
    </>
  )
}
