import Model from "./Model";

export default class Partner {
    id: string;
    title: string;
    partnerName: string;
    description: string;
    logotype?: string;
    preview?: string;
    products?: Model[];

    constructor(data: any) {
        this.id = data.sys.id;
        this.title = data.fields.title;
        this.partnerName = data.fields.partner_name;
        this.description = data.fields.description;
        this.logotype = data.fields.logotype ? data.fields.logotype.fields.file.url : undefined;
        this.preview = data.fields.preview ? data.fields.preview.fields.file.url : undefined;
        this.products = data.fields.products ? data.fields.products : undefined;
    }
}