import * as THREE from 'three'

export const fitCameraToObject = ({
    camera,
    selection = [],
    fitRatio = 1.2,
    controls
}: {camera: any, selection: any, fitRatio?: any, controls: any}) => {

    const box = new THREE.Box3();

    selection.forEach((item: any) => box.expandByObject(item));

    const { x: sx, y: sy, z: sz } = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());

    const maxSize = Math.max(sx, sy, sz);

    const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;

    const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = controls.target.clone()
        .sub(camera.position)
        .normalize()
        .multiplyScalar(distance);

    controls.target.copy(center);

    camera.near = distance / 100;
    camera.far = distance * 100;
    camera.updateProjectionMatrix();

    camera.position.copy(controls.target).sub(direction);
    controls.viewTarget = controls.target.clone();

    controls.update();
};

export const wait = (t: number = 50) =>  new Promise((r) => setTimeout(r, t));
