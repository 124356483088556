import React, { useRef, useState } from 'react'

import {
    ClickAwayListener,
    Grid, Grow, MenuItem, MenuList, Paper, Popper,
    Typography
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {LinkButton} from "../../../components/buttons";
import {changeControls} from "../../../redux/modules/action.creators/app.actions";
import {useDispatch} from "react-redux";
import styles from "./styles.module.scss";
import "./slider.css";
import {AssemblyVideo} from "../../../types/AssemblyVideo";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";

interface IMapProps {
    description?: string;
    screenshots?: string[];
    product_sheet?: string;
    assembly_instruction?: string;
    dimension_sheet?: string;
    assembly_video?: AssemblyVideo[],
    renderProductPdf: () => void,
    enablePdfDownloading?: boolean
}

export const Overview: React.FC<IMapProps> = ({
                                                  description,
                                                  screenshots,
                                                  product_sheet,
                                                  assembly_instruction,
                                                  dimension_sheet,
                                                  assembly_video,
                                                  renderProductPdf,
                                                  enablePdfDownloading
                                              }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);


    const handleToggle = () => {
        if (assembly_video.length > 1) {
            setOpen((prevOpen) => !prevOpen);
        } else {
            dispatch(changeControls({name: 'selectVideo', value: assembly_video[0]}));
            dispatch(changeControls({name: 'showVideo', value: true}));
            setOpen(false);
        }
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleClickVideo = (video: AssemblyVideo) => {
        dispatch(changeControls({name: 'selectVideo', value: video}));
        dispatch(changeControls({name: 'showVideo', value: true}));
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }



    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <Grid container className={styles.wrapper}>
            <Grid item md={6} xs={12} className={styles.overview_article}>
                <Typography variant="h6" className={styles.heading} >
                    Overview
                </Typography>
                <Typography variant="h6" className={styles.description}>
                    {description}
                </Typography>
                <div className={styles.greenguard}>
                    <div className={styles.greenguard__icon}>
                        <img className={styles.greenguard__img} alt="" src={`/assets/images/features/greenguard.png`}/>
                    </div>
                    <article className={styles.greenguard__info}>
                        <Typography variant="caption" className={styles.greenguard__title}>Greenguard Certified</Typography>
                        <Typography variant="caption" className={styles.greenguard__description}>Available with or without casters</Typography>
                    </article>
                </div>
            </Grid>

            <Grid  className={styles.links_wrapper}>
                <Grid item md={3} sm={12} xs={12}>
                    <LinkButton
                        classes={{root: styles.link_wrapper}}
                        style={!product_sheet ? {display: "none"} : {display: "flex"}}
                    >
                        <a
                            href={`https:${product_sheet}`}
                            target="_blank"
                            className={styles.link}
                        >
                            <Typography className={styles.link_heading} variant="caption">
                                Download Product Sheet
                            </Typography>
                            <ChevronRightIcon className={styles.link_icon}/>
                        </a>
                    </LinkButton>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                    <LinkButton
                        classes={{root: styles.link_wrapper}}
                        style={
                            !assembly_instruction ? {display: "none"} : {display: "flex"}
                        }
                    >
                        <a
                            href={`https:${assembly_instruction}`}
                            target="_blank"
                            className={styles.link}
                        >
                            <Typography className={styles.link_heading} variant="caption">
                                Download Assembly Instructions
                            </Typography>
                            <ChevronRightIcon className={styles.link_icon}/>
                        </a>
                    </LinkButton>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                    <LinkButton
                        classes={{root: styles.link_wrapper}}
                        style={!dimension_sheet ? {display: "none"} : {display: "flex"}}
                    >
                        <a
                            href={`https:${dimension_sheet}`}
                            target="_blank"
                            className={styles.link}
                        >
                            <Typography className={styles.link_heading} variant="caption">
                                Download Dimension Sheet
                            </Typography>
                            <ChevronRightIcon className={styles.link_icon}/>
                        </a>
                    </LinkButton>
                </Grid>
                { enablePdfDownloading && (
                    <Grid item md={3} sm={12} xs={12}>
                        <LinkButton
                            id='download-pdf-btn'
                            classes={{root: styles.link_wrapper}}
                            style={!dimension_sheet ? {display: "none"} : {display: "flex"}}
                            onClick={renderProductPdf}
                        >
                            <span className={styles.link}
                            >

                                <Typography className={styles.link_heading} variant="caption">
                                    Download Chair Info
                                </Typography>
                                <ChevronRightIcon className={styles.link_icon}/>
                            </span>
                        </LinkButton>
                    </Grid>
                )}                
                <Grid item md={3} sm={12} xs={12}>
                    <LinkButton
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        classes={assembly_video && assembly_video.length === 1 ? {root: styles.link_wrapper} : open ? {root: styles.link_wrapper_assembly_open} : {root: styles.link_wrapper_assembly}}
                        style={!assembly_video ? {display: "none"} : {display: "flex"}}
                    >
                        <div className={styles.link}>
                            <Typography className={styles.link_heading} variant="caption">
                                Assembly Video
                            </Typography>
                            <ChevronRightIcon className={styles.link_icon}/>
                        </div>
                    </LinkButton>

                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{zIndex: 1}}
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                            className={styles.list_wrapper}
                                        >

                                            {assembly_video && assembly_video.map((video) => {
                                                return (
                                                    <MenuItem key={video.title} className={styles.list_item}
                                                              onClick={() => handleClickVideo(video)}>
                                                        <PlayCircleFilledWhiteOutlinedIcon style={{color: "grey"}}/>
                                                        <Typography
                                                            className={styles.video_title}>{video.title}</Typography>
                                                    </MenuItem>
                                                )
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>

        </Grid>
    );
};
