import { useRef } from "react";
import jsPDF from "jspdf";
import Model from "../../types/Model";
import Adjustment from "../../types/Adjustment";

export const useGeneratePDF = (
  models: Model[],
  model: Model,
  adjustment: Adjustment,
  title: string
) => {
  const imageRefs = [
    useRef<HTMLImageElement>(null),
    useRef<HTMLImageElement>(null),
  ];

  const recommendedImageRefs = useRef<HTMLImageElement[]>([]);
  const featuresImageRefs = useRef<HTMLImageElement[]>([]);
  const logoRef = useRef<HTMLImageElement>(null);

  const convertImageToBase64 = (
    imgElement: HTMLImageElement
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const scale = 2;
      canvas.width = imgElement.width * scale;
      canvas.height = imgElement.height * scale;
      const ctx = canvas.getContext("2d")!;
      ctx.scale(scale, scale);
      ctx.drawImage(imgElement, 0, 0, imgElement.width, imgElement.height);

      const dataURL = canvas.toDataURL("image/png");
      if (dataURL) {
        resolve(dataURL);
      } else {
        reject("Failed to convert image to base64.");
      }
    });
  };

  const formatStringForLink = (inputString: string) => {
    return inputString.replace(/\s+/g, "_").toLowerCase();
  }

  const formatString = (inputString: string) => {
    return inputString
      .split(/[_\s-]+/)
      .map((word) => {
        if (word.length > 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      })
      .join(" ");
  };

  const limitText = (inputString: string, limit: number = 21) => {
    const formattedString = formatString(inputString);

    if (formattedString.length > limit) {
      return formattedString.substring(0, limit - 3) + "...";
    }

    return formattedString;
  };

  const generatePDF = async () => {
    try {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "a4",
      });
      const imageAspectRatio = 140 / 90;
      const imageHeight = 270;
      const imageWidth = imageHeight / imageAspectRatio;

      const pdfDocumentWidth = pdf.internal.pageSize.getWidth();
      const pdfDocumentHeight = pdf.internal.pageSize.getHeight();

      const rectWidth = pdfDocumentWidth / 1.7;
      const rectHeight = pdfDocumentHeight / 1.2;

      //Gray background
      pdf.setFillColor(247, 247, 247);
      pdf.rect(0, 0, rectWidth, rectHeight, "F");

      // LINES
      // Vertical line
      pdf.setLineWidth(1);
      pdf.setDrawColor(232, 232, 232);
      pdf.line(rectWidth, 0, rectWidth, pdfDocumentHeight);
      // Horizontal line
      pdf.line(0, rectHeight, rectWidth, rectHeight);

      // Price
      pdf.setFont("helvetica", "normal");
      pdf.setTextColor(209, 53, 50);
      pdf.setFontSize(14);
      pdf.text(`$${model.price}`, rectWidth - 55, 35);

      // Specifications
      // Add specifications
      let specYStartPosition =
        pdfDocumentHeight - (pdfDocumentHeight - rectHeight) / 1.45;
      let specXStartPosition = 20;
      let itemCount = 0;

      for (const [key, value] of Object.entries(adjustment.specifications)) {
        if (itemCount % 3 === 0 && itemCount !== 0) {
          specXStartPosition = 20;
          specYStartPosition += 28;
        }

        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(10);
        pdf.text(
          `${formatString(key)}:`,
          specXStartPosition,
          specYStartPosition
        );

        if (typeof value === "string") {
          pdf.setFont("helvetica", "normal");
          pdf.setTextColor(153, 153, 153);
          pdf.setFontSize(7);

          pdf.text(value, specXStartPosition, specYStartPosition + 9);
        }

        specXStartPosition += 120;
        itemCount++;
      }

      // Main Images
      for (let i = 0; i < imageRefs.length; i++) {
        const imgElement = imageRefs[i].current!;
        const base64Image = await convertImageToBase64(imgElement);

        const xPos = 10 + i * imageWidth;
        const yPos = 65;

        pdf.addImage(base64Image, "PNG", xPos, yPos, imageWidth, imageHeight);
      }

      // Title
      const textBlockWidth = 100;

      const splitText = pdf.splitTextToSize(
        formatString(title),
        textBlockWidth
      );

      pdf.setFont("helvetica", "bold");
      pdf.setTextColor(41, 54, 115);
      pdf.setFontSize(18);
      pdf.text(splitText, 20, 35);

      // Main Product Link
      const url = `https://www.eurotechseating.com/${model.category}/${formatStringForLink(
        title
      )}`;
      pdf.link(0, 0, rectWidth, rectHeight, { url: url });

      // Recommended Products
      let recommendedRectHeight = 0;

      if (models.length) {
        const serial = models.find(
          (m: Model) =>
            m.title.split(" ").join("_").toLowerCase() === title.toLowerCase()
        )?.serial;
        let recommendedModels = models.filter(
          (m) => m.serial === serial
        );

        const showFullRect = recommendedModels.length > 3;
        const showHalfRect = recommendedModels.length <= 3;
        const showNoRect = recommendedModels.length === 0;

        if (recommendedModels.length) {
          console.log("recommendedModels", recommendedModels);

          //TODO test this case
          if (showHalfRect) {
            recommendedRectHeight = pdfDocumentHeight * 0.2 + 5;
          }

          if (showFullRect) {
            recommendedRectHeight = pdfDocumentHeight * 0.4;
          }

          if (recommendedImageRefs.current.length) {
            //Recommendations background
            pdf.setFillColor(247, 247, 247);
            pdf.rect(
              rectWidth + 0.5,
              0,
              pdfDocumentWidth - rectWidth,
              recommendedRectHeight,
              "F"
            );

            const rectMargin = 7;

            let rectStrokeXPos = rectWidth + rectMargin;
            let rectStrokeYPos = rectMargin;
            const rectStrokeWidth = 80;
            const rectStrokeHeight = 80;

            const imageAspectRatio = 300 / 450;
            const imageHeight = rectStrokeHeight * 0.7;
            const imageWidth = imageHeight * imageAspectRatio;
            const imageMarginX = (rectStrokeWidth - imageWidth) / 2 - 1;

            for (let i = 0; i < recommendedImageRefs.current.length; i++) {
              if (i % 3 === 0 && i !== 0) {
                rectStrokeYPos = rectStrokeWidth + rectMargin + 3;
                rectStrokeXPos = rectWidth + rectMargin;
              }

              //Image rect with borders

              if (recommendedModels[i]?.title && recommendedModels[i]?.price) {
                pdf.setFillColor(255, 255, 255);
                pdf.setLineWidth(0.2);
                pdf.rect(
                  rectStrokeXPos,
                  rectStrokeYPos,
                  rectStrokeWidth,
                  rectStrokeHeight,
                  "F"
                );

                const imgElement = recommendedImageRefs.current[i];
                const base64Image = await convertImageToBase64(imgElement);

                pdf.addImage(
                  base64Image,
                  "PNG",
                  rectStrokeXPos + imageMarginX,
                  rectStrokeYPos,
                  imageWidth,
                  imageHeight
                );

                //Title
                pdf.setFont("helvetica", "normal");
                pdf.setTextColor(0, 0, 0);
                pdf.setFontSize(8);
                pdf.text(
                  limitText(recommendedModels[i].title),
                  rectStrokeXPos + 10,
                  rectStrokeYPos + imageHeight + 5
                );

                //Price
                pdf.setFont("helvetica", "bold");
                pdf.setTextColor(209, 53, 50);
                pdf.setFontSize(9);
                pdf.text(
                  `${recommendedModels[i].price} $`,
                  rectStrokeXPos + 10,
                  rectStrokeYPos + imageHeight + 5 + 10
                );

                //Recommended Products Links
                const url = `https://www.eurotechseating.com/${recommendedModels[i].category
                  }/${formatStringForLink(recommendedModels[i].title)}`;
                pdf.link(
                  rectStrokeXPos,
                  rectStrokeYPos,
                  rectStrokeWidth,
                  rectStrokeHeight,
                  { url: url }
                );

                rectStrokeXPos += rectStrokeWidth + 3;
              }
            }
          }
        }

        //Features
        if (adjustment.features.length) {
          const featuresLimit = showNoRect ? 12 : showHalfRect ? 9 : 7;
          let featureXStartPosition = rectWidth + 5;
          let featureYStartPosition = recommendedRectHeight + 15;

          const featureImageAspectRatio = 50 / 50;
          const featureImageHeight = 50;
          const featureImageWidth =
            featureImageHeight * featureImageAspectRatio;
          const imageMarginX = 10;

          for (
            let i = 0;
            i < Math.min(featuresLimit, adjustment.features.length);
            i++
          ) {
            if (i % 2 === 0 && i !== 0) {
              featureXStartPosition = rectWidth + 5;
              featureYStartPosition += featureImageHeight + 15;
            }

            //Feature Title
            pdf.setFont("helvetica", "normal");
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(10);
            const splitText = pdf.splitTextToSize(
              adjustment.features[i].key,
              55
            );
            pdf.text(
              splitText,
              featureXStartPosition + imageMarginX + featureImageWidth + 5,
              featureYStartPosition + 15
            );

            //Feature Description
            pdf.setFont("helvetica", "normal");
            pdf.setTextColor(153, 153, 153);
            pdf.setFontSize(7);
            const splitDescriptionText = pdf.splitTextToSize(
              adjustment.features[i].description,
              55
            );
            pdf.text(
              splitDescriptionText,
              featureXStartPosition + imageMarginX + featureImageWidth + 5,
              featureYStartPosition + 15 + 20
            );

            //Feature Image
            const imgElement = featuresImageRefs.current[i];
            pdf.addImage(
              imgElement,
              "PNG",
              featureXStartPosition + imageMarginX,
              featureYStartPosition,
              featureImageWidth,
              featureImageHeight
            );

            featureXStartPosition += featureImageWidth + 65;
          }
        }
      }

      //Logo
      const logoElement = logoRef.current!;
      const imageLogoAspectRatio = 300 / 56;
      const logoWidth = 80;
      const logoHeight = logoWidth / imageLogoAspectRatio;
      pdf.addImage(
        logoElement,
        "PNG",
        pdfDocumentWidth - 20 - logoWidth,
        pdfDocumentHeight - 20 - logoHeight,
        logoWidth,
        logoHeight
      );

      //Logo Link
      const logoUrl = "https://www.eurotechseating.com/";
      pdf.link(
        pdfDocumentWidth - 20 - logoWidth,
        pdfDocumentHeight - 20 - logoHeight,
        logoWidth,
        logoHeight,
        { url: logoUrl }
      );

      pdf.save(`${formatString(title)}.pdf`);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
    }
  };

  return {
    generatePDF,
    imageRefs,
    recommendedImageRefs,
    featuresImageRefs,
    logoRef,
  };
};
