import React from "react";
import {Grid, Typography} from "@material-ui/core";
import styles from './styles.module.scss';

export const CertificationTab = () => {
  return (
    <Grid className={styles.wrapper} id={"certifications"}>
      <Grid  className={styles.content_wrapper}>
        <Grid  className={styles.description_content}>
          <Typography className={styles.sub_title}>CERTIFICATIONS & MEMBERSHIPS</Typography>
          <Typography className={styles.title}>Greenguard Certified</Typography>
          <Typography className={styles.description}>
            All Eurotech products have been certified by GREENGUARD as meeting the Indoor Air Quality pollutant
            guidelines and standards. The GREENGUARD Environmental Institute is an industryindependent, non-profit
            organization that oversees the GREENGUARD Certification Program (SM), which certifies indoor products
            based on strict chemical emissions criteria. Products are tested and verified to meet the
            stringent GREENGUARD Indoor Air Quality Certified designation. GREENGUARD Certification provides
            third-party assurance that products have been tested for potentially harmful chemical emissions and
            found to meet allowable levels established by recognized public health authorities.
          </Typography>
        </Grid>
        <Grid  className={styles.img_content}>
          <section className={styles.img_container}>
            <img src={"/assets/images/about/greenguard.jpg"} alt=""/>
          </section>
        </Grid>

      </Grid>

    </Grid>
  )
}
