import {Record} from 'immutable';
import {createSelector} from "reselect";
import {all, takeEvery} from 'redux-saga/effects';
import {cloneDeep} from 'lodash';
import {IActionTypes, IStateRecord} from "./module.interfaces/dialog.interface";
import * as actions from "./action.constants/dialog.constants";
import {showDialogSaga} from "./action.generators/dialog.generators";
import {RootState} from "../root.reducer";

export const moduleName = 'dialogs';

const StateRecord: IStateRecord = {
    content: undefined,
    settings: undefined,
};

export const ReducerRecord: Record.Factory<IStateRecord> = Record(cloneDeep(StateRecord));
type RecordType = ReturnType<typeof ReducerRecord>;

export default (state = new ReducerRecord(), action: IActionTypes) => {
    switch (action.type) {
        case actions.SHOW_DIALOG_SUCCESS: {
            return state
                .set('content', action.content)
                .set('settings', action.settings ? {...action.settings} : undefined)
        }
        default: return state;
    }
}

export const stateSelector = (state: RootState): RecordType => state[moduleName];
export const contentSelector = createSelector(stateSelector, state => state.get('content'));
export const settingsSelector = createSelector(stateSelector, state => state.get('settings'));

export const saga = function* () {
    yield all([
        takeEvery(actions.SHOW_DIALOG, showDialogSaga)
    ])
};