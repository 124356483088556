import React from "react";
import styles from './styles.module.scss';
import {Dialog, DialogContent, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {showVideoSelector, videoSelector} from "../../redux/modules/app.module";
import {useDispatch, useSelector} from "react-redux";
import {changeControls} from "../../redux/modules/action.creators/app.actions";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {AssetsLoader} from "../assets.loader";

const useStyles = makeStyles(() => ({
    paper: {
        width: "100%",
        zIndex: 1500,
        maxWidth: 960,
        borderRadius: 10,
        "@media screen and (min-width: 768px) and (max-width: 1280px)": {
            width: "100%",
            maxWidth: 768,
        },
        "@media screen and (min-width: 480px) and (max-width: 768px)": {
            width: "100%",
            maxWidth: 480,
        }
    },
}));

export const VideoPopup = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const video = useSelector(videoSelector);
    const showVideo = useSelector(showVideoSelector);
    const [loading, setLoading] = React.useState(true);

    const handleClose = () => {
        dispatch(changeControls({name: "showVideo", value: false}));
        setLoading(true);
    };

    const handleKeyDown = (e: any) => {
        if ("key" in e) {
            (e.key === "Escape" || e.key === "Esc") && handleClose();
        } else {
            (e.keyCode === 27) && handleClose();
        }
    };

    return (
        <Dialog
            open={showVideo}
            onBackdropClick={handleClose}
            className={styles.video_dialog}
            classes={{paper: classes.paper}}
            onKeyDown={handleKeyDown}
        >
            <DialogContent className={styles.video_wrapper}>
                <section className={styles.preloader} style={!loading ? {display: 'none'} : {}}>
                    <AssetsLoader/>
                </section>
                <div className={styles.video_container}>
                    <div className={styles.title_container}>
                        <Typography className={styles.title}>{video && video.title}</Typography>
                    </div>
                    <div className={styles.close_btn} onClick={handleClose}>
                        <CloseOutlinedIcon/>
                    </div>
                    <video
                        // style={{width: "100%", minHeight: "540px"}}
                        className={styles.video}
                        autoPlay
                        controls
                        src={`https:${video && video.entries}`}
                        onPlaying={() => setTimeout(() => setLoading(false), 200)}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}