import * as actions from '../action.constants/app.constants';
import { FETCH_DISTRIBUTORS, INIT_BANNER_IMAGES } from '../action.constants/app.constants';
import { IStateRecord } from "../module.interfaces/app.interface";
import Model from "../../../types/Model";
import Region from "../../../types/Region";
import Message from "../../../types/Message";
import Tools from "../../../types/ITools";
import FabrixCatalog from "../../../types/FabrixCatalog";
import Partner from "../../../types/Partner";
import { BannerImage } from '../../../types/BannerImage';
import { Grade } from "../../../types/IGrades";

export const changeControls = (payload: { name: keyof IStateRecord, value: any }): { type: typeof actions.CHANGE_CONTROLS, payload: { name: keyof IStateRecord, value: any } } => ({
  type: actions.CHANGE_CONTROLS,
  payload
});

export const changeControlsSuccess = (payload: { name: keyof IStateRecord, value: any }): { type: typeof actions.CHANGE_CONTROLS_SUCCESS, payload: { name: keyof IStateRecord, value: any } } => ({
  type: actions.CHANGE_CONTROLS_SUCCESS,
  payload
});

export const searchRequest = (): { type: typeof actions.SEARCH_REQUEST } => ({
  type: actions.SEARCH_REQUEST
});

export const searchSuccess = (matches: Model[]): { type: typeof actions.SEARCH_SUCCESS, matches: Model[] } => ({
  type: actions.SEARCH_SUCCESS,
  matches
});

export const gsaRequest = (): { type: typeof actions.GSA_REQUEST } => ({
  type: actions.GSA_REQUEST
});

export const gsaSuccess = (response: Model[]): { type: typeof actions.GSA_SUCCESS, response: Model[] } => ({
  type: actions.GSA_SUCCESS,
  response
});

export const fetchAssets = (uuid: string[], key: keyof IStateRecord, constructor: { class: any }): { type: typeof actions.FETCH_ASSETS, uuid: string[], key: keyof IStateRecord, constructor: { class: any } } => ({
  type: actions.FETCH_ASSETS,
  uuid,
  key,
  constructor
});

export const fetchAssetsRequest = (): { type: typeof actions.FETCH_ASSETS_REQUEST } => ({
  type: actions.FETCH_ASSETS_REQUEST
});

export const fetchAssetsSuccess = (payload: { name: keyof IStateRecord, value: any }): { type: typeof actions.FETCH_ASSETS_SUCCESS, payload: { name: keyof IStateRecord, value: any } } => ({
  type: actions.FETCH_ASSETS_SUCCESS,
  payload
});

export const fetchAssetsFailure = (error: string): { type: typeof actions.FETCH_ASSETS_FAILURE, error: string } => ({
  type: actions.FETCH_ASSETS_FAILURE,
  error
});

export const fetchDistributors = (): { type: typeof actions.FETCH_DISTRIBUTORS } => ({
  type: FETCH_DISTRIBUTORS
});

export const fetchDistributorsRequest = (): { type: typeof actions.FETCH_DISTRIBUTORS_REQUEST } => ({
  type: actions.FETCH_DISTRIBUTORS_REQUEST,
});

export const fetchDistributorsSuccess = (regions: Region[]): { type: typeof actions.FETCH_DISTRIBUTORS_SUCCESS, regions: Region[] } => ({
  type: actions.FETCH_DISTRIBUTORS_SUCCESS,
  regions
});

export const fetchDistributorsFailure = (error: string): { type: typeof actions.FETCH_DISTRIBUTORS_FAILURE, error: string } => ({
  type: actions.FETCH_DISTRIBUTORS_FAILURE,
  error
});

export const sendMail = (publication: Message): { type: typeof actions.SEND_MAIL, publication: Message } => ({
  type: actions.SEND_MAIL,
  publication
});

export const sendMailRequest = (): { type: typeof actions.SEND_MAIL_REQUEST } => ({
  type: actions.SEND_MAIL_REQUEST,
});

export const sendMailSuccess = (): { type: typeof actions.SEND_MAIL_SUCCESS } => ({
  type: actions.SEND_MAIL_SUCCESS,
});


export const sendMailFailure = (error: string): { type: typeof actions.SEND_MAIL_FAILURE, error: string } => ({
  type: actions.SEND_MAIL_FAILURE,
  error
});

export const fetchTools = (): { type: typeof actions.FETCH_TOOLS } => ({
  type: actions.FETCH_TOOLS
});

export const fetchToolsRequest = (): { type: typeof actions.FETCH_TOOLS_REQUEST } => ({
  type: actions.FETCH_TOOLS_REQUEST,
});

export const fetchToolsSuccess = (tools: Tools): { type: typeof actions.FETCH_TOOLS_SUCCESS, tools: Tools } => ({
  type: actions.FETCH_TOOLS_SUCCESS,
  tools
});

export const fetchToolsFailure = (error: string): { type: typeof actions.FETCH_TOOLS_FAILURE, error: string } => ({
  type: actions.FETCH_TOOLS_FAILURE,
  error
});

//TODO write method for contracts links
export const fetchContracts = (): { type: typeof actions.FETCH_CONTRACTS } => ({
  type: actions.FETCH_CONTRACTS
});

export const fetchContractsRequest = (): { type: typeof actions.FETCH_CONTRACTS_REQUEST } => ({
  type: actions.FETCH_CONTRACTS_REQUEST,
});

export const fetchContractsSuccess = (tools: Tools): { type: typeof actions.FETCH_CONTRACTS_SUCCESS, tools: Tools } => ({
  type: actions.FETCH_CONTRACTS_SUCCESS,
  tools
});

export const fetchContractsFailure = (error: string): { type: typeof actions.FETCH_CONTRACTS_FAILURE, error: string } => ({
  type: actions.FETCH_CONTRACTS_FAILURE,
  error
});

export const fetchFabrixCatalog = (): { type: typeof actions.FETCH_FABRIX_CATALOG } => ({
  type: actions.FETCH_FABRIX_CATALOG
});

export const fetchFabrixCatalogRequest = (): { type: typeof actions.FETCH_FABRIX_CATALOG_REQUEST } => ({
  type: actions.FETCH_FABRIX_CATALOG_REQUEST,
});

export const fetchFabrixCatalogSuccess = (fabrixCatalog: FabrixCatalog): { type: typeof actions.FETCH_FABRIX_CATALOG_SUCCESS, fabrixCatalog: FabrixCatalog } => ({
  type: actions.FETCH_FABRIX_CATALOG_SUCCESS,
  fabrixCatalog
});

export const fetchFabrixCatalogFailure = (error: string): { type: typeof actions.FETCH_FABRIX_CATALOG_FAILURE, error: string } => ({
  type: actions.FETCH_FABRIX_CATALOG_FAILURE,
  error
});

export const initPartnerList = (data: any): { type: typeof actions.INIT_PARTNER_LIST, data: any } => ({
  type: actions.INIT_PARTNER_LIST,
  data
});

export const initPartnerListRequest = (): { type: typeof actions.INIT_PARTNER_LIST_REQUEST } => ({
  type: actions.INIT_PARTNER_LIST_REQUEST,
});

export const initPartnerListSuccess = (payload: Partner[]): { type: typeof actions.INIT_PARTNER_LIST_SUCCESS, payload: Partner[] } => ({
  type: actions.INIT_PARTNER_LIST_SUCCESS,
  payload
});

export const initPartnerListFailure = (error: string): { type: typeof actions.INIT_PARTNER_LIST_FAILURE, error: string } => ({
  type: actions.INIT_PARTNER_LIST_FAILURE,
  error
});

export const setActiveGrade = (payload: Grade): { type: typeof actions.SET_ACTIVE_GRADE, payload: Grade } => ({
  type: actions.SET_ACTIVE_GRADE,
  payload
})

export const initBannerImages = (data: any): { type: typeof actions.INIT_BANNER_IMAGES, data: any } => ({
  type: actions.INIT_BANNER_IMAGES,
  data
});

export const initBannerImagesRequest = (): { type: typeof actions.INIT_BANNER_IMAGES_REQUEST } => ({
  type: actions.INIT_BANNER_IMAGES_REQUEST,
});

export const initBannerImagesSuccess = (payload: BannerImage[]): { type: typeof actions.INIT_BANNER_IMAGES_SUCCESS, payload: BannerImage[] } => ({
  type: actions.INIT_BANNER_IMAGES_SUCCESS,
  payload
});

export const initBannerImagesFailure = (error: string): { type: typeof actions.INIT_BANNER_IMAGES_FAILURE, error: string } => ({
  type: actions.INIT_BANNER_IMAGES_FAILURE,
  error
});
