import React from "react";
import styles from "./styles.module.scss";
import Routes from "../../app.router/router.constants";
import {Grid, Typography} from "@material-ui/core";
import {HashLink as Link} from "react-router-hash-link";
import Contentful from "../../utils/app.helpers/contentful";

export const Footer = () => {
    const [subscribeLink, setSubscibeLink] = React.useState<string>('');

    React.useEffect(() => {
        const _instance = Contentful.getInstance();
        _instance.client.getEntry('359LIn2PBkxto2LdhtQsbD').then((res: any) => {
        setSubscibeLink(res.fields.link);
        })
  }, []);
    return (
        <Grid container className={styles.wrapper}>
            <Grid className={styles.content_wrapper}>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    className={styles.eurotech_container}
                >
                    <Grid  className={styles.logo}>
                        <img src={"/assets/images/footer_logo.png"} alt=""/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={styles.info_container}
                    >
                        <Typography className={styles.text}>
                            <span>525 Hempstead Turnpike </span>
                            <span>West Hempstead, NY 11552</span>
                        </Typography>
                        <Typography className={styles.info}>
                            <span className={styles.info__item}>800.637.0005</span>
                            <span className={styles.info__item}>info@eurotechseating.com</span>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid  className={styles.columns_container}>
                    <Grid  className={styles.column}>
                        <Typography className={styles.title}>Policies</Typography>

                        <Grid className={styles.link_container}>
                            <Link
                                to={`${Routes.PoliciesPage}#warranty`}
                                scroll={() => window.scrollTo({top: 0, behavior: "smooth"})}
                                className={styles.link}
                            >
                                Warranty Information
                            </Link>
                            <Link
                                to={`${Routes.PoliciesPage}#lume`}
                                scroll={(el) => el.scrollIntoView({behavior: "smooth"})}
                                className={styles.link}
                            >
                                Lume Recall Information
                            </Link>
                            <Link
                                to={`${Routes.PoliciesPage}#ship`}
                                scroll={(el) => el.scrollIntoView({behavior: "smooth"})}
                                className={styles.link}
                            >
                                24-Hour Quick Ship
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid  className={styles.column}>
                        <Typography className={styles.title}>Social Media</Typography>

                        <Grid className={styles.link_container}>
                            <a
                                className={styles.link}
                                target="_blank"
                                href="https://twitter.com/eurotechseating"
                            >
                                Twitter
                            </a>
                            <a
                                className={styles.link}
                                target="_blank"
                                href="https://www.instagram.com/eurotechseating/"
                            >
                                Instagram
                            </a>
                            <a
                                className={styles.link}
                                target="_blank"
                                href="https://www.facebook.com/eurotechseating"
                            >
                                Facebook
                            </a>
                        </Grid>
                    </Grid>

                    <Grid  className={styles.column}>
                        <Typography className={styles.title}>Contact</Typography>

                        <Grid className={styles.link_container}>
                            <Link
                                to={Routes.Contact}
                                scroll={() => window.scrollTo({top: 0, behavior: "smooth"})}
                                className={styles.link}
                            >
                                Contact Us
                            </Link>
                            <Link to={`${Routes.Contact}/?q=representative`} className={styles.link}>
                                Find a Representative
                            </Link>
                            <a className={styles.link} onClick={() => window.open(subscribeLink, '_blank').focus()}>
                                Subscribe To Our Newsletters
                            </a>
                        </Grid>
                    </Grid>

                    <Grid  className={styles.column}>
                        <Typography className={styles.title}>Privacy Policy</Typography>
                        <Grid className={styles.link_container}>
                            <Link
                                to={`${Routes.GsaAdvantage}`}
                                scroll={() => window.scrollTo({top: 0, behavior: "smooth"})}
                                className={styles.link}
                            >
                                GSA Advantage
                            </Link>
                            <Link
                                to={`${Routes.PrivacyPolicy}`}
                                scroll={() => window.scrollTo({top: 0, behavior: "smooth"})}
                                className={styles.link}
                            >
                                Privacy Policy
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className={styles.copyright_container}>
                <Typography className={styles.copyright}>
                    © 2020, The Raynor Group Companies. All rights reserved.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Footer;
