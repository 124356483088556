export default class FabrixCatalog {
    title: string;
    subTitle: string;
    farbixCategories: FabrixCategory[];

    constructor(data: any = {}) {
        this.title = data.fields.title;
        this.subTitle = data.fields.subtitle;
        this.farbixCategories = data.fields.fabrix_categories ? data.fields.fabrix_categories
            .filter((category: any) => !!category.fields)
            .map((el: any) => new FabrixCategory(el)) : undefined;
    }
}

export class FabrixCategory {
    title: string;
    fabrixList: any;

    constructor(data: any = {}) {
        this.title = data.fields.title;
        this.fabrixList = data.fields.fabrix;
        this.fabrixList = data.fields.fabrix ? data.fields.fabrix.filter((fabrix: any) => !!fabrix.fields).map((el: any) => new Fabrix(el)) : undefined;
    }
}

export class Fabrix {
    title: string;
    preview: string;
    overview: string;

    constructor(data: any = {}) {
        this.title = data.fields.title;
        this.preview = data.fields.preview.fields.file ? data.fields.preview.fields.file.url : undefined;
        this.overview = data.fields.overview.fields.file ? data.fields.overview.fields.file.url : undefined;
    }
}
