import React from 'react';
import {Grid, Typography} from "@material-ui/core";

import styles from './styles.module.scss';
import { ContactUs } from '../../pages/contact/contact.us'

export const ContactArticle = () => {
    return (
        <Grid container className={styles.wrapper}>
            <Grid item xs={12} className={styles.content}>
                <article className={styles.content_wrapper}>
                    <Typography variant="h2" className={styles.title}>
                        Contact your Eurotech representative to get started.
                    </Typography>
                    <Typography variant="caption" className={styles.description}>
                        A great product means nothing without excellent service to back it up. We stand by our products, but we
                        also believe in offering customized support for our customers. Work closely, one-on-one, with a
                        dedicated representative so you’re guaranteed to get the perfect chair.
                    </Typography>
                </article>
                <ContactUs/>
            </Grid>

        </Grid>
    )
};
