import Seating from './Seating'

import { checkIsPublish, fetchFabrixCatalogSaga } from "../redux/modules/action.generators/app.generators";
import Contentful from '../utils/app.helpers/contentful';




export default class Model extends Seating {
  price: number
  priceDescription?: string
  surcharge?: boolean
  sku?: string
  keyWords?: string
  gsa?: boolean
  configurable?: boolean
  object?: string
  category: string
  buyNow?: string
  isFabrix?: boolean
  isDiscounted?: boolean
  discountValue?: number
  accessories?: Model[] | any;
  adjustment?: any;
  gradeDescription?: string;
  fabrixExpressColors?: boolean;
  additionalFabrixOptions?: boolean;
  fabrixExpressPdf?: string | any;
  video?: string | null;

  constructor(data: any = {}) {

    super(data)
    this.price = data.fields.price ? data.fields.price : 0
    this.video = data.fields.video ? data.fields.video.fields.file.url : null
    this.priceDescription = data.fields.price_description ? data.fields.price_description : undefined
    this.surcharge = data.fields.surcharge ? data.fields.surcharge : undefined
    this.sku = data.fields.sku ? data.fields.sku : undefined
    this.keyWords = data.fields.key_words ? data.fields.key_words : undefined
    this.gsa = data.fields.gsa ? data.fields.gsa : undefined
    this.configurable = data.fields.configurable ? data.fields.configurable : undefined
    this.object = data.fields.object ? data.fields.object.fields.file.url : undefined
    this.category = data.fields.category
    this.buyNow = data.fields.buy_now ? data.fields.buy_now : undefined
    this.isFabrix = data.fields.is_fabrix ? data.fields.is_fabrix : undefined
    this.isDiscounted = data.fields.is_discounted ? data.fields.is_discounted : undefined
    this.discountValue = data.fields.discount_value ? data.fields.discount_value : undefined
    this.accessories = data.fields.accessories ? checkIsPublish(data.fields.accessories, Model) : undefined;
    this.adjustment = data.fields.entries ? data.fields.entries : undefined;
    this.gradeDescription = data.fields.gradeDescription ? data.fields.gradeDescription : undefined;
    this.fabrixExpressColors = data.fields.fabrixExpressColors ? data.fields.fabrixExpressColors : undefined;
    this.additionalFabrixOptions = data.fields.additionalFabrixOptions ? data.fields.additionalFabrixOptions : undefined;
  }
}
