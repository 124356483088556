import React from 'react'
import styles from './styles.module.scss'

interface ISpecificationsList {
  specifications: {},
}

const Specifications = ({ specifications } : ISpecificationsList) => {
  return (
    <ul className={styles.specifications}>
      {
        specifications && Object.keys(specifications).map((key, index) => (
          <li className={styles.specifications_item} key={index}>
                      <span className={styles.specifications_title}>
                        {key.split('_').join(' ')}:&nbsp;
                      </span>
            {specifications[key as keyof typeof specifications]}
          </li>
        ))
      }
    </ul>
  )
}

export default Specifications