import React from 'react';

import {Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import styles from './styles.module.scss';
import { imageCompressConstants } from '../../../constants/imageCompressConstants'

interface IMapProps {
  category: string,
  heading?: string,
  description?: string,
  preview?: string
}

export const SBannerComponent: React.FC<IMapProps> = ({
                                                        category,
                                                        heading,
                                                        description,
                                                        preview
                                                      }) => {

    return (
    <Grid container className={`${styles.wrapper} ${styles[category.toLowerCase().split(' ').join('_')]}`}
          style={preview ?
            {background: `#222222 url(https:${preview}${imageCompressConstants.categoriesBanner}) no-repeat`}
            :
            {background: `#222222`}}>
      <div className={styles.container}>
        <Grid item xs={12} className={styles.content}>
          <Typography variant='h6' className={styles.label}>
            {category}
          </Typography>
          <Typography variant="h2" className={styles.heading}>
            {heading}
          </Typography>
          <Typography variant="caption" className={styles.description}>
            {description}
          </Typography>
        </Grid>
        <section className={styles.scroll_link}>
          <Typography variant="caption" className={styles.scroll_link_title}>
            Scroll For More
          </Typography>
          <ExpandMoreIcon className={styles.scroll_link_icon}/>
        </section>
      </div>

    </Grid>
  )
}
