import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { SBannerComponent } from './banner.component'
import Footer from '../../components/footer.component'
import { Products } from '../categories/product.catalog'
import { partnerListSelector } from '../../redux/modules/app.module'
import { ContactArticle } from '../../components/contact.article.component'
import { getDiscountedChairs } from '../../utils/app.helpers/contentful.requests'

interface IMapProps {
}

export const PartnerPage: React.FC<IMapProps> = () => {
  //@ts-ignore
  let { uid } = useParams()

  const partnerList = useSelector(partnerListSelector)
  const [ selectedPartner, setSelectedPartner ] = useState(undefined)
  const [ models, setModels ] = useState(undefined)

  useEffect(() => {
    if ( partnerList && uid ) {
      const selectModel = partnerList.find(partner => partner.partnerName.split(' ').join('_').toLowerCase() === uid.toLowerCase())
      selectModel && setSelectedPartner(selectModel)
      selectModel.products && setModels(selectModel.products)
    }
  }, [ partnerList, uid ])

  return (
    <React.Fragment>
      { selectedPartner ? <>
        <Helmet>
          <title>{ selectedPartner.partnerName + ' | Eurotech' }</title>
          <meta name="description" content={ selectedPartner.description }/>
        </Helmet>
        <SBannerComponent
          heading={ selectedPartner.partnerName }
          description={ selectedPartner.description }
          preview={ selectedPartner.preview }
          scroll={ !!models }
        />

        <Products models={ models } category={ selectedPartner.partnerName } isPartner={ true } isDiscount={ uid.includes('discontinued') }/>

        { models ? <ContactArticle/> : null }
        <Footer/>
      </> : null }
    </React.Fragment>
  )
}
