import React from 'react'
import styles from './styles.module.scss'
import Adjustment from "../../../../../types/Adjustment";
import { formatterPrice } from "../../../../../utils/app.helpers/price.formatter";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

interface IPriceComponent {
  adjustment: Adjustment,
  price: number
}

const PriceComponent = ( { adjustment, price }: IPriceComponent ) => {
  return adjustment ? (
    <div className={styles.wrapper}>
      <div className={styles.price}>
        <span className={styles.price__title}>Price:</span>
        <span className={styles.price__value}>{formatterPrice(price)}</span>
      </div>
      {adjustment && adjustment.product_sheet ? (
        <div className={styles.download}>
          <a href={`https:${adjustment.product_sheet}`} target="_blank"
             className={styles.download_link}>Download
            Product Sheet <ExpandMoreIcon className={styles.download_icon}/></a>
        </div>
      ) : null}
    </div>

  ) : null
}

export default PriceComponent