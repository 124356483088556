import React from "react";
import {Helmet} from 'react-helmet';
import * as QueryString from "query-string"
import SwipeableViews from "react-swipeable-views";
import {Box, Grid, Tab, Tabs, useTheme, withStyles} from "@material-ui/core";
import {ContactUs} from "./contact.us";
import {ContactTab} from "./contact.tab";
import {RepresentativeComponent} from "../../components/representative.component";
import Footer from "../../components/footer.component";
import {useDispatch, useSelector} from "react-redux";
import {fetchDistributors} from "../../redux/modules/action.creators/app.actions";
import styles from './styles.module.scss';
import {regionsSelector} from "../../redux/modules/app.module";
import {AssetsLoader} from "../../components/assets.loader";

interface TabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

const TabController = withStyles({
    root: {
        justifyContent: 'flex-start',
        padding: 0,
        borderBottom: '1px solid #fafafa',
    },
    indicator: {
        height: '1px',
    }
})((props: TabsProps) => <Tabs variant="standard" {...props} />);

const TabButton = withStyles({
    root: {
        width: '150px',
        textTransform: 'capitalize',
        fontFamily: 'Muli-SemiBold, sans-serif',
        fontSize: '13px',
        lineHeight: '30px',
        letterSpacing: '0.13px',
        color: '#444',
        opacity: 1,
        backgroundColor: '#fff',
        '&:hover': {
            color: '',
            opacity: 1,
        },
        '&:focus': {
            color: '#D13532'
        },
    },
    selected: {
        backgroundColor: '#fff',
        color: '#D13532'
    }
})(Tab);

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <section className={styles.panel}
                 role="panel"
                 hidden={value !== index}
                 id={`panel-${index}`}
                 aria-labelledby={`tab-${index}`}
                 {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </section>
    );
}

export const ContactPage = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [active, setActive] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const regions = useSelector(regionsSelector);

    React.useEffect(() => {
        const params = QueryString.parse(window.location.search);
        params && params.q && params.q === "representative" ? setActive(1) : setActive(0);
    }, [window.location.search]);

    React.useEffect(() => {
        if (!regions) dispatch(fetchDistributors());
        regions && setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, []);

    const handleChangeActiveTab = (event: React.ChangeEvent<{}>, _value: number) => {
        setActive(_value);
    };

    const handleChangeIndex = (index: number) => {
        setActive(index);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{"Contact | Eurotech"}</title>
                <meta name="description" content={"Contact your Eurotech representative to get started."}/>
            </Helmet>

            <Grid container id={'contact'} className={styles.wrapper}>
                <Grid item xs={12} md={12} className={styles.tabs_controller_wrapper}>
                    <TabController
                        value={active}
                        onChange={handleChangeActiveTab}
                        aria-label="controller"
                        classes={{root: styles.controller}}
                    >
                        <TabButton className={styles.controller_Btn} selected label="Contact"/>
                        <TabButton className={styles.controller_Btn} label="Representative"/>
                    </TabController>
                </Grid>
                <Grid item xs={12} md={12} className={styles.tab_panel_wrapper}>
                    {
                        loading ? (
                            <section className={styles.preloader}>
                                <AssetsLoader/>
                            </section>
                        ) : (
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={active}
                                onChangeIndex={handleChangeIndex}
                            >

                                <TabPanel index={0} value={active} dir={theme.direction}>
                                        <ContactTab/>
                                        <div className={styles.contact_wrapper}>
                                            <ContactUs/>
                                        </div>
                                        <Footer/>
                                </TabPanel>
                                <TabPanel index={1} value={active} dir={theme.direction}>
                                    <RepresentativeComponent regions={regions}/>
                                </TabPanel>
                            </SwipeableViews>
                        )
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
