import React from 'react';
import {HashLink as Link} from "react-router-hash-link";
import styles from './styles.module.scss';
import {Typography} from "@material-ui/core";

interface ISedebarProps {
    categories: string[];
}

export const SidebarCategories: React.FC<ISedebarProps> = ({categories}) => {
    const [selected, setSelected] = React.useState('');

    return (
        <div className={styles.wrapper} >
            <Typography className={styles.title}>Quick navigation</Typography>

            <div className={styles.list_container}>

                {
                    categories.map((category) => {
                        return (
                            <Link
                                key={category}
                                to={`#${category}`}
                                scroll={(el) => el.scrollIntoView({block: 'start', behavior: "smooth"})}
                                onClick={() => setSelected(category)}
                                className={styles.link}
                            >
                                <Typography
                                    className={selected === category ? styles.category_selected : styles.category}
                                >
                                    {category}
                                </Typography>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}