import React from 'react';
import styles from './styles.module.scss';
import { Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { imageCompressConstants } from '../../../constants/imageCompressConstants';

interface IMapProps {
  heading?: string,
  description?: string,
  preview?: string,
  scroll?: boolean
}

export const SBannerComponent: React.FC<IMapProps> = ({
                                                        heading,
                                                        description,
                                                        preview,
                                                        scroll,
                                                      }) => {

  return (
    <Grid container className={`${styles.wrapper}`}
          style={{ background: `#FFFFFF url(https:${preview}${imageCompressConstants.productBannerThumb}) no-repeat` }}>
      <Grid item xs={12} className={styles.content}>
        <Typography variant="h2" className={styles.heading}>
          {heading}
        </Typography>
        <Typography variant="caption" className={styles.description}>
          {description}
        </Typography>
        <img src={`https:${preview}${imageCompressConstants.productBannerThumb}`} className={styles.img_container} alt={'preview'}/>
        {scroll ? <section className={styles.scroll_link}>
          <Typography variant="caption" className={styles.scroll_link_title}>
            Scroll For More
          </Typography>
          <ExpandMoreIcon className={styles.scroll_link_icon}/>
        </section> : null}
      </Grid>
    </Grid>
  );
};
