import React, { useRef, useState } from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from "react-redux";
import {
  fabricSelector,
  fullscreenSelector,
} from '../../redux/modules/customizer.module'
import {Transition} from 'react-transition-group';
import Grid from '@material-ui/core/Grid';
import {isMobile} from 'react-device-detect';

import {ICustomizerProps} from "./customizer.container";
import SceneViewer from "../../components/scene.controller";
import styles from './styles.module.scss';

import LeftSidebar from './left.sidebar'
import RightSidebar from './right.sidebar'
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { useHeight } from "../../hooks/utils.hooks";

interface ITranstion {
  maxWidth?: string | number
  height?: string | number
  margin?: string | number
}

export const CustomizerViewer: React.FC<ICustomizerProps> = ({
                                                               sequences,
                                                               sequence,
                                                               model,
                                                               adjustment,
                                                               options,
                                                               fabric,
                                                               saving,
                                                               changeControls
                                                             }) => {
  const fullscreen = useSelector(fullscreenSelector);

  const ref = useRef<HTMLDivElement>(null);

  const [price, setPrice] = React.useState<number>(0);
  const [activePreview, setActivePreview] = useState(false as boolean)


  const { height } = useResizeObserver({ ref });
  const fabrics = useSelector(fabricSelector)

  React.useEffect(() => {
    if (sequence?.type === "preview") {
      changeControls({name: 'fullscreen', value: true})
    }
  }, [sequence]);

  React.useEffect(() => {
    return () => {
      setPrice(0)
    }
  }, []);

  React.useEffect(() => {
    if(fabrics) {
      if (fabrics.price) {
        setPrice(+fabrics.price);
      } else {
        if (adjustment && adjustment.grades && fabrics.gradeTitle) {
          const selectPrice = adjustment.grades.grades.find((el: any) => el.title.toLowerCase() === fabrics.gradeTitle.toLowerCase());
          const validPrice = Number(selectPrice.price.split(',').join(''))

          setPrice(validPrice);
        } else if (adjustment.modifications && fabrics.gradeTitle) {
          const gradeSet = adjustment.modifications.filter((el) => el.grade);
          const selectPrice = gradeSet[0].grade.find((el: any) => el.title.toLowerCase() === fabrics.gradeTitle.toLowerCase());
          const validPrice = Number(selectPrice.price.split(',').join(''))

          setPrice(validPrice);
        } else {
          setPrice(+adjustment.price);
        }
      }
    }
  }, [fabrics]);


  const optionsTransitionController = ( ) => {
    if(isMobile) {
      const transition = {
        unmounted: { height: '100%'},
        entering: { height: '100%'},
        entered: {  height: '100%'},
        exiting: { height: 0, margin: 0 } as ITranstion,
        exited: { height: 0, margin: 0} as ITranstion
      }
      if(height < 550) {
        transition.exiting = { ...transition.exiting, height: '100%', maxWidth: '0%' }
        transition.exited = { ...transition.exited, height: '100%', maxWidth: '0%' }
      }
      return transition
    }
    return {
      unmounted: { flex: 1},
      entering: { flex: 1},
      entered: {  flex: 1},
      exiting: { flex: 0},
      exited: { flex: 0}
    }
  }
  const heightWindow = useHeight()
  return (
    <React.Fragment>
      <Helmet>
        <title>{"Configurator | Eurotech"}</title>
        <meta name="description" content={"Create your own custom chair."} />
      </Helmet>
      <Grid ref={ref} container className={styles.root}
            style={fullscreen ? {height: '100vh'} : {}}>
        <Grid container direction="row" className={styles.container}>
          <Transition timeout={350} in={!fullscreen}>
            {state => (
                <div className={styles.left_sidebar} style={{...optionsTransitionController()[state]}}>
                  {options && sequence && (
                      <LeftSidebar
                        model={model}
                        adjustment={adjustment}
                        options={options}
                        setActivePreview={setActivePreview}
                        activePreview={activePreview}
                      />
                    )}
                </div>
              )}
          </Transition>
          <Grid className={styles.scene}>
            <SceneViewer
              model={model}
              adjustment={adjustment}
              fabric={fabric}
              saving={saving}
              changeControls={changeControls}
            />
          </Grid>
          <Transition timeout={350} in={!fullscreen}>
            {state => (
                <div className={styles.right_sidebar} style={{...optionsTransitionController()[state]}}>
                  <RightSidebar adjustment={adjustment} price={price} activePreview={activePreview}/>
                </div>

              )}
          </Transition>
        </Grid>
      </Grid>
    </React.Fragment>
  )
};
