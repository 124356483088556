import React from "react";
import Grid from "@material-ui/core/Grid";
import styles from "./styles.module.scss";
import { Typography } from "@material-ui/core";
import "pure-react-carousel/dist/react-carousel.es.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { imageCompressConstants } from "../../../../constants/imageCompressConstants";
import Contentful from "../../../../utils/app.helpers/contentful";
import gsap from "gsap";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation } from "swiper";
import useWindowWidth from "../../../../hooks/useWindowWidth";
//@ts-ignore
SwiperCore.use([Navigation]);

export const HBannerComponent = () => {
  const [slides, setSlides] = React.useState<any[]>([]);
  const [swiper, setSwiper] = React.useState<SwiperCore | null>(null);
  const [activeSlide, setActiveSlide] = React.useState(1);

  React.useEffect(() => {
    const _instance = Contentful.getInstance();
    _instance.client.getEntry("5r8OeZ2k96xBAT3jkhk8GE").then((res: any) => {
      setSlides(res.fields.images);
    });
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousemove", parallax);
    function parallax(event: MouseEvent) {
      const x = (window.innerWidth - event.pageX) / 60;
      const y = (window.innerHeight - event.pageY) / 60;
      const tl = gsap.timeline();
      tl.add("play").set(".image", {
        x: x * 1.2,
        y: y * 1.2,
      });
      tl.to(
        "#container",
        {
          x: x,
          y: y,
        },
        "play"
      );
    }

    return () => {
      document.removeEventListener("mousemove", parallax);
    };
  }, []);

  React.useEffect(() => {
    if (swiper) {
      //@ts-ignore
      swiper.slideTo(activeSlide, 300);
    }
  }, [activeSlide, swiper]);

  const handleNavigation = (num: number) => {
    setActiveSlide((prev) => {
      return prev + num;
    });
  };


  const width = useWindowWidth();

  const spacing = React.useMemo(() => {
    const spacingObj = {
      between: 40,
      perView: 1.4,
    };
    if (width > 480) {
      spacingObj.between = 100;
      spacingObj.perView = 1.8;
    }
    if (width > 1280) {
      spacingObj.between = 300;
      spacingObj.perView = 2.1;
    }

    return spacingObj;
  }, [width]);

  return (
    <div className={styles.main_wrapper}>
      <Grid container className={styles.wrapper}>
        <div className={styles.title_overlay}>
          <Grid className={styles.content}>
            <Typography variant="h3" className={styles.hint}>
              Sit smarter with Eurotech Ergonomic Office Chairs
            </Typography>
            <Typography variant="h1" className={styles.title}>
              You can't sit in two chairs at once.
              <br />
              But you can sit in the <b>smartest</b> chair in the house.
            </Typography>
          </Grid>
        </div>
      </Grid>
      <div className={styles.slider_container}>
        <div className={[styles.buttonBack, activeSlide === 0 ? styles.hidden : ''].join(' ')}>
          <svg
            onClick={() => handleNavigation(-1)}
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.2929 44.2929C40.9024 44.6834 40.9024 45.3166 41.2929 45.7071L47.6569 52.0711C48.0474 52.4616 48.6805 52.4616 49.0711 52.0711C49.4616 51.6805 49.4616 51.0474 49.0711 50.6569L43.4142 45L49.0711 39.3431C49.4616 38.9526 49.4616 38.3195 49.0711 37.9289C48.6805 37.5384 48.0474 37.5384 47.6569 37.9289L41.2929 44.2929ZM44 44L42 44L42 46L44 46L44 44Z"
              fill="white"
            />
            <rect
              x="89.5"
              y="89.5"
              width="89"
              height="89"
              rx="44.5"
              transform="rotate(-180 89.5 89.5)"
              stroke="white"
            />
          </svg>
        </div>
        <div className={[styles.buttonNext, activeSlide === slides.length - 1 ? styles.hidden : ''].join(' ')}>
          <svg
            onClick={() => handleNavigation(1)}
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.7071 45.7071C49.0976 45.3166 49.0976 44.6834 48.7071 44.2929L42.3431 37.9289C41.9526 37.5384 41.3195 37.5384 40.9289 37.9289C40.5384 38.3195 40.5384 38.9526 40.9289 39.3431L46.5858 45L40.9289 50.6569C40.5384 51.0474 40.5384 51.6805 40.9289 52.0711C41.3195 52.4616 41.9526 52.4616 42.3431 52.0711L48.7071 45.7071ZM46 46L48 46L48 44L46 44L46 46Z"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="89"
              height="89"
              rx="44.5"
              stroke="white"
            />
          </svg>
        </div>
        <div className={styles.overlay} />
        {slides && slides.length !== 0 ? (
          <Swiper
            id="container"
            spaceBetween={spacing.between}
            slidesPerView={spacing.perView}
            centeredSlides={true}
            className={styles.slider}
            onSwiper={(swiper: SwiperCore) => {
              setSwiper(swiper);
            }}
            onActiveIndexChange={() =>
              setActiveSlide(swiper ? swiper?.activeIndex : 1)
            }
          >
            {slides.map((el, iterator) => (
              <SwiperSlide key={el.fields.link + iterator}>
                <div
                  className={[
                    styles.itemWrapper,
                    activeSlide === iterator ? styles.active : "",
                  ].join(" ")}
                >
                  <div className={styles.slider__overlay} />
                  <div
                    className={styles.slider__item}
                    onClick={() => {
                      if (activeSlide === iterator) {
                        window.open(el.fields.link, "_blank").focus();
                      }
                    }}
                  >
                    <div className={styles.text}>
                      <p className={styles.title}>{el.fields.title}</p>
                      <p className={styles.subtitle}>{el.fields.subtitle}</p>
                    </div>
                    <div
                      className={[styles.slider__item_inner, "image"].join(" ")}
                      style={{
                        backgroundImage: `url(${el.fields.image.fields.file.url})`,
                      }}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          ""
        )}
      </div>
      <section className={styles.scroll_link}>
        <span className={styles.scroll_link_title}>Scroll For More</span>
        <ExpandMoreIcon className={styles.scroll_link_icon} />
      </section>
    </div>
  );
};
