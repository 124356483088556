export default class Modifications {
    title: string;
    priceDescription?: string;
    sku: string;
    price: string;
    option?: Options[];
    grade?: Grades[];

    constructor(data: any = {}) {
        this.title = data.title;
        this.priceDescription = data.price_description ? data.price_description : undefined;
        this.sku = data.sku;
        this.price = data.price;
        this.option = data.options ? data.options.map((el: any) => new Options(el)) : undefined;
        this.grade = data.grades ? data.grades.map((el: any) => new Grades(el)) : undefined;
    }
};

class Options {
    title: string;
    sku: string;
    price: string;

    constructor(data: any = {}) {
        this.title = data.title;
        this.sku = data.sku;
        this.price = data.price;
    }
}

class Grades {
    title: string;
    price: string;

    constructor(data: any = {}) {
        this.title = data.title;
        this.price = data.price;
    }
}