import React from 'react'
import styles from "../banner.component/styles.module.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { imageCompressConstants } from "../../../constants/imageCompressConstants";
import './index.css'
import CloseIcon from "@material-ui/icons/Close";


interface IAccessoriesProps {
  selectedAccessories: any,
  setSelectedAccessories: any
}

const AccessoriesSelected = ( { selectedAccessories, setSelectedAccessories }: IAccessoriesProps ) => {
  const selectAccessories = (item: any) => {
    const key = item.sku

    if (selectedAccessories && selectedAccessories.hasOwnProperty(key)) {
      const res = {...selectedAccessories}

      delete res[key]
      setSelectedAccessories(res)

      return
    }
  }

  return (
    <div className={styles.model_accessoriesList}>
      <TransitionGroup className={styles.model_accessoriesList}>
        {selectedAccessories && Object.values(selectedAccessories).map(( item: any ) => (
          <CSSTransition
            key={item.id}
            timeout={300}
            classNames="item"
          >
            <div className={styles.model_accessoriesItem} key={item.id} onClick={() => selectAccessories(item)}>
              <img src={`https:${item.thumbnail}${imageCompressConstants.accessoriesPreview}`} alt=""/>
              <CloseIcon className={styles.model_accessoriesRemove} fontSize="inherit"/>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}
export default AccessoriesSelected