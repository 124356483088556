import React from 'react';
import styles from "./styles.module.scss";
export const AssetsLoader: React.FC = () => {
    return (
        <div className={styles.wrapper} onClick={(event) => event.stopPropagation()}>
            <div className={styles.loader}>
                <div className={styles.element} />
                <div className={styles.element} />
                <div className={styles.element} />
                <div className={styles.large_square} />
            </div>
        </div>
    )
};