import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Routes from "../../../../app.router/router.constants";
import {LinkButton} from "../../../../components/buttons";

import styles from './styles.module.scss';

export const WelcomeArticle = () => {
  return (
    <Grid container className={styles.wrapper}>
      <Grid item xs={12} className={styles.content}>
        <article className={styles.content_wrapper}>
          <Typography variant="h5" className={styles.subtitle}>
            Welcome to Eurotech
          </Typography>
          <Typography variant="h2" className={styles.title}>
            Exceptional Seating without Exception
          </Typography>
          <Typography variant="caption" className={styles.description}>
            Make back pain, neck strain, and ugly office chairs a thing of the past. At Eurotech, we believe that
            design, form, functionality, and frugality can meet, become fast friends, and give you an office chair
            that’s customized for your specific needs.
          </Typography>

          <LinkButton classes={{root: styles.link_wrapper}}>
            <Link to={Routes.About} className={styles.link}>
              <Typography variant="caption">Discover</Typography>
              <ChevronRightIcon className={styles.link_icon}/>
            </Link>
          </LinkButton>
        </article>
      </Grid>
    </Grid>
  )
};