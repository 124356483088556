import React from 'react'

interface IconsProps {
  styles?: string
}

const SortIcon = ({styles = ''} : IconsProps) => {
  return (
    <svg className={styles} width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="3" x2="23" y2="3" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
      <line x1="1" y1="15" x2="23" y2="15" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
      <circle cx="6.69238" cy="3" r="3" fill="#000000"/>
      <circle cx="16.6924" cy="15" r="3" fill="#000000"/>
    </svg>

  )
}

export default SortIcon