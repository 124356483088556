import React from "react";
import { Helmet } from 'react-helmet';
import { Container, Grid, Typography, } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Footer from "../../components/footer.component";
import styles from './styles.module.scss';

export const ResourcesPage = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Warranty Information | Eurotech"}</title>
        <meta name="description"
          content={"In today's high-stakes marketplace and state-of-the-art technology, seating solutions must be available quickly and reliably."} />
      </Helmet>
      <Grid container className={styles.wrapper}>

        <Grid item xs={12} md={12} className={styles.header}>
          <div className={styles['text-wrapper']}>
            <Typography className={styles.sub_title}>
              DESIGN RESOURCES
            </Typography>
            <Typography variant="h1" className={styles.title}>
              Need Design Help? You’re in the Right Place.
            </Typography>

            <Typography className={styles.description}>
              If you use <b>Project Matrix, 2020, GIZA, Kits collaborator or Configura</b>, please click the links below to download the most current symbols.
            </Typography>
          </div>
        </Grid>

        <section className={styles.scroll_link}>
          <Typography className={styles.scroll_link_title}>Scroll For More</Typography>
          <ExpandMoreIcon className={styles.scroll_link_icon} />
        </section>

        <Grid xs={12} md={12}>
          <Container>
            <div className={styles.card_wrapper}>
              <div className={styles.card}>
                <Typography variant="h4" className={styles.card_title}>CAP Download</Typography>
                <Typography className={styles.card_content}>
                  CAP designer is an Auto-CAD based design tool.  Download our symbols here
                </Typography>

                <nav className={styles.link_wrapper}>
                  <a href="/assets/downloads/resources/CAP.zip" target="_blank" download className={styles.link_item}>Download</a>
                  {/* <a href="" className={styles.link_item}>Support</a> */}
                  {/* <a href="" className={styles.link_item}>User Guide</a> */}
                </nav>
              </div>

              <div className={styles.card}>
                <Typography variant="h4" className={styles.card_title}>CET Download</Typography>
                <Typography className={styles.card_content}>
                  Design 3D layouts of your space, including relevant products and structures.
                </Typography>

                <nav className={styles.link_wrapper}>
                  {/* <a href="#" className={styles.link_item}>Overview</a> */}
                  {/* <a href="#" className={styles.link_item}>Frequently Asked Questions</a> */}
                  <a href="/assets/downloads/resources/CET.zip" target="_blank" download className={styles.link_item}>Download</a>
                  {/* <a href="#" className={styles.link_item}>Support</a> */}
                  {/* <a href="#" className={styles.link_item}>AIS Briefcase</a> */}
                  {/* <a href="#" className={styles.link_item}>AIS Extension Forum</a> */}
                  {/* <a href="#" className={styles.link_item}>CET Software Forum</a> */}
                  {/* <a href="#" className={styles.link_item}>CET Training</a> */}
                </nav>
              </div>

              <div className={styles.card}>
                <Typography variant="h4" className={styles.card_title}>Giza Download</Typography>
                <Typography className={styles.card_content}>
                  Easy-to-use sales, design and specification tool for the do-it-all furniture professional. Download our symbols here
                </Typography>

                <nav className={styles.link_wrapper}>
                  <a href="/assets/downloads/resources/GIZA.zip" target="_blank" download className={styles.link_item}>Download</a>
                  {/* <a href="#" className={styles.link_item}>Support</a> */}
                </nav>
              </div>

              <div className={styles.card}>
                <Typography variant="h4" className={styles.card_title}>Project Matrix Download</Typography>
                <Typography className={styles.card_content}>
                  Download 2D and 3D symbols for space planning and configurations
                </Typography>

                <nav className={styles.link_wrapper}>
                  <a href="/assets/downloads/resources/ProjectMatrix.zip" target="_blank" download className={styles.link_item}>Download</a>
                  {/* <a href="#" className={styles.link_item}>Support</a> */}
                  {/* <a href="#" className={styles.link_item}>Specification Tips</a> */}
                  {/* <a href="#" className={styles.link_item}>Project Symbols</a> */}
                </nav>
              </div>

              <div className={styles.card}>
                <Typography variant="h4" className={styles.card_title}>Kits Download</Typography>
                <Typography className={styles.card_content}>
                  The KITS collaborator™ - Mobile Sales Platform was developed to meet the needs of today's office furniture sales professional. build with 3D furniture models with our Eurotech symbols
                </Typography>

                <nav className={styles.link_wrapper}>
                  <a href="/assets/downloads/resources/KITS.zip" target="_blank" download className={styles.link_item}>Download</a>
                  {/* <a href="#" className={styles.link_item}>Support</a> */}
                  {/* <a href="#" className={styles.link_item}>Specification Tips</a> */}
                  {/* <a href="#" className={styles.link_item}>Project Symbols</a> */}
                </nav>
              </div>
            </div>
          </Container>
        </Grid>


        <Grid item xs={12} sm={12} md={12}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}