import React from 'react'

interface IconsProps {
  styles?: string
}

const DownloadIcon = ({styles = ''} : IconsProps) => {
  return (
    <svg className={styles} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1C14 0.447715 13.5523 1.96147e-08 13 0C12.4477 -1.96147e-08 12 0.447715 12 1L14 1ZM12.2929 17.7071C12.6834 18.0976 13.3166 18.0976 13.7071 17.7071L20.0711 11.3431C20.4616 10.9526 20.4616 10.3195 20.0711 9.92893C19.6805 9.53841 19.0474 9.53841 18.6569 9.92893L13 15.5858L7.34315 9.92893C6.95262 9.53841 6.31946 9.53841 5.92893 9.92893C5.53841 10.3195 5.53841 10.9526 5.92893 11.3431L12.2929 17.7071ZM12 1L12 17L14 17L14 1L12 1Z" fill="white"/>
      <path d="M1 16V21C1 23.2091 2.79086 25 5 25H21C23.2091 25 25 23.2091 25 21V16" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

export default DownloadIcon