import * as THREE from 'three';
import {debounce} from 'throttle-debounce';
import SceneEngine, {IEngineProps} from "./scene.engine";
import {TextureLoader} from "./scene.cache";
import Fabric from "../../types/Fabric";
import {MeshBasicMaterial, MeshPhongMaterial, MeshStandardMaterial, Object3D} from "three";
import Material from "../../types/Material";
import { imageCompressConstants } from "../../constants/imageCompressConstants";

interface IControllerProps extends IEngineProps {}
interface ITechpack {
    object: string,
    config: {
        visible_meshes: string[],
    },
    materials: Material[],
    fabric: Fabric,
    fabricModification: string[]
}

export default class SceneController {
    protected props: IControllerProps;
    model?: THREE.Object3D;
    protected TextureLoader = new TextureLoader();
    protected Engine: SceneEngine;
    fabricModification : string[]

  constructor(props: IControllerProps) {
    this.props = props;
    this.Engine = new SceneEngine(props);

    this.render();
    this.updateModel = debounce(100, this.updateModel)
  }

  clearThree = () => {
    this.Engine.clearThree();
  }

  updateModel = async (techpack: ITechpack, cb: (_: THREE.Object3D) => void) => {
    this.Engine.clearScene();
    const _object = await this.Engine.loadModel(`https:${techpack.object}`);
    _object.rotation.y = Math.PI / 4;
    this.model = _object;
    this.fabricModification = techpack.fabricModification

    this.Engine.addModel(_object, 'model');
    this.Engine.fitCamera([_object]);

    await this.updateVisibleMeshes(techpack.config);
    await this.updateMaterials(techpack.materials);
    await this.updateFabrics(techpack.fabric);
    cb(this.model);
  };

  updateVisibleMeshes = async (config: { visible_meshes: string[] }) => {
    this.model.children.forEach((child: Object3D) => {
      child.visible = config.visible_meshes.includes(child.name);
    });
  };

  updateMaterials = async (materials: Material[]) => {
    await Promise.all(materials.map(async (material: Material) => {
      const _keys = Object.keys(material).filter(key => (key.includes('map') || key.includes('Map')) && material[key as keyof typeof material]);
      const _material = await this.generateMaterial(_keys, material);
      await this.applyMaterial(_material, material);
    }));
  };

  updateFabrics = async (fabric: Fabric) => {
    const _keys = Object.keys(fabric).filter(key => (key.includes('map') || key.includes('Map')) && fabric[key as keyof typeof fabric]);

    const _material = await this.generateMaterial(_keys, fabric);

    await this.applyMaterial(_material, fabric);
  };

  generateMaterial = async (keys: string[], content: Fabric | Material) => {
    const _textures = await Promise.all(keys.map(async key => ({
      // @ts-ignore
      texture: await this.TextureLoader.load(`${content[key]}${imageCompressConstants.fabricConfigurator}`) as THREE.Texture,
      key
    })));

    const _maps = _textures.reduce((acc, {key, texture}) => ({...acc, [key]: texture}), {});

    if (content instanceof Fabric) {
      return new MeshPhongMaterial({
        ...content.config.parameters,
        ..._maps,
        //@ts-ignore
        blending: content.config.parameters.blending && content.config.parameters.blending === 'custom' && THREE.CustomBlending,
        //@ts-ignore
        side: content.config.parameters.side && content.config.parameters.side === 'double' && THREE.DoubleSide
      })
    } else {
      switch (content.type) {
        case 'basic': {
          return new MeshBasicMaterial({
            ...content.config.parameters,
            ..._maps,
            side: THREE.FrontSide
          });
        }
        case 'standard': {
          return new MeshStandardMaterial({
            ...content.config.parameters,
            ..._maps,
            side: THREE.FrontSide
          });
        }
        case 'phong': {
          return new MeshPhongMaterial({
            ...content.config.parameters,
            ..._maps,
            //@ts-ignore
            blending: content.config.parameters.blending && content.config.parameters.blending === 'custom' && THREE.CustomBlending,
            //@ts-ignore
            side: content.config.parameters.side && content.config.parameters.side === 'double' && THREE.DoubleSide
          })
        }
        default: {
          console.error('Empty material type');
        }
      }
    }
  };

  applyMaterial = async (material: THREE.Material, content: Fabric | Material) => {
    let _materials: Object3D[] = []

    if(content instanceof Fabric) {
      _materials = this.model?.children.filter((child: Object3D) => (
        this.fabricModification.includes(child.name)
      ))
    }
    if(content instanceof Material) {
      _materials = this.model?.children.filter((child: Object3D) => (
        content.config.mesh_attachment.some((name: string) => child.name === name)
      ))
    }
    _materials.forEach((child: Object3D) => {
      this.setMaterialToTraversedMesh(child as THREE.Mesh, material);
    });
  };

  getScreenshots = async (): Promise<{ [key: string]: string }> => {
    return await this.Engine.takeScreenShot()
  };

  setZoom = (scale: number) => {
    this.Engine.updateZoom(scale);
  };

  setMaterialToTraversedMesh(mesh: THREE.Mesh, material: THREE.Material) {
    if (mesh.material) mesh.material = material;
    mesh.traverse(child => {
      //@ts-ignore
      if (child.material) {
        //@ts-ignore
        child.material = material;
        //@ts-ignore
        child.material.needsUpdate = true;
      }
    })
  }

  render = () => {
    this.Engine.update();
    requestAnimationFrame(this.render);
  }
}