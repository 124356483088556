import React from 'react'
import styles from './styles.module.scss'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Adjustment from '../../../../../types/Adjustment'

interface IFeaturesList {
  adjustment: Adjustment
}

const FeaturesList = ({ adjustment } : IFeaturesList) => {
  return adjustment.features ? (
    <div className={styles.wrapper}>
      <ul className={styles.features}>
        {adjustment.features && adjustment.features.map((item: any) => {
          return (
            <li key={item.key} className={styles.features__item}>{item.key}</li>
          )
        })}
      </ul>
      {adjustment && adjustment.product_sheet ? (
        <div className={styles.download}>
          <a href={`https:${adjustment.product_sheet}`} target="_blank"
             className={styles.download_link}>Download
            Product Sheet <ExpandMoreIcon className={styles.download_icon}/></a>
        </div>
      ) : null}
    </div>
  ) : null
}

export default FeaturesList