import React from 'react';
import Fabric from "../../../types/Fabric";
import { useDispatch, useSelector } from "react-redux";
import { activeColorSelector, adjustmentSelector, modelSelector } from "../../../redux/modules/app.module";
import { changeControls, setActiveGrade } from "../../../redux/modules/action.creators/app.actions";
import { Grade } from "../../../types/IGrades";
import Modifications from "../../../types/Modifications";
import styles from "./styles.module.scss";
import { imageCompressConstants } from "../../../constants/imageCompressConstants";
import FabricSection from '../../../types/FabricSection';


type BaseProps = {
}
type InjectedProps = {
  checkActiveColor?: (id : string) => boolean,
  clickHandler?: (fabric: Fabric) => void,
  renderInfo?: () => JSX.Element
}


const colorsConstructor = <BaseProps extends InjectedProps>(PassedComponent: React.ComponentType<BaseProps>) => {
  return (props: BaseProps) => {
    const dispatch = useDispatch()

    const model = useSelector(modelSelector)
    const adjustment = useSelector(adjustmentSelector)
    const activeColor = useSelector(activeColorSelector)

    const checkActiveColor = ( id: string ) => {
      if (activeColor) {
        return activeColor.id === id
      }
      return false
    }
    const clickHandler = ( fabric: Fabric ) => {
      if (model && activeColor && activeColor.id === fabric.id) {
        dispatch(changeControls({name: 'activeColor', value: null}))
        dispatch(setActiveGrade(null))
        return
      }

      dispatch(setActiveGrade(null))
      dispatch(changeControls({name: 'activeColor', value: fabric}))

      if (adjustment && adjustment.grades) {
        adjustment.grades.grades.forEach(( item: Grade ) => {
          if(!item.title || !fabric.gradeTitle) return
          if (fabric.gradeTitle.toLowerCase() === item.title.toLowerCase()) {
            dispatch(setActiveGrade(item))
          }
        })
      }
      if (adjustment && adjustment.modifications) {
        adjustment.modifications.forEach(( item: Modifications ) => {
          if (item.grade) {
            item.grade.forEach(( el: Grade ) => {
              if(!el.title || !fabric.gradeTitle) return

              if (fabric.gradeTitle.toLowerCase() === el.title.toLowerCase()) {
                dispatch(setActiveGrade(el))
              }
            })
          }

        })
      }
    }
    const renderInfo = ( fabric: Fabric ) => {
      return (
        <div className={styles.tooltip}>
          <span className={styles.tooltip__title}>{fabric.title.slice(0, fabric.title.indexOf('_'))}</span>
          <span className={styles.tooltip__serial}>{fabric.serial}</span>
          <div className={styles.tooltip__material}>
            <img className={styles.tooltip__image}
                 src={`https:${fabric.thumbnail}${imageCompressConstants.productFabricPreview}`} alt=""/>
          </div>
          {fabric.description
            ? <p className={styles.tooltip__description}>{fabric.description}</p>
            : <p className={styles.tooltip__description}>It's a very pretty color, use it always</p>}
        </div>
      )
    }
    return (
      <PassedComponent
        {...props as BaseProps}
        checkActiveColor={checkActiveColor}
        clickHandler={clickHandler}
        renderInfo={renderInfo}
      />
    );
  }
}


export default colorsConstructor;