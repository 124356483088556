import * as THREE from 'three';

interface IObject {
  key: string,
  object: THREE.Object3D;
}

export default class ObjectCache {
  private objects: IObject[] = [];

  addObject(key: string, object: THREE.Object3D) {
    if (!this.objects.map(((object: IObject) => object.key)).includes(key)) {
      this.objects.push({key, object})
    }
    if (this.objects.length > 2) {
      this.objects.shift()
    }
  }

  getObject(key: string) {
    const _target = this.objects.find((object: IObject) => object.key === key);
    return _target ? _target.object : null;
  }

  removeObject() {
    if (this.objects.length >= 2) {
      this.objects.shift()
    }
    return;
  }
}

export class TextureLoader {
  private loader = new THREE.TextureLoader();

  load(url: string): Promise<THREE.Texture> {
    return new Promise((resolve) => {
      this.loader.load(url, (texture) => {
        resolve(texture);
      })
    })
  }
}