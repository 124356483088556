import React from "react";
import styles from "./styles.module.scss";

interface ISpecificationsList {
  specifications: any;
  specifications_new: any[];
}

const SpecificationsList = ({ specifications, specifications_new }: ISpecificationsList) => {
  if (specifications_new) {
    console.log(specifications_new)
    return (
      <ul className={styles.specifications}>
        {specifications &&
          specifications_new.map((spec: any, index: number) => (
            <li className={styles.specifications_item} key={index}>
              <span className={styles.specifications_title}>
                {spec.name}
              </span>
              {spec.value}
            </li>
          ))}
      </ul>
    );
  }
  return (
    <ul className={styles.specifications}>
      {specifications &&
        Object.keys(specifications).map((key, index) => (
          <li className={styles.specifications_item} key={index}>
            <span className={styles.specifications_title}>
              {key.split("_").join(" ")}:&nbsp;
            </span>
            {specifications[key as keyof typeof specifications]}
          </li>
        ))}
    </ul>
  );
};

export default SpecificationsList;
