import React, { Dispatch, SetStateAction, useState } from 'react'
import styles from './styles.module.scss'
import Model from '../../../types/Model'
import ModelColors from "./model.colors";
import ModelTitle from "./model.title";
import ModelSeating from "./model.seating";
import ModelSelect from "./model.select";
import Adjustment from '../../../types/Adjustment'
import { IOptions } from '../../../types/IOptions'
import SpecificationsList from '../../product/specifications.list'
import FeaturesList from '../right.sidebar/components/features.list'
import PriceComponent from '../right.sidebar/components/price.component'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { Transition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import { fullscreenSelector } from '../../../redux/modules/customizer.module'
import { isMobile } from "react-device-detect";

interface ILeftSidebar {
  model: Model,
  adjustment: Adjustment,
  options: IOptions,
  activePreview?: boolean,
  setActivePreview? : Dispatch<SetStateAction<boolean>>

}

const titleTransition = {
  unmounted: { left: 0},
  entering: {left: 0},
  entered: {left: 0},
  exiting: {left: '-100%'},
  exited: {left: '-100%'},
};

const LeftSidebar = ( { model, adjustment, options, activePreview ,setActivePreview }: ILeftSidebar ) => {
  const fullscreen = useSelector(fullscreenSelector)

  const previewTransition = {
    unmounted: { top: 'calc(100% - var(--previewButtonHeight))'},
    entering: {top: 'calc(100% - var(--previewButtonHeight))'},
    entered: {top: 'calc(100% - var(--previewButtonHeight))'},
    exiting: {top:  fullscreen ? '100%' : 'var(--headerHeight)'},
    exited: {top: fullscreen ? '100%' : 'var(--headerHeight)'},
  };

  const togglePreview = () => {
    setActivePreview((prev) => !prev)
  }
  return adjustment ? (
    <div className={styles.sidebar}>
      <Transition timeout={350} in={!fullscreen} >
        {state => (
            <div className={styles.sidebar__title} style={{...titleTransition[state]}}>
              <ModelTitle />
            </div>
          )}
      </Transition>

      <div className={styles.sidebar__seatings}>
        <div className={styles.sidebar__subtitle}>
          <span className={styles.sidebar__count}>01</span>
          <span>Seating</span>
        </div>
        <ModelSeating options={options}/>
      </div>
      <div className={styles.sidebar__models}>
        <div className={styles.sidebar__subtitle}>
          <span className={styles.sidebar__count}>02</span>
          <span>Model</span>
        </div>
        <ModelSelect  options={options} />
      </div>
      <div className={styles.sidebar__colors}>
        <div className={styles.sidebar__subtitle}>
          <span className={styles.sidebar__count}>03</span>
          <span>Colors</span>
        </div>
        <ModelColors  options={options}/>
      </div>
      {isMobile ? (
        <Transition timeout={350} in={!activePreview && !fullscreen} >
          {state => (
            <div className={styles.sidebar__preview}
                 onClick={togglePreview}
                 style={{...previewTransition[state]}}
            >
              <span className={styles.sidebar__subtitle}>Preview</span>
              <ChevronRight className={`${styles.sidebar__chevron} ${activePreview ? styles.open : ''}`}/>
            </div>
          )}
        </Transition>
      ) : null}
    </div>
  ) : null
}

export default LeftSidebar