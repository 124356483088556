import React from 'react'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import {  modelSelector } from '../../../../redux/modules/customizer.module'
import { ChevronLeft } from '@material-ui/icons'
import Routes from '../../../../app.router/router.constants'
import {Link} from "react-router-dom";

interface IModelTitle {
}

const ModelTitle = ({}: IModelTitle) => {
  const model = useSelector(modelSelector)

  return model ? (
    <div className={styles.title} >
      <Link className={styles.title__back} to={Routes.Homepage} >
        <ChevronLeft/>
        <span>Back</span>
      </Link>
      <h3 className={styles.title__main}>{model.title}</h3>
      <span className={styles.title__subtitle}>{model.sku}</span>
    </div>

  ) : null
}

export default ModelTitle