import React from 'react'

interface IconsProps {
  styles?: string
}

const FilterIcon = ({styles = ''} : IconsProps) => {
  return (
    <svg className={styles} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.03238 11.3409C9.28424 11.615 9.42251 11.973 9.42251 12.3434V23.2571C9.42251 23.9139 10.2151 24.2473 10.6843 23.7855L13.7287 20.2966C14.1362 19.8077 14.3608 19.5657 14.3608 19.0817V12.3458C14.3608 11.9755 14.5016 11.6174 14.751 11.3433L23.4869 1.86422C24.1412 1.1531 23.6375 0 22.6696 0H1.11373C0.145816 0 -0.360364 1.15063 0.296435 1.86422L9.03238 11.3409Z" fill="#000000"/>
    </svg>
  )
}

export default FilterIcon