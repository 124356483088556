import React from 'react'
import { styled, Tooltip, TooltipProps } from '@material-ui/core'

interface IPopupComponent {
  children: any,
  contentHTML: any,
  styles?: {
    tooltip?: {},
    arrow?: {},
  },
}

const PopupComponent = ({ children, contentHTML, styles = { tooltip: {}, arrow : {} } } : IPopupComponent) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: '#fbfbfb',
      padding: 15,
      maxWidth: 350,
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      ...styles.tooltip,
    },
    [`& .MuiTooltip-arrow:before`]: {
      backgroundColor: '#fbfbfb',
      border: '1px solid #dadde9',
      ...styles.arrow,
    },
  }))
  return (
    <HtmlTooltip title={contentHTML}>
      {children}
    </HtmlTooltip>
  )

}

export default PopupComponent