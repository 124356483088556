import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {RootState} from "../../redux/root.reducer";
import {slidersSelector} from "../../redux/modules/app.module";

import {HomepageViewer} from './homepage.viewer';

const mapStateToProps = (state: RootState) => ({
  sliders: slidersSelector(state)
});

const mapDispatchToProps = (dispatch: any) => (
  bindActionCreators({}, dispatch)
);

export type IHomepageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(HomepageViewer);