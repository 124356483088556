import { useEffect, useState } from 'react';

const getHeight = () => window.innerHeight

const useHeight = (): number => {
  const [height, setHeight] = useState(getHeight())

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextHeight = getHeight()

      setHeight(nextHeight)
    }, 200)

    return () => {
      clearInterval(intervalId)
    };
  });
  useEffect(() => {
    const changeResize = () => {
      const vh = getHeight();
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    changeResize()
    window.addEventListener('resize', changeResize)
    return () => {
      window.removeEventListener('resize', changeResize)
    }
  }, [])

  return height
}

export { useHeight }